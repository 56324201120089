import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['timeTracking', 'automaticTimeTracking'];

  connect() {
    this.refresh();
  }

  refresh() {
    this.containerTarget.classList.toggle('inactive', !this.timeTrackingTarget.checked);
  }

  get containerTarget() {
    return this.automaticTimeTrackingTarget.parentNode;
  }
}
