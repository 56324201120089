import { useEffect, useMemo } from 'react'
import { createConsumer } from '@rails/actioncable'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { WebsocketsStatuses } from 'src/react/constants/Meetings/index'

global.addEventListener = () => {};
global.removeEventListener = () => {};

export function useActionCable() {
  const actionCable = useMemo(() => createConsumer(), []);
  const websocketsStatus = useSelector(state => selectors.websocketsStatus(state));

  useEffect(() => {
    return () => {
      if (websocketsStatus == WebsocketsStatuses.Inactive) actionCable.disconnect();
    };
  }, [websocketsStatus]);

  return {
    actionCable
  };
}