import React from 'react'

import Nullable from 'src/react/components/Metrics/Nullable'

const EjectionFraction = ({ scores }) => {
  return (
    <div className="scores">
      <div className="scores-table">
        <table>
          <tbody>
            <tr>
              <td>LVEF (%)</td>
              <td>
                <div className="score">
                  <div className="title">Min</div>
                  <div className="value">
                    <Nullable>
                      {scores.lvef_min}
                    </Nullable>
                  </div>
                </div>
              </td>
              <td>
                <div className="score">
                  <div className="title">Avg</div>
                  <div className="value">
                    <Nullable>
                      {scores.lvef_avg}
                    </Nullable>
                  </div>
                </div>
              </td>
              <td>
                <div className="score">
                  <div className="title">Max</div>
                  <div className="value">
                    <Nullable>
                      {scores.lvef_max}
                    </Nullable>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="additional-scores">
        <div className="score">
          <div className="title">Tests Done</div>
          <div className="value">
            {scores.total}
          </div>
        </div>
        <div className="score">
          <div className="title">Active Days</div>
          <div className="value">
            {scores.active_days}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EjectionFraction
