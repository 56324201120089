import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

const userTypesAliases = {
  'MedicalGroupMember': 'MG User',
  'MedicalGroupPatient': 'Patient'
};

const Menu = (props) => {
  if (props.selectProps.inputValue.length === 0) return null;

  return (
    <components.Menu {...props} />
  );
}

const Option = (props) => {
  const { name, type } = props.data;

  return (
    <components.Option {...props}>
      <strong>{name}</strong>
      {
        /*
        <div
          className="text-muted"
          style={{'fontSize': '12px'}}
        >
          {userTypesAliases[type]}
        </div>
        */
      }

    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  const { name } = props.data;

  return (
    <components.SingleValue {...props} >
      <strong>{name}</strong>
    </components.SingleValue>
  );
}

const Subscriber = ({
  changeSubscriber,
  user,
  loadOptions,
  placeholder = 'Select recipient ...'
}) => {
  const [selectValue, setSelectValue] = useState(user);

  useEffect(() => {
    user && setSelectValue(user);
  }, [user]);

  useEffect(() => {
    changeSubscriber(selectValue);
  }, [selectValue]);

  const handleChange = (option, { action }) => {
    setSelectValue(action == 'clear' ? null : option);
  };

  return (
    <AsyncSelect
      value={selectValue}
      isClearable
      blurInputOnSelect
      backspaceRemovesValue
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={handleChange}
      components={{
        Menu,
        Option,
        SingleValue
      }}
    />
  );
};

export default Subscriber
