import debounce from 'lodash.debounce'
import { useState, useCallback, useEffect, useRef } from 'react'

import { useSizeCallback } from './useSizeCallback'

export function useDimensions({
  mediaStream,
  landscapeVideoRef,
  miniatureVideoRef
}) {
  const [landscapeDimension, setLandscapeDimension] = useState({ width: 0, height: 0 });
  const [miniatureDimension, setMiniatureDimension] = useState({ width: 0, height: 0 });

  const debounceRef = useRef(debounce(setLandscapeDimension, 300));

  const onCanvasResize = useCallback(
    ({ width, height }) => {
      if (landscapeVideoRef) {
        debounceRef.current({ width, height });
      }
    },
    [landscapeVideoRef]
  );

  useSizeCallback(landscapeVideoRef.current, onCanvasResize);

  useEffect(() => {
    if (landscapeVideoRef.current) {
      const { width, height } = landscapeVideoRef.current.getBoundingClientRect();

      setLandscapeDimension({ width, height });
    }
  }, []);

  useEffect(() => {
    const width = landscapeDimension.width > 1000 ? 320 : 213;
    const height = landscapeDimension.width > 1000 ? 180 : 120;

    setMiniatureDimension({ width, height });
  }, [landscapeDimension]);

  useEffect(() => {
    const { width, height } = landscapeDimension;

    try {
      if (landscapeVideoRef.current) {
        landscapeVideoRef.current.width = width;
        landscapeVideoRef.current.height = height;
      }
    } catch (e) {
      mediaStream?.updateVideoCanvasDimension(landscapeVideoRef.current, width, height);
    }
  }, [mediaStream, landscapeDimension, landscapeVideoRef]);

  useEffect(() => {
    const { width, height } = miniatureDimension;

    try {
      if (miniatureVideoRef.current) {
        miniatureVideoRef.current.width = width;
        miniatureVideoRef.current.height = height;
      }
    } catch (e) {
      mediaStream?.updateVideoCanvasDimension(miniatureVideoRef.current, width, height);
    }
  }, [mediaStream, miniatureDimension, miniatureVideoRef]);

  return { landscapeDimension, miniatureDimension };
}
