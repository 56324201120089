import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  static values = { url: String, content: String }

  connect() {
    this.initializePopover();
    this.attachEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  initializePopover() {
    this.popoverEl =
      $(this.element).popover({
        placement: 'top',
        html: true,
        sanitize: false,
        trigger: 'manual',
        content: this.contentValue,
        template: `
          <div class="popover d-flex" style="max-width: 350px; z-index: 1000;" role="tooltip">
            <div class="arrow"></div>
            <div class="popover-body"></div>
            <button type="button" class="close mr-2" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`
      });
  }

  attachEventListeners() {
    this.closeButtonElement.addEventListener('click', () => this.closePopover());
  }

  removeEventListeners() {
    this.closeButtonElement.removeEventListener('click', () => this.closePopover());
  }

  check({ detail: { isCompleted, macAddress } }) {
    isCompleted ? this.checkMacAddressRequest(macAddress) : this.closePopover();
  }

  checkMacAddressRequest(macAddress) {
    this.lockInput();

    const request = $.ajax({
      method: 'POST',
      dataType: 'json',
      url: this.urlValue,
      data: { mac_address: macAddress }
    });

    request.done((response) => this.success(response));
    request.fail((error) => this.fail(error));
    request.always(() => this.unlockInput());
  }

  success({ exists }) {
    exists ? this.showPopover() : this.closePopover();
  }

  fail(xhr) {
    new ErrorsInterceptor({ xhr });
  }

  closePopover() {
    this.popoverEl.popover('hide');
  }

  showPopover() {
    this.popoverEl.popover('show');
  }

  lockInput() {
    this.toggleLoading();

    this.element.readonly = true;
  }

  unlockInput() {
    this.toggleLoading();

    this.element.readonly = false;
  }

  toggleLoading() {
    this.element.classList.toggle('is-loading');
  }

  get closeButtonElement() {
    return this.popoverInstance.querySelector('button.close');
  }

  get popoverInstance() {
    return $(this.element).data('bs.popover').getTipElement();
  }
}
