import $ from 'jquery'
import escape from 'src/utils/sanitize'
import 'devbridge-autocomplete'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

$.fn.rosterFormBindings = function() {
  const self = $(this);

  const autocomplete_box = self.find('.autocomplete-select');
  const autocomplete_url = autocomplete_box.data('url');

  const new_appointments_box = self.find('.new-appointments');
  const new_appointments_list = new_appointments_box.find('.list');
  const new_appointments_count = new_appointments_box.find('.count');

  const existing_appointments_box = self.find('.existing-appointments');
  const existing_appointments_list = existing_appointments_box.find('.list');
  const existing_appointments_count = existing_appointments_box.find('.count');

  let users_ids = [];

  const recalculateLogic = () => {
    users_ids = self.
      find('.appointment-fields').
      not('.destroyed').
      map(function(element) {
        return $(this).data('user-id');
      }).
      get();

    const new_count = new_appointments_box.
      find('.appointment-fields').
      not('.destroyed').
      length;

    const existing_count = existing_appointments_box.
      find('.appointment-fields').
      not('.destroyed').
      length;

    new_appointments_box.toggleClass('d-none', (new_count <= 0));
    existing_appointments_box.toggleClass('d-none', (existing_count <= 0));

    new_appointments_count.html(new_count);
    existing_appointments_count.html(existing_count);
  };

  const autocomplete = () => {
    autocomplete_box.autocomplete({
      serviceUrl: autocomplete_url,
      dataType: 'json',
      minChars: 0,
      deferRequestBy: 200,
      appendTo: '#edit-modal .modal',
      forceFixPosition: true,
      showNoSuggestionNotice: true,
      noSuggestionNotice: `<div class="p-2"> No patients found <div>`,
      formatResult(suggestion, currentValue) {
        return `
          <div class="user-list-widget user-list-widget-rosters">
            <div class="user-between-with-bange">
              <div class="content">
                <div class="name"> ${escape(currentValue, suggestion.data.name)} </div>
                <div class="info-label"> ${patientInfoLabel(suggestion.data)} </div>
              </div>
              ${suggestion.data.archived ? '<span class="custom-badge"> Archived </span>' : ''}
              ${Array.from(users_ids).includes(suggestion.data.id) ? '<div class="custom-badge"> Added </div>' : ''}
           </div>
         </div>`;
      },
      onSelect(suggestion) {
        autocomplete_box.val('');

        if (Array.from(users_ids).includes(suggestion.data.id)) return;

        const appointment =
          suggestion._appointment.replace(/new_roster_appointment/gi, (new Date().getTime()));

        new_appointments_list.append(appointment);

        recalculateLogic();
      },
      onSearchError(query, xhr) {
        new ErrorsInterceptor({ xhr });
      }
    });
  }

  // listeners
  self.on('click', '.appointment-remove', function(e) {
    $(this).
      parents('.appointment-fields').
      addClass('destroyed').
      find('.destroy-field').
      val('true');

    recalculateLogic();
  });

  // initializers
  recalculateLogic();
  autocomplete();
};
