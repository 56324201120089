import { useState } from 'react'

export function useCheckboxes(elements) {
  const [items, setItems] = useState(elements);

  const handleToggle = (e) => {
    const inputVal = e.target.value;
    const isActive = items.indexOf(inputVal) !== -1;

    if (isActive) {
      setItems(items.filter((n) => n !== inputVal));
    } else {
      setItems([...items, inputVal]);
    };
  };

  return [items, handleToggle];
}