import React from 'react'

const LatencyScreen = ({ caption }) => {
  return (
    <div className="latency-screen">
      <div className="joining loading-box"> {caption} </div>
    </div>
  );
}

export default LatencyScreen
