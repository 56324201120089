import React from 'react'

import { useActionCable } from 'src/react/hooks/Meetings/useActionCable'

import ActionCableContext from 'src/react/contexts/Meetings/action-cable-context'

const ActionCableConnection = ({ children }) => {
  const { actionCable } = useActionCable();

  return (
    <ActionCableContext.Provider value={actionCable}>
      {children}
    </ActionCableContext.Provider>
  );
}

export default ActionCableConnection
