import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// Redux DevTools extension code
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : e => e;

const configureStore = (reducers, initialState = {}) => {
  const rootReducer = combineReducers(reducers);

  const enhancer = composeEnhancers(applyMiddleware(
    thunkMiddleware,
  ));

  return createStore(rootReducer, initialState, enhancer);
};

export default configureStore;
