import DiseaseFormatter from 'src/utils/diseaseFormatter'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import $ from 'jquery'
import 'selectize'

export default class primaryDiseaseCodes {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.initDiagnosisCodesElement();
    this.initPrimaryDiseaseSelect();
    this.toggleSelectize();
    
    this.bindListeners();
  }

  initDiagnosisCodesElement() {
    this.diagnosisCodesSelector = this.element.dataset.sourceElement;
    this.diagnosisCodesElement = document.getElementById(this.diagnosisCodesSelector);
  }

  initPrimaryDiseaseSelect() {
    const self = this;
    const url = this.element.dataset.url;
    const selector = this.element.dataset.sourceElement;
    const preloadingOptions = JSON.parse(this.element.dataset.preloadingOptions);
    const diagnosisCodes = document.getElementById(selector);
    const formatter = new DiseaseFormatter(this.element);

    this.primaryDiseasesSelect = $(this.element).selectize({
      valueField: 'code',
      labelField: 'code',
      searchField: ['code', 'description'],
      options: preloadingOptions,
      create: false,
      plugins: ['remove_button'],
      disabledField: 'node',
      render: {
        option: formatter.optionViewCallback(),
        item: formatter.itemViewCallback()
      },
      onInitialize: this.tagTooltipCallback,
      onChange: function() {
        self.tagTooltipCallback();
        formatter.cleanHiddenSelectOptions(this.$input[0].options);
      },
      load: function (query, callback) {
        const selectedItems = this.getValue();

        query = formatter.dotInsensitiveQuery(query);
        this.clearOptions();

        if (!query.length) return callback();

        $.ajax({
          url: url,
          dataType: 'json',
          data: { codes: $(diagnosisCodes).val() },
          success: (response) => {
            const billableDiseases =
              formatter.formatResponseData(response.data, selectedItems).
              filter(d => d.billable);

            callback(billableDiseases)
          },
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        })
      },
      score: () => () => 1
    });
  }

  bindListeners() {
    $(this.diagnosisCodesElement).on('change', () => this.updatePrimaryDiseasesSelector());
  }

  updatePrimaryDiseasesSelector() {
    this.toggleSelectize();
    this.setPrimaryCodes(this.primaryCodesValues())
  }

  primaryDiseasesSelectize() {
    return this.primaryDiseasesSelect[0].selectize;
  }

  primaryCodesValues() {
    const diagnosisCodes = this.diagnosisCodes();
    const primaryDiseaseCodes = this.primaryDiseasesValue();
    return primaryDiseaseCodes.filter((v) => diagnosisCodes.includes(v));
  }

  setPrimaryCodes(values) {
    this.primaryDiseasesSelectize().clear();
    values.forEach((v) => this.primaryDiseasesSelectize().addItem(v))
  }

  toggleSelectize() {
    const selectize = this.primaryDiseasesSelectize();

    if (this.isBillableCodeExists()) {
      selectize.enable();
    } else {
      selectize.disable();
    }
  }

  diagnosisCodes() {
    return $(this.diagnosisCodesElement).val();
  }

  isBillableCodeExists() {
    const diseasesCodesElement = $(this.diagnosisCodesElement).parent();
    const billableCodesElements = diseasesCodesElement.find('.selectize-input .mdi-currency-usd');
    return billableCodesElements.length > 0;
  }

  primaryDiseasesValue() {
    return $(this.element).val();
  }

  tagTooltipCallback() {
    $('[data-toggle="tooltip"], .tooltip').tooltip('hide');
    $('[data-toggle="tooltip"]').tooltip();
  }
}
