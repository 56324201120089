import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.location) window.location = data.location;

    if (data.modal) {
      $('body').
        findOrAppend('#recovery-modal', '<div id="recovery-modal" />').
        html(data.modal).
        find('.modal').
        modal('show');
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data }, () => {
      if (data.location) window.location = data.location;
    });
  }
}
