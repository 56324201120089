import { VideoQuality } from '@zoom/videosdk'

const currentQuality = (cellWidth) => {
  let quality = VideoQuality.Video_90P;

  if (cellWidth >= 180 && cellWidth < 270) {
    quality = VideoQuality.Video_180P;
  } else if (cellWidth >= 270 && cellWidth < 510) {
    quality = VideoQuality.Video_360P;
  } else {
    quality = VideoQuality.Video_720P;
  }

  return quality;
};

const calculateMiniatureLayout = ({ width, height }) => {
  const quality = currentQuality(width);

  return {
    width,
    height,
    x: 0,
    y: 0,
    quality
  };
}

const calculateLandscapeLayout = ({ width: rootWidth, height: rootHeight }) => {
  const fillFactor = Math.floor(Math.min(rootWidth / 16, rootHeight / 9));

  const cellWidth = fillFactor * 16;
  const cellHeight = fillFactor * 9;

  const x = (rootWidth - cellWidth) / 2;
  const y = (rootHeight - cellHeight) / 2;
  
  const quality = currentQuality(cellWidth);

  // 320 x 180
  // 213 x 120

  return {
    width: cellWidth,
    height: cellHeight,
    x: Math.floor(x),
    y: Math.floor(y),
    quality
  };
}

export {
  calculateLandscapeLayout,
  calculateMiniatureLayout
}
