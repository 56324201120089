export enum LineStatuses {
  Free = 'free',
  Pending = 'pending',
  Busy = 'busy'
};

export enum ZoomStatuses {
  Free = 'free',
  Pending = 'pending',
  Busy = 'busy'
};

export enum WebsocketsStatuses {
  Active = 'active',
  Inactive = 'inactive'
};

export enum MeetingTypes {
  Incoming = 'incoming',
  Outcoming = 'outcoming'
};

export enum MeetingStatuses {
  Pending = 'pending',
  Accepting = 'accepting',
  Rejecting = 'rejecting',
  Leaving = 'leaving',
  Ending = 'ending',
  Active = 'active'
};

export enum HistoryStatuses {
  Rejected = 'rejected',
  Timeouted = 'timeouted',
  Ended = 'ended'
};

export enum CameraStatuses {
  Pending = 'pending',
  Ready = 'ready',
  Unpermitted = 'unpermitted'
};

export enum MicrophoneStatuses {
  Pending = 'pending',
  Ready = 'ready',
  Unpermitted = 'unpermitted'
};

export enum ShutdownReasons {
  WebsocketsDisconnect = 'websocketsDisconnect',
  WatchdogInterrupt = 'watchdogInterrupt',
  // StaleConnectionInterrupt = 'staleConnectionInterrupt',
  AnotherDeviceAccepted = 'anotherDeviceAccepted',
  UnableStartMeeting = 'unableStartMeeting',
  Rejected = 'rejected',
  Timeouted = 'timeouted',
  Ended = 'ended'
};

export enum MeetingEvents {
  Offer = 'meeting_offer',
  Timeout = 'meeting_timeout',
  Reject = 'meeting_reject',
  Accept = 'meeting_accept',
  Online = 'meeting_online'
};

export enum MeetingCommands {
  Accept = 'accept_meeting',
  Reject = 'reject_meeting'
};

export const SHUTDOWN_REASONS_TOASTS = {
  [ShutdownReasons.WebsocketsDisconnect]: { type: 'error', message: 'Call terminated (Connection lost)' },
  [ShutdownReasons.WatchdogInterrupt]: { type: 'error', message: 'Call terminated (Timeout interrupt)' },
  // [ShutdownReasons.StaleConnectionInterrupt]: { type: 'error', message: 'Call terminated (Stale connection interrupt)' },
  [ShutdownReasons.AnotherDeviceAccepted]: { type: 'info', message: 'Call accepted on another device' },
  [ShutdownReasons.UnableStartMeeting]: { type: 'error', message: 'Unable to start meeting' },
  [ShutdownReasons.Rejected]: { type: 'error', message: 'Call rejected' },
  [ShutdownReasons.Timeouted]: { type: 'info', message: 'Call was not answered' },
  [ShutdownReasons.Ended]: { type: 'success', message: 'Call ended' }
};

export const WATCHDOG_TIMINGS = {
  [MeetingStatuses.Pending]: 125_000,
  [MeetingStatuses.Accepting]: 5_000,
  [MeetingStatuses.Rejecting]: 5_000,
  [MeetingStatuses.Leaving]: 5_000,
  [MeetingStatuses.Active]: 5_000
};

export const JOIN_ZOOM_MEETING_ATTEMPTS = 5;
export const STALE_CONNECTION_TIMEOUT = 15; // in seconds

export const DATETIME_FORMAT = 'MMM-DD-YYYY hh:mm A';