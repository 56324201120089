import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  loadMetrics,
  factoryDigest,
  changeRequestStatusAction
} from 'src/react/store/Metrics/actions'

import { RequestStatuses } from 'src/react/constants/Metrics/index'

import ChartsFactory from 'src/react/modules/Metrics/charts/Factory'
import ScoresFactory from './ScoresFactory'

import ScoresLoading from 'src/react/components/Metrics/ScoresLoading'
import RequestError from 'src/react/components/Metrics/RequestError'

const WidgetFactory = (props) => {
  const {
    from,
    to,
    type,
    requestStatus,
    currentWidget
  } = props;

  const dispatch = useDispatch();

  const factoryRef = useRef(null);
  const currentFactoryDigest = factoryDigest(props);

  const [digest, setDigest] = useState(null);
  const [scores, setScores] = useState(null);

  const isActiveWidget = currentWidget == type;
  const isSameDigest = currentFactoryDigest == digest;

  const changeRequestStatus = (status) => {
    dispatch(changeRequestStatusAction(status));
  };

  const changeDrilldownLevel = () => {
    setScores(factoryRef.current.currentDataspace.scores);
  };

  const applyDrilldown = (chart, e) => {
    changeRequestStatus(RequestStatuses.Loading);

    const requestOptions =
      factoryRef.current.requestOptions();

    const rangeOptions =
      factoryRef.current.drilldownRangeOptions(e);

    loadMetrics({
      ...rangeOptions,
      ...requestOptions
    }).
      then((response) => {
        factoryRef.current.setDrilldownResults(chart, e, response.data);

        changeRequestStatus(RequestStatuses.Ready);
      }).
      catch((_error) => {
        changeRequestStatus(RequestStatuses.Error);
      }).
      finally(() => {
        factoryRef.current.hideLoading();
      });
  }

  const gridRef = useCallback((node) => {
    factoryRef.current =
      new ChartsFactory(type, { node, applyDrilldown, changeDrilldownLevel });
  }, []);

  useEffect(() => {
    if (!isActiveWidget) return;
    if (isSameDigest) return;

    changeRequestStatus(RequestStatuses.Loading);
    setDigest(currentFactoryDigest);

    factoryRef.current.applyRoot();
    factoryRef.current.updateSettings(props);

    const requestOptions =
      factoryRef.current.requestOptions();

    loadMetrics({
      ...requestOptions,
      start_date: from,
      end_date: to
    }).
      then((response) => {
        factoryRef.current.setRootResults(response.data);

        changeRequestStatus(RequestStatuses.Ready);
      }).
      catch((_error) => {
        changeRequestStatus(RequestStatuses.Error);
      }).
      finally(() => {
        factoryRef.current.hideLoading();
      });
  }, [currentWidget, currentFactoryDigest]);

  return (
    <div className="wrapper">
      <div className="caption">
        {from} - {to}
      </div>
      <div className="block">
        <div
          ref={gridRef}
          className="chart"
        />
        {
          isActiveWidget &&
            requestStatus == RequestStatuses.Loading &&
            <ScoresLoading />
        }
        {
          isActiveWidget &&
            scores &&
            requestStatus == RequestStatuses.Ready &&
            <ScoresFactory type={type} scores={scores} />
        }
        {
          isActiveWidget &&
            requestStatus == RequestStatuses.Error &&
            <RequestError type={type} />
        }
      </div>
    </div>
  );
}

export default WidgetFactory