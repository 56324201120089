import $ from 'jquery'
import 'selectize'

class Recipients {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    this.selectize =
      $(this.element).
        selectize({
          create: false,
          plugins: ['remove_button']
        });
  }

  clear() {
    this.selectize[0].selectize.clear();
  }
}

export default Recipients
