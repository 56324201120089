import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Metrics/selectors'
import { hideModalAction, clearModalAction } from 'src/react/store/Metrics/actions'

import ExportModal from './Modals/ExportModal'
import SettingsModal from './Modals/SettingsModal'

const ModalFactory = () => {
  const modalShow = useSelector(state => selectors.modalShow(state));
  const modalAction = useSelector(state => selectors.modalAction(state));

  const dispatch = useDispatch();

  const modalProps = {
    show: modalShow,
    onHide: () => dispatch(hideModalAction()),
    onExited: () => dispatch(clearModalAction())
  };

  if (modalAction == 'export') {
    return <ExportModal {...modalProps} />;
  }

  if (modalAction == 'settings') {
    return <SettingsModal {...modalProps} />;
  }

  return null;
}

export default ModalFactory
