import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Metrics/selectors'

import {
  changeInterval,
  moveForward,
  moveBackward,
  moveFromToday
} from 'src/react/store/Metrics/actions'

import { NAVIGATION_OFFSETS } from 'src/react/constants/Metrics/index'

import RangePicker from './RangePicker'

const Navigation = () => {
  const from = useSelector(state => selectors.from(state));
  const to = useSelector(state => selectors.to(state));
  const currentDay = useSelector(state => selectors.currentDay(state));
  const isControlsInactive = useSelector(state => selectors.isControlsInactive(state));
  const { navigationOffset } = useSelector(state => selectors.settings(state));

  const dispatch = useDispatch();

  const onApply = ({ from, to }) => {
    dispatch(changeInterval({ from, to }));
  };

  const navigateForward = () => dispatch(moveForward());
  const navigateBackward = () => dispatch(moveBackward());

  const navigateByMonth = () => dispatch(moveFromToday(29, 'days'));
  const navigateByThreeMonth = () => dispatch(moveFromToday(89, 'days'));
  const navigateBySixMonth = () => dispatch(moveFromToday(6, 'months'));

  const isSameDay = moment(to).isSame(currentDay);
  const byMonthActive = isSameDay && moment(to).subtract(29, 'days').isSame(from);
  const byThreeMonthActive = isSameDay && moment(to).subtract(89, 'days').isSame(from);
  const bySixMonthActive = isSameDay && moment(to).subtract(6, 'month').isSame(from);
  const customRangeActive = !(byMonthActive || byThreeMonthActive || bySixMonthActive);
  const offsetName = NAVIGATION_OFFSETS[navigationOffset];

  return (
    <div className="navigation">
      <div className="btn-group btn-group-toggle">
        <div
          className={
            classnames('btn', { active: byMonthActive, disabled: isControlsInactive })
          }
          onClick={navigateByMonth}
        >
          Last 30 days
        </div>
        <div
          className={
            classnames('btn', { active: byThreeMonthActive, disabled: isControlsInactive })
          }
          onClick={navigateByThreeMonth}
        >
          Last 90 days
        </div>
        <div
          className={
            classnames('btn', { active: bySixMonthActive, disabled: isControlsInactive })
          }
          onClick={navigateBySixMonth}
        >
          Last 6 months
        </div>
        <RangePicker
          from={from}
          to={to}
          onApply={onApply}
          settings={{
            drops: 'up',
            maxSpan: {
              days: 720
            }
          }}
        >
          <div
            className={
              classnames('btn', { active: customRangeActive, disabled: isControlsInactive })
            }
          >
            Custom
          </div>
        </RangePicker>
      </div>
      <div className="card-actions">
        <div
          className={
            classnames('btn', 'btn-step-prev', { disabled: isControlsInactive })
          }
          onClick={navigateBackward}
        >
          <div className="mdi mdi-24px mdi-chevron-left" />
          {offsetName}
        </div>
        <div
          className={
            classnames('btn', 'btn-step-next', { disabled: isControlsInactive })
          }
          onClick={navigateForward}
        >
          {offsetName}
          <div className="mdi mdi-24px mdi-chevron-right" />
        </div>
      </div>
    </div>
  );
}

export default Navigation