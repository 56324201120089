import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Metrics/selectors'

import {
  changeSettingsAction,
  changeWeightSettingsAction,
  changeTemperatureSettingsAction,
  changeBloodGlucoseSettingsAction,
  changeBloodPressureSettingsAction
} from 'src/react/store/Metrics/actions'

import {
  WidgetTypes,
  RequestStatuses
} from 'src/react/constants/Metrics/index'

import { Modal, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

const SettingsModal = (props) => {
  const settings = useSelector(state => selectors.settings(state));
  const requestStatus = useSelector(state => selectors.requestStatus(state));
  const activeWidgets = useSelector(state => selectors.activeWidgets(state));

  const dispatch = useDispatch();

  const changeSettings = (settings) => dispatch(changeSettingsAction(settings));

  const changeBloodPressureSettings = (settings) => {
    dispatch(changeBloodPressureSettingsAction(settings));
  };

  const changeTemperatureSettings = (settings) => {
    dispatch(changeTemperatureSettingsAction(settings));
  };

  const changeWeightSettings = (settings) => {
    dispatch(changeWeightSettingsAction(settings));
  };

  const changeBloodGlucoseSettings = (settings) => {
    dispatch(changeBloodGlucoseSettingsAction(settings));
  };

  const isActiveWidget = (type) => {
    return activeWidgets.includes(type);
  };

  const isLoading = requestStatus == RequestStatuses.Loading;

  return (
    <Modal
      className="patient-metrics-settings-modal"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4">
          Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={
            classnames('settings-widget', { disabled: isLoading })
          }
        >
          {
            isActiveWidget(WidgetTypes.BloodPressure) && (
              <>
                <div className="settings-widget-line">
                  <div className="caption">Blood Pressure Chart Type</div>
                  <div className="d-flex justify-content-start">
                    <ToggleButtonGroup
                      type="radio"
                      name="view"
                      value={settings.bloodPressure.view}
                      onChange={
                        (view) => changeBloodPressureSettings({ view })
                      }
                      className="btn-group-settings"
                    >
                      <ToggleButton value="linear" variant="white">Linear</ToggleButton>
                      <ToggleButton value="bar" variant="white">Bar</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>

                <div
                  className={
                    classnames(
                      'settings-widget-block',
                      { 'disabled': settings.bloodPressure.view == 'bar' }
                    )
                  }
                >
                  <div className="settings-widget-line">
                    <div className="caption">Blood Pressure Key Measurement</div>
                    <div className="d-flex justify-content-start">
                      <ToggleButtonGroup
                        type="radio"
                        name="rankKey"
                        value={settings.bloodPressure.rankKey}
                        onChange={
                          (rankKey) => changeBloodPressureSettings({ rankKey })
                        }
                        className="btn-group-settings"
                      >
                        <ToggleButton value="systolic" variant="white">Systolic</ToggleButton>
                        <ToggleButton value="diastolic" variant="white">Diastolic</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                  <div className="settings-widget-line">
                    <div className="caption">Display Value</div>
                    <div className="d-flex justify-content-start">
                      <ToggleButtonGroup
                        type="radio"
                        name="rankKeyDirection"
                        value={settings.bloodPressure.rankKeyDirection}
                        onChange={
                          (rankKeyDirection) => changeBloodPressureSettings({ rankKeyDirection })
                        }
                        className="btn-group-settings"
                      >
                        <ToggleButton value="desc" variant="white">Highest</ToggleButton>
                        <ToggleButton value="asc" variant="white">Lowest</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </div>
              </>
            )
          }

          {
            isActiveWidget(WidgetTypes.Temperature) && (
              <div className="settings-widget-line">
                <div className="caption">Temperature Units</div>
                <div className="d-flex justify-content-start">
                  <ToggleButtonGroup
                    type="radio"
                    name="temperatureUnit"
                    value={settings.temperature.unit}
                    onChange={
                      (unit) => changeTemperatureSettings({ unit })
                    }
                    className="btn-group-settings"
                  >
                    <ToggleButton value="fahrenheit" variant="white">°F</ToggleButton>
                    <ToggleButton value="celsius" variant="white">°C</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )
          }

          {
            isActiveWidget(WidgetTypes.Weight) && (
              <div className="settings-widget-line">
                <div className="caption">Weight Units</div>
                <div className="d-flex justify-content-start">
                  <ToggleButtonGroup
                    type="radio"
                    name="weightUnit"
                    value={settings.weight.unit}
                    onChange={
                      (unit) => changeWeightSettings({ unit })
                    }
                    className="btn-group-settings"
                  >
                    <ToggleButton value="lbs" variant="white">Lbs</ToggleButton>
                    <ToggleButton value="kg" variant="white">Kg</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )
          }

          {
            isActiveWidget(WidgetTypes.BloodGlucose) && (
              <div className="settings-widget-line">
                <div className="caption">Blood Glucose Units</div>
                <div className="d-flex justify-content-start">
                  <ToggleButtonGroup
                    type="radio"
                    name="bloodGlucoseUnit"
                    value={settings.bloodGlucose.unit}
                    onChange={
                      (unit) => changeBloodGlucoseSettings({ unit })
                    }
                    className="btn-group-settings"
                  >
                    <ToggleButton value="mg_dl" variant="white">mg/dL</ToggleButton>
                    <ToggleButton value="mmol_l" variant="white">mmol/L</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )
          }

          <div className="settings-widget-line">
            <div className="caption">Step Size</div>
            <div className="d-flex justify-content-start">
              <ToggleButtonGroup
                type="radio"
                name="navigationOffset"
                value={settings.navigationOffset}
                onChange={
                  (navigationOffset) => changeSettings({ navigationOffset })
                }
                className="btn-group-settings"
              >
                <ToggleButton value={1} variant="white">1 day</ToggleButton>
                <ToggleButton value={3} variant="white">3 days</ToggleButton>
                <ToggleButton value={7} variant="white">7 days</ToggleButton>
                <ToggleButton value={14} variant="white">14 days</ToggleButton>
                <ToggleButton value={30} variant="white">1 month</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SettingsModal
