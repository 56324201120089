import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    $('.patient-notes-list').
      replaceWith(data.content);

    $('.flash-alerts').
      html(data.flash);

    $('#add-note-modal .modal').
      modal('hide');
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data }, () => {
      $('#add-note-modal .modal-content').
        replaceWith(data.form);

      $('.modal textarea[autofocus]').focus();
    });
  }
}
