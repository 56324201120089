import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Drilldown from 'highcharts/modules/drilldown'

HighchartsMore(Highcharts)
Drilldown(Highcharts)

import moment from 'moment'

import { UNITS } from 'src/react/constants/Metrics/index'

import { formatPath } from 'src/utils/formatters'

class Base {
  constructor(selector, { applyDrilldown, changeDrilldownLevel }) {
    this.selector = selector;
    this.applyDrilldownCallback = applyDrilldown;
    this.changeDrilldownLevelCallback = changeDrilldownLevel;

    this.settings = {}
    this.dataspace = {};

    this.init();
  }

  init() {
    this.instance = Highcharts.chart(this.selector, this.chartParams());
  }

  chartParams() {
    const self = this;

    let {
      xAxis: [ rootAxis, labelAxis ],
      ... params
    } = this.defaultParams();

    params.chart.events.drillupall = function(e) {
      return self.drillup(this, e);
    }

    params.chart.events.drillupstart = function(e) {
      return self.onDrillupStart();
    }

    params.chart.events.drilldown = function(e) {
      return self.drilldown(this, e);
    }

    params.tooltip.formatter = function(tooltip) {
      return self.tooltipFormatter(this, tooltip);
    }

    params.plotOptions.series.point.events.click = function(e) {
      return self.pointClick(this, e);
    }

    rootAxis.labels.formatter = function() {
      return self.rootAxisFormatter(this);
    }

    labelAxis.labels.formatter = function() {
      return self.labelsAxisFormatter(this);
    }

    params.xAxis = [ rootAxis, labelAxis ];

    return params;
  }

  drillup(chart, e) {
    this.onDrillupEnd(chart);
  }

  drilldown(chart, e) {
    this.showLoading();

    this.onDrilldownRequestStart(chart, e);

    this.applyDrilldownCallback(chart, e);
  }

  applyRoot() {
    this.showLoading();
    this.resetDrilldown();
    this.resetSeries();
  }

  pointClick(point, e) {
    if (point.drilldown) return;

    this.gotoCycleURL(point);
  }

  gotoCycleURL(point) {
    if (!point.cycle_id) return;

    let link = document.createElement('a');

    link.href = formatPath(window.metrics.cycles_path, { id: point.cycle_id });
    link.target = '_blank';

    link.click();
  }

  setRootResults(response) {
    this.onLoadRequestEnd(this.instance, response);

    this.instance.redraw();
  }

  setDrilldownResults(chart, e, response) {
    this.onDrilldownRequestEnd(chart, e, response);

    chart.applyDrilldown();
  }

  showLoading() {
    this.instance.showLoading();
  }

  hideLoading() {
    this.instance.hideLoading();
  }

  onLoadRequestStart(chart) {}

  onLoadRequestEnd(chart, response) {
    this.level = 0;

    this.cacheDataspace(response);
    this.onChangeDrilldownLevel();

    response.series.forEach((object, index) => {
      chart.addSeries(object, false);
    });
  }

  onDrilldownRequestStart(chart, e) {}

  onDrilldownRequestEnd(chart, e, response) {
    this.level += 1;

    this.cacheDataspace(response);
    this.addDrilldownSeries(response, e);
    this.onChangeDrilldownLevel();

    if (this.currentDataspace.mode == 'daily') {
      this.onActivateDaily(chart, e, response);
    }
  }

  addDrilldownSeries(response, e) {
    response.series.forEach((object, index) => {
      this.instance.addSingleSeriesAsDrilldown(e.point, object, false);
    });
  }

  onDrillupStart() {
    this.level -= 1;

    this.onChangeDrilldownLevel();

    if (this.currentDataspace.mode == 'range') {
      this.onDeactivateDaily(this.instance);
    }
  }

  onDrillupEnd(chart) {}

  onChangeDrilldownLevel() {
    this.changeDrilldownLevelCallback(this.level);

    this.switchDailySubtitle();
  }

  cacheDataspace(response) {
    this.dataspace[this.level] = response;
  }

  get currentDataspace() {
    return this.dataspace[this.level];
  }

  switchDailySubtitle() {
    const text = this.currentDataspace.mode == 'daily' ?
                 moment(this.currentDataspace.timeframe.start_date).format('D MMM Y') :
                 null;

    this.instance.setTitle(null, { text });
  }

  resetDrilldown() {
    if (this.instance.drillUpButton) {
      this.instance.drilldownLevels = [];
      this.instance.drillUpButton = this.instance.drillUpButton.destroy();

      this.instance.update(this.chartParams(), false);
    }
  }

  resetSeries() {
    let i = this.instance.series.length - 1;

    while (i >= 0) {
      this.instance.series[i].remove(false);
      i--;
    }
  }

  rootAxisFormatter(label) {
    if (this.currentDataspace.mode == 'daily') return (label.pos + 1);

    const point = this.currentDataspace.series[0].data[label.pos];

    if (point) {
      return moment(point.start_date || point.by_date).format('MMM-DD-YYYY').toUpperCase();
    }
  }

  labelsAxisFormatter(label) {
    if (this.currentDataspace.mode == 'daily') return '';

    const result = this.currentDataspace.labels[label.pos];

    const labelStyle =
      (this.currentDataspace.labels.length > 20) && (this.instance.chartWidth <= 500) ? 'label-sm' : 'label';

    if (result > 1) {
      return `
        <div class="highcharts-top-axis-label ${labelStyle}">
          ${result}
        </div>`;
    }
  }

  tooltipFormatter(context, tooltip) {
    return tooltip.defaultFormatter.call(context, tooltip);
  }

  tooltipHeader(label) {
    return `<em style="font-size: 11px;">${label}</em>`
  }

  tooltipBody(point, result) {
    const value = `${result} ${this.unitAlias(point)}`;

    return `
      <br/>
      <span style="color:${point.series.color}"> ● </span>${point.series.name}: <b>${value}</b>
    `;
  }

  unitAlias(point) {
    return UNITS[point.series.userOptions.unit] || point.series.userOptions.unit || '';
  }

  alert(message, options = {}) {
    const timeout = options.timeout || 3000;
    const box = `<div class="highcharts-alert-box"> ${message} </div>`;

    const label =
      this.instance.renderer.label(box, 0, 0, null, null, null, true, false, 'top-priority-box').
      add().
      attr({ align: 'center' }).
      align({ align: 'center', verticalAlign: 'top' }, null, 'plotBox');

    setTimeout(() => label.fadeOut(), timeout);
  }

  drilldownRangeOptions(e) {
    const { start_date, end_date, by_date } = e.point;

    return by_date ? { by_date } : { start_date, end_date };
  }

  updateSettings(settings) {
    this.settings = settings;
  }
}

export default Base
