const getState = state => state.meetings;

const history = state => getState(state).history;
const isMoreHistory = state => getState(state).isMoreHistory;
const isLoadingHistory = state => getState(state).isLoadingHistory;
const isLoadingHistoryError = state => getState(state).isLoadingHistoryError;

const latestMeetings = state => getState(state).latestMeetings;
const isLoadingLatestMeetings = state => getState(state).isLoadingLatestMeetings;
const isLoadingLatestMeetingsError = state => getState(state).isLoadingLatestMeetingsError;

const lineStatus = state => getState(state).lineStatus;
const websocketsStatus = state => getState(state).websocketsStatus;
const zoomStatus = state => getState(state).zoomStatus;

const currentUser = state => getState(state).currentUser;
const currentSubscriber = state => getState(state).currentSubscriber;
const currentMeeting = state => getState(state).currentMeeting;
const currentMeetingId = state => currentMeeting(state).id;
const currentMeetingStatus = state => currentMeeting(state).status;
const currentMeetingCredentials = state => currentMeeting(state).credentials;

const cameraBusy = state => getState(state).cameraBusy;
const cameraStatus = state => getState(state).cameraStatus;
const cameraClosing = state => getState(state).cameraClosing;
const microphoneBusy = state => getState(state).microphoneBusy;
const microphoneStatus = state => getState(state).microphoneStatus;
const microphoneClosing = state => getState(state).microphoneClosing;
const audioStreamReady = state => getState(state).audioStreamReady;

const inMeeting = state => getState(state).inMeeting;
const meetingType = state => getState(state).meetingType;
const meetingStatus = state => getState(state).meetingStatus;
const meetingSubscribersOnline = state => getState(state).meetingSubscribersOnline;

const modalShow = state => getState(state).modalShow;
const modalAction = state => getState(state).modalAction;

export {
  getState,
  modalShow,
  modalAction,
  history,
  isMoreHistory,
  isLoadingHistory,
  isLoadingHistoryError,
  latestMeetings,
  isLoadingLatestMeetings,
  isLoadingLatestMeetingsError,
  lineStatus,
  websocketsStatus,
  zoomStatus,
  currentUser,
  currentSubscriber,
  currentMeeting,
  currentMeetingId,
  currentMeetingStatus,
  currentMeetingCredentials,
  cameraBusy,
  cameraStatus,
  cameraClosing,
  microphoneBusy,
  microphoneStatus,
  microphoneClosing,
  audioStreamReady,
  inMeeting,
  meetingType,
  meetingStatus,
  meetingSubscribersOnline
}
