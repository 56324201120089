import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getMeeting } from 'src/react/store/Meetings/actions'

import moment from 'moment'
import { formatDuration } from 'src/utils/formatDuration'

import {
  HistoryStatuses,
  DATETIME_FORMAT
} from 'src/react/constants/Meetings/index'

import { OverlayTrigger, Popover } from 'react-bootstrap'
import HistoryStatus from './HistoryStatus'

const HistoryItem = ({ meeting, currentUser: { tz_offset }}) => {
  const {
    id,
    status,
    duration,
    created_at,
    _lazy,
    initiator: { name: initiator_name },
    subscriber: { name: subscriber_name },
  } = meeting;

  const dispatch = useDispatch();

  useEffect(() => {
    if (_lazy) dispatch(getMeeting(id));
  }, [id, _lazy]);

  if (_lazy) return null;

  const popoverOverlay = (
    <Popover id={`tooltip-${id}`}>
      <Popover.Title>
        <HistoryStatus status={status} />
      </Popover.Title>
      <Popover.Content>
        <div>
          <i className="mdi mdi-account-multiple" />{' '}
          <strong>
            <span className="initiator">{' '}
              {initiator_name}
            </span>
             <i className="mdi mdi-arrow-right" />
            <span className="subscriber">{' '}
              {subscriber_name}
            </span>
          </strong>
        </div>

        {
          status == HistoryStatuses.Ended && (
            <div className="mt-1">
              <i className="mdi mdi-clock-outline" />{' '}
              {formatDuration(duration)}
            </div>
          )
        }

        <div className="mt-1">
          <i className="mdi mdi-calendar-blank" />{' '}
          <span className="text-uppercase">
            {moment(created_at).utcOffset(tz_offset).format(DATETIME_FORMAT)}
          </span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="list-group-item px-1 py-2">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <HistoryStatus status={status} />

          <div className="text-muted">
            <i className="mdi mdi-account" />{' '}
            {subscriber_name}
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <OverlayTrigger overlay={popoverOverlay}>
            <div
              role="button"
              className="mdi mdi-btn mdi-information-outline text-primary"
            />
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default HistoryItem