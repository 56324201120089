import React, { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useZoomConnection } from 'src/react/hooks/Meetings/useZoomConnection'

import ZoomContext from 'src/react/contexts/Meetings/zoom-context'
import ZoomMediaStateContext from 'src/react/contexts/Meetings/media-state-context'

import * as selectors from 'src/react/store/Meetings/selectors'
import { setAudioStreamReadyAction } from 'src/react/store/Meetings/actions'

import { ZoomStatuses } from 'src/react/constants/Meetings/index'

import LatencyScreen from './LatencyScreen'
import VideoContainer from './VideoContainer'

const VideoClient = () => {
  const zoomStatus = useSelector(state => selectors.zoomStatus(state));

  const zmClient = useContext(ZoomContext);

  const dispatch = useDispatch();

  const { mediaState } = useZoomConnection(zmClient);

  useEffect(() => {
    if (zmClient && mediaState.audio.decode && mediaState.audio.encode) {
      dispatch(setAudioStreamReadyAction(true));
    }
  }, [zmClient, mediaState]);

  if (
    zoomStatus == ZoomStatuses.Free ||
    zoomStatus == ZoomStatuses.Pending
  ) {
    return (
      <LatencyScreen caption={'Joining meeting ...'} />
    );
  }

  return (
    <ZoomMediaStateContext.Provider value={mediaState}>
      <VideoContainer />
    </ZoomMediaStateContext.Provider>
  );
};

export default VideoClient
