import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'

import { useCommandChannel } from 'src/react/hooks/Meetings/useCommandChannel'
import { useMeetingChannel } from 'src/react/hooks/Meetings/useMeetingChannel'
import { useMeetingClient } from 'src/react/hooks/Meetings/useMeetingClient'
import { useMeetingCamera } from 'src/react/hooks/Meetings/useMeetingCamera'
import { useMeetingMicrophone } from 'src/react/hooks/Meetings/useMeetingMicrophone'
import { useMeetingWatchdog } from 'src/react/hooks/Meetings/useMeetingWatchdog'
import { useWebsocketsStatus } from 'src/react/hooks/Meetings/useWebsocketsStatus'
import { useStaleConnection } from 'src/react/hooks/Meetings/useStaleConnection'

import ActionCableContext from 'src/react/contexts/Meetings/action-cable-context'
import ZoomContext from 'src/react/contexts/Meetings/zoom-context'
import ZoomMediaStreamContext from 'src/react/contexts/Meetings/media-stream-context'
import MeetingChannelContext from 'src/react/contexts/Meetings/meeting-channel-context'

import Headline from './Headline'
import Activities from './Activities'
import MeetingContainer from './MeetingContainer'
import ModalFactory from './ModalFactory'

const Page = () => {
  const actionCable = useContext(ActionCableContext);

  const { channel } = useCommandChannel(actionCable);

  useMeetingChannel(actionCable);
  useStaleConnection(actionCable);

  const { zmClient, mediaStream } = useMeetingClient();

  useMeetingCamera(mediaStream);
  useMeetingMicrophone(mediaStream);

  useMeetingWatchdog();
  useWebsocketsStatus();

  return (
    <div className="container-fluid">
      <div className="main-content">
        <div className="meetings">
          <div className="card">
            <div className="card-header">
              <Headline />
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <MeetingChannelContext.Provider value={{ channel }}>
                    <ZoomContext.Provider value={zmClient}>
                      <ZoomMediaStreamContext.Provider value={mediaStream}>
                        <MeetingContainer />
                      </ZoomMediaStreamContext.Provider>
                    </ZoomContext.Provider>
                  </MeetingChannelContext.Provider>
                </div>
                <div className="col-lg-3">
                  <Activities />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      <ModalFactory />
    </div>
  );
}

export default Page
