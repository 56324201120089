import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["entity", "actions", "user", "cycle"];
  static values = { entityActions: Object }

  connect() {
    this.handleEntityChange(this.entityTarget.value);

    this.entityTarget.addEventListener('change', (e) => this.handleEntityChange(e.target.value));
  }

  handleEntityChange(selectedEntity) {
    this.toggleCycle(selectedEntity);
    this.toggleUser(selectedEntity);
    this.toggleEntityActions(selectedEntity);
  }

  toggleEntityActions(selectedEntity) {
    this.actionsOptions.forEach((option) => {
      if (!selectedEntity) {
        option.hidden = false;

        return;
      }
      
      if (this.entityActionsValue[selectedEntity].includes(option.value) || option.value === '') {
        option.hidden = false;
      } else {
        option.hidden = true;
        option.selected = false;
      }
    })
  }

  toggleUser(selectedEntity) {
    if (['MedicalGroupPatient', 'MedicalGroupMember'].includes(selectedEntity)) {
      this.userTarget.parentElement.hidden = false;
    } else {
      this.userTarget.parentElement.hidden = true;
      this.userTarget.selectedOptions[0].selected = false;
    }
  }

  toggleCycle(selectedEntity) {
    if (selectedEntity === 'Cycle') {
      this.cycleTarget.parentElement.hidden = false;
    } else {
      this.cycleTarget.parentElement.hidden = true;
      this.cycleTarget.value = '';
    }
  }

  get actionsOptions() {
    return this.actionsTarget.querySelectorAll('option');
  }
}