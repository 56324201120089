import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ZoomVideo from '@zoom/videosdk'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  ZoomStatuses,
  MeetingStatuses,
  JOIN_ZOOM_MEETING_ATTEMPTS
} from 'src/react/constants/Meetings/index'

import {
  endMeeting,
  setZoomStatusAction
} from 'src/react/store/Meetings/actions'

import { retryCatch } from 'src/react/utils/Meetings/tools'

export function useMeetingClient() {
  const zoomStatus = useSelector(state => selectors.zoomStatus(state));
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));
  const cameraBusy = useSelector(state => selectors.cameraBusy(state));
  const microphoneBusy = useSelector(state => selectors.microphoneBusy(state));
  const currentMeetingCredentials = useSelector(state => selectors.currentMeetingCredentials(state));

  const dispatch = useDispatch();

  const [zmClient, setZmClient] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);

  const joinZoomMeeting = ({ topic, signature, name, password }) => {
    dispatch(setZoomStatusAction(ZoomStatuses.Pending));

    const client = ZoomVideo.createClient();

    setZmClient(client);

    const init = async () => {
      try {
        await client.init('en-US', 'CDN', {
          stayAwake: true
        });

        try {
          retryCatch(async () => {
            console.log('try join zoom ...');

            await client.join(topic, signature, name, password);

            const stream = client.getMediaStream();

            setMediaStream(stream);

            dispatch(setZoomStatusAction(ZoomStatuses.Busy));
          }, JOIN_ZOOM_MEETING_ATTEMPTS);
        } catch (e) {
          console.log(e);
        }

      } catch (e) {
        console.log(e);
      }
    };

    init();
  };

  const shutdownMeeting = () => dispatch(endMeeting());

  const shutdownZoomMeeting = async () => {
    try {
      await zmClient.leave();
    } catch (e) {
      console.log('leave zoom exception', e);
    }

    try {
      ZoomVideo.destroyClient();
    } catch (e) {
      console.log('destroy zoom exception', e);
    }

    setZmClient(null);
    setMediaStream(null);

    shutdownMeeting();
  };

  useEffect(() => {
    if (meetingStatus == MeetingStatuses.Active) joinZoomMeeting(currentMeetingCredentials);
  }, [meetingStatus, currentMeetingCredentials]);

  useEffect(() => {
    // console.log('zoomStatus', zoomStatus, 'meetingStatus', meetingStatus, 'cameraBusy', cameraBusy, 'microphoneBusy', microphoneBusy);

    if (zoomStatus == ZoomStatuses.Free) {
      if (meetingStatus == MeetingStatuses.Ending) shutdownMeeting();
    } else {
      if (meetingStatus == MeetingStatuses.Ending && !cameraBusy && !microphoneBusy) shutdownZoomMeeting();
    }
  }, [zmClient, zoomStatus, meetingStatus, cameraBusy, microphoneBusy]);

  return {
    zmClient,
    mediaStream
  };
}
