import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { leavingMeeting } from 'src/react/store/Meetings/actions'

import MeetingChannelContext from 'src/react/contexts/Meetings/meeting-channel-context'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const LeaveButton = () => {
  const { id } = useSelector(state => selectors.currentMeeting(state));

  const { channel } = useContext(MeetingChannelContext);

  const dispatch = useDispatch();

  const leavingMeetingClick = () => dispatch(leavingMeeting({ id, channel }));

  return (
    <div className="reject">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="leave-tooltip">
            Leave Meeting
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <Button
            variant="danger"
            className="d-inline-flex align-items-center reject-button"
            onClick={leavingMeetingClick}
            ref={ref}
            {...triggerHandler}
          >
            <span className="mdi mdi-phone-hangup"></span>
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );

};

export default LeaveButton
