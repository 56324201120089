import React, { useEffect, useState } from 'react'
import { useInterval } from 'src/react/hooks/Meetings/useInterval'

import moment from 'moment'
import { DATETIME_FORMAT } from 'src/react/constants/Meetings/index'

import DaylineWidget from 'src/react/components/Meetings/DaylineWidget'

const WelcomeWidget = ({ user: { name, tz_offset } }) => {
  const getCurrentTime = () => moment().utcOffset(tz_offset);

  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    setCurrentTime(getCurrentTime());
  }, [name, tz_offset]);

  useInterval(() => {
    setCurrentTime(getCurrentTime);
  }, 1000);

  return (
    <>
      <DaylineWidget hours={currentTime.hours()} />

      <p className="lead text-white">
        {name},{' '}
        <span className="text-uppercase">
          {currentTime.format(DATETIME_FORMAT)}
        </span>
      </p>
    </>

  );
};

export default WelcomeWidget
