import React from 'react'

import classnames from 'classnames'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const CameraButton = ({
  isCameraBusy,
  isStartedVideo,
  onCameraClick
}) => {
  const tooltipText = isStartedVideo ? 'Stop camera' : 'Start camera';

  return (
    <div className="camera">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="camera-tooltip">
            {tooltipText}
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <Button
            disabled={isCameraBusy}
            variant={isStartedVideo ? 'dark' : 'danger'}
            className="d-inline-flex align-items-center camera-button text-white"
            onClick={onCameraClick}
            ref={ref}
            {...triggerHandler}
          >
            <span
              className={
                classnames('mdi', isStartedVideo ? 'mdi-video' : 'mdi-video-off')
              }
            />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );
};
export default CameraButton
