import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    $('#edit-modal .modal').modal('hide');
    $('.flash-alerts').html(data.flash);
    $('#root-context').html(data.content);
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data }, () => {
      $('#edit-modal .modal-content').replaceWith(data.form);
      $('.modal textarea[autofocus]').focus();
    });
  }
}
