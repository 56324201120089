import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect() {
    this.mask = IMask(this.element, this.maskOptions);

    this.mask.on('complete', () => {
      this.dispatch('complete', { detail: { macAddress: this.mask.value } })
    });

    this.mask.on('accept', () => {
      this.dispatch('accept', {
        detail: {
          macAddress: this.mask.value,
          isCompleted: this.mask.masked.isComplete
        }
      })
    });
  }

  disconnect() {
    this.mask.destroy();
  }

  synchronize() {
    this.mask.updateValue();
    this.mask.updateControl();
  }

  get maskOptions() {
    return {
      mask: 'AA-AA-AA-AA-AA-AA',
      prepareChar: value => value.toUpperCase(),
      eager: true,
      definitions: {
        A: /[0-9A-Fa-f]/
      }
    };
  }
}
