import React, { useRef, useLayoutEffect } from 'react'
import { Form } from 'react-final-form'
import { FormControl } from 'react-bootstrap'

import $ from 'jquery'
import 'air-datepicker'
import 'air-datepicker/dist/js/i18n/datepicker.en'

const DatePickerField = ({
  input,
  meta,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  minDate = null,
  maxDate = null,
  selectedDate = null,
  upcase = false,
  dateFormat = 'M-dd-yyyy',
  autoClose = true,
  position = 'top left',
  language = 'en',
  toggleSelected = false,
  ...rest
}) => {
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    const el = inputRef.current;

    const datePickerOptions = ((options = {}) => {
      if (selectedDate) options.selectedDate = new Date(selectedDate);
      if (minDate) options.minDate = new Date(minDate);
      if (maxDate) options.maxDate = new Date(maxDate);

      if (dateFormat) options.dateFormat = dateFormat;
      if (position) options.position = position;
      if (language) options.language = language;

      options.autoClose = autoClose;
      options.toggleSelected = toggleSelected;

      options.onSelect = ((formattedDate, date, inst) => {
        if (upcase) el.value = formattedDate.toUpperCase();

        input.onChange(formattedDate);
      });

      return options;
    })();

    const datePicker = $(el).datepicker(datePickerOptions).data('datepicker');

    datePicker.selectDate(datePickerOptions.selectedDate);

    return () => {
      datePicker.destroy();
    }
  }, []);

  return (
    <FormControl
      {...rest}
      ref={inputRef}
      isInvalid={invalid(meta)}
      isValid={valid(meta)}
    />
  );
};

export default DatePickerField
