import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    this.responsibleSelector(data.id).replaceWith(data.content);

    if (data.previous_responsible) {
      this.responsibleSelector(data.previous_responsible.id).
        replaceWith(data.previous_responsible.content);
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data });
  }

  responsibleSelector(id) {
    return $(`#medical_group_responsibility_member_${id}`);
  }
}
