import moment from 'moment'

import {
  UNITS,
  DATE_FORMAT,
  DEFAULT_OPTIONS,
  WEIGHT
} from 'src/react/constants/Metrics/index'

import Base from './Base'

class Weight extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, WEIGHT);
  }

  onLoadRequestEnd(chart, response) {
    super.onLoadRequestEnd(chart, response);

    this.applyUnitsByAxis(chart);
  }

  applyUnitsByAxis(chart) {
    const {
      settings: {
        weight: {
          unit
        }
      }
    } = this.settings;

    const options = {
      title: {
        text: UNITS[unit]
      }
    };

    chart.yAxis[0].update(options, false);
  }

  onActivateDaily(chart, e, response) { }
  onDeactivateDaily(chart) { }

  tooltipFormatter(context, tooltip) {
    return this.tooltipHeader(this.tooltipLabel(context.point).toUpperCase()) +
           this.tooltipBody(context.point, this.tooltipResult(context.point));
  }

  tooltipLabel(point) {
    if (point.by_date) return moment(point.by_date).format(DATE_FORMAT);

    return `${moment(point.start_date).format(DATE_FORMAT)} - ${moment(point.end_date).format(DATE_FORMAT)}`;
  }

  tooltipResult(point) {
    return point.by_date ? point.y : `${point.low} - ${point.high}`;
  }

  requestOptions() {
    const {
      settings: {
        weight: {
          unit
        }
      }
    } = this.settings;

    return {
      unit,
      widget: 'weight'
    };
  }
}

export default Weight
