import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useMeetingStart } from 'src/react/hooks/Meetings/useMeetingStart'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  changeCurrentSubscriber,
  loadSubscribers
} from 'src/react/store/Meetings/actions'

import {
  WebsocketsStatuses,
  LineStatuses
} from 'src/react/constants/Meetings/index'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Subscriber from './Subscriber'

const Headline = () => {
  const lineStatus = useSelector(state => selectors.lineStatus(state));
  const websocketsStatus = useSelector(state => selectors.websocketsStatus(state));
  const currentSubscriber = useSelector(state => selectors.currentSubscriber(state));

  const dispatch = useDispatch();

  const { isReady, startMeetingClick } = useMeetingStart();

  const changeSubscriber = (user) => dispatch(changeCurrentSubscriber(user));

  return (
    <div className="d-flex justify-content-between">
      <h4 className="mt-2"> Meetings </h4>

      {
        lineStatus == LineStatuses.Free && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="subscriber">
              <Subscriber
                user={currentSubscriber}
                changeSubscriber={changeSubscriber}
                loadOptions={loadSubscribers}
              />
            </div>

            <OverlayTrigger
              placement="top"
              overlay={
                !currentSubscriber || websocketsStatus != WebsocketsStatuses.Active ? (
                  <Tooltip id={`currentTooltip`}>
                    {
                      websocketsStatus != WebsocketsStatuses.Active ? (
                        'Connection is not established'
                      ) : (
                        !currentSubscriber && 'Select recipient firstly'
                      )
                    }
                  </Tooltip>
                ) : (
                  <span />
                )
              }
            >
              {({ ref, ...triggerHandler }) => (
                <button
                  className="btn btn-white btn-lg btn-outline text-secondary"
                  onClick={startMeetingClick}
                  disabled={!isReady}
                  ref={ref}
                  {...triggerHandler}
                >
                  <div className="mdi mdi-btn mdi-video"></div>
                </button>
              )}
            </OverlayTrigger>
          </div>
        )
      }

    </div>
  );
}

export default Headline
