import { Controller } from 'stimulus'
import escape from 'src/utils/sanitize'
import 'devbridge-autocomplete'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

export default class extends Controller {
  connect() {
    const self = this;
    const url = this.data.get('url');
    const type = this.data.get('type');
    const key = this.fieldsMappers(type);

    $(this.element).autocomplete({
      serviceUrl: url,
      dataType: 'json',
      params: { type },
      minChars: 1,
      deferRequestBy: 200,
      showNoSuggestionNotice: true,
      noSuggestionNotice: `<div class="p-2"> No results found <div>`,
      onSelect: ({ value }) => {
        this.dispatch('select', { detail: { value } });
      },
      transformResult: (response) => {
        return {
          suggestions: $.map(response.suggestions, function(item) {
            return { value: item[key], data: item };
          })
        };
      },
      formatResult: (suggestion, currentValue) => {
        if (!currentValue) {
          return suggestion.value;
        }

        switch (type) {
          case 'report_id':
            return `
              <div class="name"> ${escape(currentValue, suggestion.data.short_key)} </div>
              <div class="report"> ${escape(currentValue, suggestion.data.complex_key)} </div>
            `;

          case 'medical_group':
          case 'patient_group':
          case 'rule':
            return `<div class="name"> ${escape(currentValue, suggestion.data.name)} </div>`;

          case 'user':
          case 'member':
          case 'rendering_physician':
            return self.formatUsers(suggestion, currentValue, 'member');
          case 'patient':
            return self.formatPatients(suggestion, currentValue, 'patient');
          case 'protocol':
            return `
              <div class="name"> ${escape(currentValue, suggestion.data.id)} </div>
              <div class="report"> ${escape(currentValue, suggestion.data.name)} </div>
            `;
          case 'patient_file':
            return `<div class="patient-file">${escape(currentValue, suggestion.value)}</div>`;
          case 'charge_item_uuid':
            return `<div class="name"> ${escape(currentValue, suggestion.data.uuid_key)} </div>`;
          case 'device_serial_number':
            return `<div class="name"> ${escape(currentValue, suggestion.data.serial_number)} </div>`;
          case 'device_mac_address':
            return `<div class="name"> ${escape(currentValue, suggestion.data.mac_address)} </div>`;
        }
      },
      onSearchError: (query, xhr) => {
        new ErrorsInterceptor({ xhr });
      }
    });
  }

  formatUsers(suggestion, currentValue, roleType) {
    return `
      <div class="user-list-widget">
        <div class="avatar"
          data-controller="helpers--avatar"
          data-image-url="${suggestion.data.avatar.thumb.url}"
          data-name="${suggestion.data.name}"
          data-role="${roleType}"
          data-size="32">
        </div>

        <div class="content">
          <div class="name"> ${escape(currentValue, suggestion.data.name)} </div>
          <div class="email"> ${escape(currentValue, suggestion.data.email)} </div> 
       </div>
      </div>
    `;
  }

  formatPatients(suggestion, currentValue, roleType) {
    return `
      <div class="user-list-widget">
        <div class="content">
          <div class="name"> ${escape(currentValue, suggestion.data.name)} </div>
          <div class="email"> ${patientInfoLabel(suggestion.data)} </div> 
       </div>
      </div>
    `;
  }

  fieldsMappers(type) {
    return {
      'member': 'name',
      'patient': '_name',
      'report_id': 'complex_key',
      'medical_group': 'name',
      'patient_file': 'filename',
      'clinic': 'title',
      'rule': 'name',
      'patient_group': 'name',
      'protocol': 'name',
      'rendering_physician': 'name',
      'charge_item_uuid': 'uuid_key',
      'device_serial_number': 'serial_number',
      'device_mac_address': 'mac_address',
    }[type];
  }
}
