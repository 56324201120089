import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  setCameraStatusAction,
  setCameraBusyAction,
  setCameraClosingAction,
} from 'src/react/store/Meetings/actions'

import {
  ZoomStatuses,
  CameraStatuses,
  MeetingStatuses
} from 'src/react/constants/Meetings/index'

export function useMeetingCamera(mediaStream) {
  const zoomStatus = useSelector(state => selectors.zoomStatus(state));
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));
  const cameraBusy = useSelector(state => selectors.cameraBusy(state));
  const cameraStatus = useSelector(state => selectors.cameraStatus(state));
  const cameraClosing = useSelector(state => selectors.cameraClosing(state));

  const dispatch = useDispatch();

  const startVideo = async () => {
    // console.log('starting video ...');

    dispatch(setCameraBusyAction(true));
    dispatch(setCameraStatusAction(CameraStatuses.Pending));

    try {
      await mediaStream.startVideo();

      dispatch(setCameraStatusAction(CameraStatuses.Ready));
    } catch (e) {
      console.log('start video exception: ', e);

      dispatch(setCameraBusyAction(false));

      if (e.type == 'VIDEO_USER_FORBIDDEN_CAPTURE') {
        toast.error(`
          Access is denied for video camera in your browser.
          Please allow video camera permissions and restart meeting.
        `, { autoClose: false });

        dispatch(setCameraStatusAction(CameraStatuses.Unpermitted));
      }
    }
  };

  const stopVideo = async () => {
    if (cameraClosing) return;

    // console.log('stopping video ...');

    dispatch(setCameraClosingAction(true));

    try {
      await mediaStream.stopVideo();
    } catch (e) {
      console.log('stop video exception: ', e);
    }

    dispatch(setCameraClosingAction(false));
    dispatch(setCameraBusyAction(false));
  };

  useEffect(() => {
    if (zoomStatus == ZoomStatuses.Busy) startVideo();
  }, [zoomStatus, mediaStream]);

  useEffect(() => {
    //console.log('cameraStatus:', cameraStatus, 'currentMeetingStatus:', currentMeetingStatus, 'cameraBusy', cameraBusy);

    if (
      meetingStatus == MeetingStatuses.Ending &&
      cameraStatus == CameraStatuses.Ready &&
      cameraBusy
    ) stopVideo();
  }, [meetingStatus, cameraStatus, cameraBusy]);
}
