import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  LineStatuses,
  MeetingStatuses
} from 'src/react/constants/Meetings/index'

import MeetingStatus from './CurrentMeeting/MeetingStatus'
import MeetingDuration from './CurrentMeeting/MeetingDuration'

const CurrentMeeting = () => {
  const lineStatus = useSelector(state => selectors.lineStatus(state));
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));

  if (lineStatus != LineStatuses.Busy) return null;

  return (
    <div className="active-meeting">
      <div className="d-flex justify-content-between align-items-center my-3">
        <h4 className="m-0">Current Meeting</h4>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <MeetingStatus status={meetingStatus} />

        <div className="text-secondary">
          {
            meetingStatus == MeetingStatuses.Active && (
              <MeetingDuration />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CurrentMeeting
