import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  //connect() {
    //this.submitButtons.forEach(b => b.addEventListener('click', (e) => {
    //  e.target.dataset.disableWith = e.target.dataset.await;
    //}));
  //}

  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    switch (data.response_status) {
      case 'with_retry':
        return this.replaceModalForm(data);
      default:
        return this.closeModal(data);
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data }, () => this.replaceModalForm(data));
  }

  replaceModalForm(data) {
    $('#create-modal .modal-content').
      replaceWith(data.form);
  }

  closeModal(data) {
    $('.flash-alerts').
      html(data.flash);

    $('#create-modal .modal').
      modal('hide');
  }

  //get submitButtons() {
  //  return this.element.querySelectorAll('[data-await]');
  //}
}
