export async function retryCatch(callback, times = 1) {
  try {
    return await callback();
  } catch (error) {
    if (times > 0) {
      return await retryCatch(callback, times - 1);
    } else {
      throw error;
    }
  }
}