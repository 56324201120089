import moment from 'moment'

import {
  UNITS,
  DATE_FORMAT,
  DEFAULT_OPTIONS,
  BLOOD_GLUCOSE
} from 'src/react/constants/Metrics/index'

import Base from './Base'

class BloodGlucose extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, BLOOD_GLUCOSE);
  }

  onLoadRequestEnd(chart, response) {
    super.onLoadRequestEnd(chart, response);

    this.applyUnitsByAxis(chart);
  }

  applyUnitsByAxis(chart) {
    const {
      settings: {
        bloodGlucose: {
          unit
        }
      }
    } = this.settings;

    let options;

    switch (unit) {
      case 'mg_dl':
        options = {
          min: 70,
          max: 400,
          tickInterval: 50
        }
        break;
      case 'mmol_l':
        options = {
          min: 3.9,
          max: 22.2,
          tickInterval: 2.5
        }
        break;
      default:
        throw 'undefined unit';
    }

    options.title = {
      text: UNITS[unit]
    };

    chart.yAxis[0].update(options, false);
  }

  onActivateDaily(chart, e, response) {}
  onDeactivateDaily(chart) {}

  tooltipFormatter(context, tooltip) {
    return this.tooltipHeader(this.tooltipLabel(context.point).toUpperCase()) +
           this.tooltipBody(context.point, this.tooltipResult(context.point));
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format(DATE_FORMAT);

    return `${moment(point.start_date).format(DATE_FORMAT)} - ${moment(point.end_date).format(DATE_FORMAT)}`;
  }

  tooltipResult(point) {
    return (this.currentDataspace.mode == 'range') ? `${point.low} - ${point.high}` : point.y;
  }


  requestOptions() {
    const {
      settings: {
        bloodGlucose: {
          unit
        }
      }
    } = this.settings;

    return {
      unit,
      widget: 'blood_glucose'
    };
  }
}

export default BloodGlucose
