import Highcharts from 'highcharts'
import moment from 'moment'

import { DEFAULT_PARAMS, DATE_FORMAT } from 'src/charts/timing/constants'

class Chart {
  constructor(selector) {
    this.selector = selector;
    this.init();
  }

  init() {
    if (this.isPayloadEmpty) {
      this.selector.style.display = 'none'
    } else {
      this.selector.style.display = 'block';
      const chart = Highcharts.chart(this.selector, this.options);
      chart.reflow();
    }
  }

  get options() {
    return Object.assign({}, DEFAULT_PARAMS, this.series, this.xAxis);
  }

  get series() {
    return {
      series: [{
        name: 'Tracked Time',
        type: 'column',
        data: this.timing_data
      }]
    };
  }

  get payload() {
    const { payload } = this.selector.dataset;
    return JSON.parse(payload);
  }

  get isPayloadEmpty() {
    return this.payload.length === 0;
  }

  get dates() {
    return this.payload.map(el => moment(el.start_date).format(DATE_FORMAT).toUpperCase());
  }

  get xAxis() {
    return {
      xAxis: [{
        categories: this.dates,
        crosshair: true,
        grouping: this.payload[0].grouping
      }]
    };
  }

  get timing_data() {
    return this.payload.map(el => el.time_amount);
  }
}

export default Chart
