import React from 'react'
import { useSelector } from 'react-redux'
import { useMeetingStart } from 'src/react/hooks/Meetings/useMeetingStart'

import * as selectors from 'src/react/store/Meetings/selectors'

import WelcomeWidget from 'src/react/components/Meetings/WelcomeWidget'

const WelcomeScreen = () => {
  const currentUser = useSelector(state => selectors.currentUser(state));
  const currentSubscriber = useSelector(state => selectors.currentSubscriber(state));
  const cameraBusy = useSelector(state => selectors.cameraBusy(state));
  const microphoneBusy = useSelector(state => selectors.microphoneBusy(state));

  const { isReady, isPending, startMeetingClick } = useMeetingStart();

  return (
    <div className="jumbotron lockscreen">
      <WelcomeWidget user={currentSubscriber || currentUser} />

      <div className="mt-6">
        {
          cameraBusy && (
            <h4 className="text-white d-flex align-items-center">
              <div className="mdi mdi-video mr-1" />
              Camera is still busy.
            </h4>
          )
        }

        {
          microphoneBusy && (
            <h4 className="text-white d-flex align-items-center">
              <div className="mdi mdi-microphone mr-1" />
              Microphone is still busy.
            </h4>
          )
        }

        {
          isPending && (
            <h4 className="text-white d-flex align-items-center">
              <div className="mdi mdi-loading mdi-spin mr-1" />
              Initialize meeting ...
            </h4>
          )
        }

        {
          isReady && (
            <button
              className="btn btn-primary btn-lg btn-outline"
              onClick={startMeetingClick}
            >
              <div className="mdi mdi-btn mdi-video mr-1" />
              Make a call
            </button>
          )
        }
      </div>
    </div>
  );
}

export default WelcomeScreen
