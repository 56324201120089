import React, { memo } from 'react'
import classnames from 'classnames'

const DaylineWidget = memo(({ hours }) => {
  const dayline = () => {
    // 00:00 - 5:59
    if (hours >= 0 && hours < 6) {
      return ['mdi-weather-night', 'Good Night'];
    }

    // 6:00 - 11:59
    if (hours >= 6 && hours < 12) {
      return ['mdi-white-balance-sunny', 'Good Morning'];
    }

    // 12:00 - 15:59
    if (hours >= 12 && hours < 16) {
      return ['mdi-white-balance-sunny', 'Good Afternoon'];
    }

    // 16:00 - 23:59
    return ['mdi-lamp', 'Good Evening'];
  }

  const [icon, caption] = dayline();

  return (
    <h1 className="display-3 text-warning">
      <div className={classnames('mdi', icon)}></div>
      {caption}
    </h1>
  );
});

export default DaylineWidget
