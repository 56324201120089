import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  getLatestMeetings,
  showModalAction
} from 'src/react/store/Meetings/actions'

import HistoryItem from 'src/react/components/Meetings/History/HistoryItem'

const LatestMeetings = () => {
  const latestMeetings = useSelector(state => selectors.latestMeetings(state));
  const isLoadingLatestMeetings = useSelector(state => selectors.isLoadingLatestMeetings(state));
  const isLoadingLatestMeetingsError = useSelector(state => selectors.isLoadingLatestMeetingsError(state));
  const currentUser = useSelector(state => selectors.currentUser(state));

  const dispatch = useDispatch();

  const seeHistoryClick = () => dispatch(showModalAction('history'));

  useEffect(() => {
    dispatch(getLatestMeetings());
  }, []);

  return (
    <div className="history">
      <div className="d-flex justify-content-between align-items-center my-3">
        <h4 className="m-0">Recently</h4>
        <button
          onClick={seeHistoryClick}
          className="btn btn-white btn-sm btn-outline text-secondary"
        >
          <i className="mdi mdi-btn mdi-text-box-search-outline mr-1" />
          All
        </button>
      </div>
      <div className="list-group list-group-flush scrollable-list">
        {
          latestMeetings.map(meeting => (
            <HistoryItem
              key={meeting.id}
              meeting={meeting}
              currentUser={currentUser}
            />
          ))
        }

        {
          isLoadingLatestMeetings ? (
            <div className="d-flex justify-content-center mt-1">
              <div className="text-secondary">
                <span className="mdi mdi-loading mdi-spin" />
                <span className="ml-1">Loading ...</span>
              </div>
            </div>
          ) : (
            latestMeetings.length == 0 && !isLoadingLatestMeetingsError && (
              <div className="text-secondary text-center">
                No meetings yet
              </div>
            )
          )
        }

        {
          isLoadingLatestMeetingsError && (
            <div className="text-center text-danger">
              <span className="mdi mdi-alert-circle-outline" />
              <span className="ml-1">Meetings loading error</span>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default LatestMeetings
