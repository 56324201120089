import arrayRange from 'src/utils/arrays/arrayRange'

/*
const PLOT_BANDS = [
  {
    from: 40,
    to: 250,
    color: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1
      },
      stops: [
        [0, 'rgba(240, 65, 65, 0.1)'],
        [0.33, 'rgba(255, 164, 15, 0.1)'],
        [0.66, 'rgba(18, 194, 157, 0.1)'],
        [1, 'rgba(68, 147, 232, 0.1)']
      ]
    },
  },
];
*/

/*
const PLOT_LINES = [
  {
    color: '#E888AA',
    width: 2,
    dashStyle: 'ShortDash',
    value: 70,
    label: {
      style: {
        fontSize: 9,
        color: '#E888AA',
      },
      text: 'SYS min'
    }
  },
  {
    color: '#94D4F3',
    width: 2,
    value: 140,
    dashStyle: 'ShortDash',
    label: {
      style: {
        fontSize: 9,
        color: '#94D4F3',
      },
      text: 'DIA max'
    }
  }
];
*/

const BLOOD_PRESSURE = {
  chart: {
    type: 'columnrange',
    marginTop: 20,
    plotBackgroundColor: '#F1F1F1'
  },
  xAxis: [
    {
      type: 'category',
      crosshair: true,
      alternateGridColor: '#F7F7F7',
    }
  ],
  yAxis: [
    {
      min: 40,
      max: 240,
      tickPositioner(min, max) {
        return arrayRange(min, max, 10);
      },
      title: {
        text: 'mmHg',
        align: 'low',
        rotation: 0,
        reserveSpace: false,
        y: 22,
        style: {
          fontSize: 10
        }
      },
      //plotBands: PLOT_BANDS,
      //plotLines: PLOT_LINES
    },
    {
      min: 0,
      max: 200,
      tickPositioner(min, max) {
        return arrayRange(min, max, 10);
      },
      title: {
        text: 'BPM',
        align: 'low',
        rotation: 0,
        reserveSpace: false,
        y: 22,
        style: {
          fontSize: 9
        }
      },
      opposite: true
    }
  ],
  tooltip: {
    shared: true
  },
  plotOptions: {
    columnrange: {
      dataLabels: {
        enabled: true,
        padding: 3,
        style: {
          color: '#444',
          fontSize: '10px',
        }
      }
    },
    spline: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5
        }
      },
      marker: {
        symbol: 'circle'
      }
    },
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      point: {
        events: {}
      }
    },
    states: {
      hover: {
        enabled: false
      }
    }
  }
};

export { BLOOD_PRESSURE }
