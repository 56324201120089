import { Controller } from 'stimulus'
import { escapeHTML } from 'src/utils/sanitize'

export default class extends Controller {
  static values = {
    templates: Object
  }

  static targets = ['previewBox']

  connect() {
    this.buildArguments();
    this.renderPreview();

    // attach inputs listeners
    [...this.inputElements].forEach(el => {
      el.addEventListener(el.type == 'text' ? 'input' : 'change', (e) => this.updatePreview(e));
    });
  }

  disconnect() {
    // remove inputs listeners
    [...this.inputElements].forEach(el => {
      el.removeEventListener(el.type == 'text' ? 'input' : 'change', (e) => this.updatePreview(e));
    });
  }

  buildArguments() {
    let args = {};

    [...this.inputElements].forEach(el => {
      const { key, name, value } = this.compileArgument(el);

      args[key] = { name, value };
    });

    this.arguments = args;
  }

  renderPreview() {
    let conditionKey = this.arguments.condition ? this.arguments.condition.value : Object.keys(this.templatesValue)[0];
    let currentTemplate = this.templatesValue[conditionKey];

    Object.entries(this.arguments).forEach(([k, v]) => {
      currentTemplate = currentTemplate.replace(new RegExp('{' + k + '}', 'g'), escapeHTML(v.name) || `{${k}}`);
    });

    this.previewBoxTarget.innerHTML = currentTemplate;
  }

  compileArgument(el) {
    const { argument, aliases } = el.dataset;

    const variable = () => {
      if (el.options && aliases) {
        return JSON.parse(aliases)[el.options[el.selectedIndex].value];
      }

      if (el.options) {
        return el.options[el.selectedIndex].text;
      }

      return el.value;
    }

    return {
      key: argument,
      name: variable(),
      value: el.value
    }
  }

  updatePreview(e) {
    const { key, name, value } = this.compileArgument(e.target);

    this.arguments[key] = { name, value };

    this.renderPreview();
  }

  get inputElements() {
    return this.element.querySelectorAll('input, select');
  }
}
