import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Metrics/selectors'

import Actions from './Actions'
import Navigation from './Navigation'
import MetricsContainer from './MetricsContainer'
import ModalFactory from './ModalFactory'

import NoResults from 'src/react/components/Metrics/NoResults'

const Page = () => {
  const anyResults = useSelector(state => selectors.anyResults(state));

  return (
    <div className="card patient-metrics">
      <div className="card-header">
        <div className="card-title">Measurements</div>
        <div className="card-actions">
          <Actions />
        </div>
      </div>

      <div className="card-body">
        {
          anyResults ?
            <MetricsContainer /> :
            <NoResults />
        }
      </div>

      <div className="card-body">
        <Navigation />
      </div>

      <ModalFactory />
    </div>
  );
}

export default Page