import React, { useState, useRef, useContext } from 'react'

import ZoomMediaStreamContext from 'src/react/contexts/Meetings/media-stream-context'

import { Overlay, Tooltip, Dropdown } from 'react-bootstrap'

const DropdownToggleButton = ({ disabled }) => {
  const dropdownTarget = useRef(null);

  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <Overlay
        target={dropdownTarget.current}
        show={showOverlay}
        placement="top"
      >
        <Tooltip> Settings </Tooltip>
      </Overlay>

      <Dropdown.Toggle
        variant="dark"
        className="d-inline-flex align-items-center settings-button text-white"
        disabled={disabled}
        ref={dropdownTarget}
        onMouseEnter={
          () => setShowOverlay(true)
        }
        onMouseLeave={
          () => setShowOverlay(false)
        }
      >
        <span className="mdi mdi-dots-vertical"></span>
      </Dropdown.Toggle>
    </>
  )
};

const SettingsButton = (props) => {
  const {
    isStartedAudio,
    microphoneList,
    speakerList,
    activeMicrophone,
    activeSpeaker,
    onMicrophoneMenuClick,
    isStartedVideo,
    cameraList,
    activeCamera,
    isMirrored,
    isBlur,
    onSwitchCamera,
    onMirrorVideo,
    onBlurBackground
  } = props;

  const mediaStream = useContext(ZoomMediaStreamContext);

  const onMenuItemClick = (key) => {
    if (key === 'mirror') {
      onMirrorVideo?.();
    } else if (key === 'blur') {
      onBlurBackground?.();
    } else {
      onSwitchCamera(key);
    }
  };

  const activeCameras = cameraList.length > 0;
  const activeSpeakers = speakerList.length > 0;
  const activeMicrophones = microphoneList.length > 0;

  return (
    <div className="settings">
      <Dropdown>
        <DropdownToggleButton
          disabled={!isStartedAudio && !isStartedVideo}
        />

        <Dropdown.Menu>
          {
            isStartedVideo && activeCameras && (
              <>
                <Dropdown.Header> Select a Camera </Dropdown.Header>
                {
                  cameraList.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={`camera-${index}`}
                        onClick={
                          () => onMenuItemClick(item.deviceId)
                        }
                      >
                        {activeCamera === item.deviceId && <span className="mdi mdi-check" />} {item.label}
                      </Dropdown.Item>
                    );
                  })
                }
                <Dropdown.Divider />
              </>
            )
          }

          {
            isStartedAudio && activeMicrophones && (
              <>
                <Dropdown.Header> Select a microphone </Dropdown.Header>
                {
                  microphoneList.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={`microphone-${index}`}
                        onClick={
                          () => onMicrophoneMenuClick(`microphone|${item.deviceId}`)
                        }
                      >
                        {activeMicrophone === item.deviceId && <span className="mdi mdi-check" />} {item.label}
                      </Dropdown.Item>
                    );
                  })
                }
                <Dropdown.Divider />
              </>
            )
          }

          {
            isStartedAudio && activeSpeakers && (
              <>
                <Dropdown.Header> Select a speaker </Dropdown.Header>
                {
                  speakerList.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={`speaker-${index}`}
                        onClick={
                          () => onMicrophoneMenuClick(`speaker|${item.deviceId}`)
                        }
                      >
                        {activeSpeaker === item.deviceId && <span className="mdi mdi-check" />} {item.label}
                      </Dropdown.Item>
                    );
                  })
                }
                <Dropdown.Divider />
              </>
            )
          }

          {
            isStartedVideo && mediaStream?.isSupportVirtualBackground() && (
              <Dropdown.Item
                onClick={
                  () => onMenuItemClick('blur')
                }
              >
                {isBlur && <span className="mdi mdi-check" />} Blur My Background
              </Dropdown.Item>
            )
          }

          {
            /*
            isStartedVideo && (
              <Dropdown.Item
                onClick={
                  () => onMenuItemClick('mirror')
                }
              >
                {isMirrored && <span className="mdi mdi-check" />} Mirror My Video
              </Dropdown.Item>
            )
            */
          }

          {
            /*
            isStartedAudio && (
              <Dropdown.Item
                onClick={
                  () => onMicrophoneMenuClick('leave audio')
                }
              >
                Leave Audio
              </Dropdown.Item>
            )
            */
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SettingsButton
