import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import 'selectize'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  static values = {
    url: String,
    optionsObjects: Array
  };

  connect() {
    let select = $(this.element).selectize({
      valueField: 'id',
      searchField: 'name',
      options: this.optionsObjectsValue,
      render: {
        option: (data, escape) => this.formatElement(data, escape),
        item: (data, escape) => this.formatElement(data, escape),
      },
      load: (query, callback) => {
        $.ajax({
          type: 'GET',
          url: this.urlValue,
          dataType: 'json',
          data: {
            query: query
          },
          success: (response) => callback(response.data),
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        });
      },
      openOnFocus: false,
      hideSelected: true,
      closeAfterSelect: true,
      plugins: ['remove_button']
    });

    select.on('item_add', (_) => select[0].selectize.blur());
  }

  formatElement({ color, pattern, name }, escape) {
    return '<div class="tag-pattern ' + color + ' ' + pattern + '">' +
      '<span class="tag-name">' + escape(name) + '</span>' +
    '</div>';
  }
}
