import { handleActions } from 'redux-actions'
import produce from 'immer'

import {
  showModalAction,
  hideModalAction,
  clearModalAction,
  startMeetingRequestAction,
  startMeetingAction,
  receiveMeetingAction,
  acceptingMeetingAction,
  rejectingMeetingAction,
  acceptMeetingAction,
  endMeetingAction,
  leavingMeetingAction,
  changeCurrentSubscriberAction,
  setCameraStatusAction,
  setMicrophoneStatusAction,
  startHistoryRequestAction,
  getHistoryAction,
  getHistoryErrorAction,
  resetHistoryAction,
  startLatestMeetingsRequestAction,
  updateLatestMeetingAction,
  getLatestMeetingsAction,
  getLatestMeetingsErrorAction,
  endingMeetingAction,
  onlineOnMeetingAction,
  setAudioStreamReadyAction,
  setCameraBusyAction,
  setCameraClosingAction,
  setMicrophoneBusyAction,
  setMicrophoneClosingAction,
  setZoomStatusAction,
  setWebsocketsStatusAction
} from './actions'

import {
  ZoomStatuses,
  LineStatuses,
  MeetingTypes,
  MeetingStatuses,
  ShutdownReasons
} from 'src/react/constants/Meetings/index'

const initialState = {};

export default handleActions({
  [showModalAction]: (state, { payload: modalAction }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { modalAction, modalShow: true });
    });
  },
  [hideModalAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.modalShow = false;
    });
  },
  [clearModalAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.modalAction = null;
    });
  },
  [setWebsocketsStatusAction]: (state, { payload: websocketsStatus }) => {
    return produce(state, draftState => {
      draftState.websocketsStatus = websocketsStatus;
    });
  },
  [setZoomStatusAction]: (state, { payload: zoomStatus }) => {
    return produce(state, draftState => {
      draftState.zoomStatus = zoomStatus;
    });
  },
  [setCameraBusyAction]: (state, { payload: cameraBusy }) => {
    return produce(state, draftState => {
      draftState.cameraBusy = cameraBusy;
    });
  },
  [setCameraClosingAction]: (state, { payload: cameraClosing }) => {
    return produce(state, draftState => {
      draftState.cameraClosing = cameraClosing;
    });
  },
  [setMicrophoneBusyAction]: (state, { payload: microphoneBusy }) => {
    return produce(state, draftState => {
      draftState.microphoneBusy = microphoneBusy;
    });
  },
  [setMicrophoneClosingAction]: (state, { payload: microphoneClosing }) => {
    return produce(state, draftState => {
      draftState.microphoneClosing = microphoneClosing;
    });
  },
  [setAudioStreamReadyAction]: (state, { payload: audioStreamReady }) => {
    return produce(state, draftState => {
      draftState.audioStreamReady = audioStreamReady;
    });
  },
  [onlineOnMeetingAction]: (state, { payload: meetingSubscribersOnline }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { meetingSubscribersOnline });
    });
  },
  [startHistoryRequestAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.isLoadingHistory = true;
      draftState.isLoadingHistoryError = false;
    });
  },
  [getHistoryAction]: (state, { payload: { isMoreHistory, meetings } }) => {
    return produce(state, draftState => {
      draftState.history.push(...meetings);

      Object.assign(draftState, {
        isLoadingHistory: false,
        isMoreHistory
      });
    });
  },
  [getHistoryErrorAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.isLoadingHistory = false;
      draftState.isLoadingHistoryError = true;
    });
  },
  [startLatestMeetingsRequestAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.isLoadingLatestMeetings = true;
    });
  },
  [getLatestMeetingsAction]: (state, { payload: { meetings } }) => {
    return produce(state, draftState => {
      draftState.latestMeetings = meetings;
      draftState.isLoadingLatestMeetings = false;
    });
  },
  [getLatestMeetingsErrorAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.isLoadingLatestMeetings = false;
      draftState.isLoadingLatestMeetingsError = true;
    });
  },
  [updateLatestMeetingAction]: (state, { payload: meeting }) => {
    const meetingIndex =
      state.latestMeetings.findIndex(el => el.id == meeting.id);

    return produce(state, draftState => {
      draftState.latestMeetings[meetingIndex] = meeting;
    });
  },
  [resetHistoryAction]: (state, { payload }) => {
    return produce(state, draftState => {
      Object.assign(draftState, {
        history: [],
        isLoadingHistory: false,
        isLoadingHistoryError: false,
        isMoreHistory: true
      });
    });
  },
  [setCameraStatusAction]: (state, { payload: cameraStatus }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { cameraStatus });
    });
  },
  [setMicrophoneStatusAction]: (state, { payload: microphoneStatus }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { microphoneStatus });
    });
  },
  [changeCurrentSubscriberAction]: (state, { payload: currentSubscriber }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { currentSubscriber });
    });
  },
  [startMeetingRequestAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.lineStatus = LineStatuses.Pending;
    });
  },
  [startMeetingAction]: (state, { payload: meeting }) => {
    return produce(state, draftState => {
      Object.assign(draftState, {
        inMeeting: true,
        lineStatus: LineStatuses.Busy,
        meetingType: MeetingTypes.Outcoming,
        meetingStatus: MeetingStatuses.Pending
      });

      draftState.currentMeeting = meeting;
    });
  },
  [receiveMeetingAction]: (state, { payload: meeting }) => {
    return produce(state, draftState => {
      Object.assign(draftState, {
        lineStatus: LineStatuses.Busy,
        meetingType: MeetingTypes.Incoming,
        meetingStatus: MeetingStatuses.Pending
      });

      draftState.currentMeeting = meeting;
    });
  },
  [acceptingMeetingAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.meetingStatus = MeetingStatuses.Accepting;
    });
  },
  [rejectingMeetingAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.meetingStatus = MeetingStatuses.Rejecting;
    });
  },
  [leavingMeetingAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.meetingStatus = MeetingStatuses.Leaving;
    });
  },
  [endingMeetingAction]: (state, { payload: { shutdownReason, meetingAttributes = {} }}) => {
    return produce(state, draftState => {
      Object.assign(draftState, {
        shutdownReason,
        meetingStatus: MeetingStatuses.Ending
      });

      Object.assign(draftState.currentMeeting, meetingAttributes);
    });
  },
  [acceptMeetingAction]: (state, { payload: started_at }) => {
    return produce(state, draftState => {
      Object.assign(draftState, {
        inMeeting: true,
        meetingStatus: MeetingStatuses.Active
      });

      Object.assign(draftState.currentMeeting, { started_at });
    });
  },
  [endMeetingAction]: (state, { payload }) => {
    return produce(state, draftState => {
      if (
        state.shutdownReason == ShutdownReasons.Ended ||
        state.shutdownReason == ShutdownReasons.Rejected ||
        state.shutdownReason == ShutdownReasons.Timeouted
      ) {
        draftState.latestMeetings =
          [state.currentMeeting, ...state.latestMeetings].slice(0, 10);
      }

      Object.assign(draftState, {
        lineStatus: LineStatuses.Free,
        zoomStatus: ZoomStatuses.Free,
        cameraBusy: false,
        cameraStatus: null,
        cameraClosing: false,
        microphoneBusy: false,
        microphoneStatus: null,
        microphoneClosing: false,
        audioStreamReady: false,
        inMeeting: false,
        meetingType: null,
        meetingStatus: null,
        meetingSubscribersOnline: 0,
        shutdownReason: null,
        currentMeeting: {}
      });
    });
  }
}, initialState);
