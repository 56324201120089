import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import { membershipsFormBindings } from 'src/plugins/memberships'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    $('body').
      findOrAppend('#edit-modal', '<div id="edit-modal" />').
      html(data.content).
      find('.modal').
      modal('show').
      membershipsFormBindings({ type: 'patient_groups' });
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data });
  }
}
