import Highcharts from 'highcharts'
import moment from 'moment'

import { DEFAULT_PARAMS } from 'src/charts/charge_items/constants'

class Chart {
  constructor(selector) {
    this.selector = selector;
    this.init();
  }

  init() {
    if (this.isPayloadEmpty) {
      this.selector.style.display = 'none'
    } else {
      this.selector.style.display = 'block';
      const chart = Highcharts.chart(this.selector, this.options);
      chart.reflow();
    }
  }

  get options() {
    return Object.assign({}, DEFAULT_PARAMS, this.series, this.xAxis);
  }

  get series() {
    return {
      series: [{
        name: 'CPT 99453',
        type: 'column',
        data: this.dataByCode('initiation')
      },
      {
        name: 'CPT 99454',
        type: 'column',
        data: this.dataByCode('monitoring')
      },
      {
        name: 'CPT 99457',
        type: 'column',
        data: this.dataByCode('capture')
      },
      {
        name: 'CPT 99458',
        type: 'column',
        data: this.dataByCode('extra_capture')
      },
      {
        name: 'G 0511',
        type: 'column',
        data: this.dataByCode('monitoring_with_capture')
      }]
    };
  }

  dataByCode(code) {
    return this.payload.map(el => el[code]);
  }

  get payload() {
    const { payload } = this.selector.dataset;
    return JSON.parse(payload);
  }

  get isPayloadEmpty() {
    return this.payload.length === 0;
  }

  get dates() {
    return this.payload.map(el => moment(el.start_date).format('MMM Y'));
  }

  get xAxis() {
    return {
      xAxis: [{
        categories: this.dates,
        crosshair: true,
        grouping: this.payload[0].grouping
      }]
    };
  }
}

export default Chart
