import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  acceptingMeeting,
  rejectingMeeting
} from 'src/react/store/Meetings/actions'

import MeetingChannelContext from 'src/react/contexts/Meetings/meeting-channel-context'

import { MeetingTypes, MeetingStatuses } from 'src/react/constants/Meetings/index'

const ConnectionScreen = () => {
  const {
    id,
    initiator: { name: initiator_name },
    subscriber: { name: subscriber_name },
  } = useSelector(state => selectors.currentMeeting(state));

  const meetingType = useSelector(state => selectors.meetingType(state));
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));

  const { channel } = useContext(MeetingChannelContext);

  const dispatch = useDispatch();

  const acceptingMeetingClick = () => dispatch(acceptingMeeting({ id, channel }));
  const rejectingMeetingClick = () => dispatch(rejectingMeeting({ id, channel }));

  const isButtonsInactive = [MeetingStatuses.Accepting, MeetingStatuses.Rejecting].includes(meetingStatus);

  return (
    <div className="connection-screen">
      <div className="column title-box">
        <div className="connect loading-box">
          {meetingType == MeetingTypes.Incoming && <span> Call from {initiator_name} ...</span>}
          {meetingType == MeetingTypes.Outcoming && <span> Calling to {subscriber_name} ...</span>}
        </div>
      </div>
      <div className="column buttons-box">
        { 
          meetingType == MeetingTypes.Incoming && (
            <button
              className="btn btn-lg btn-success btn-circle-lg mr-3"
              onClick={acceptingMeetingClick}
              disabled={isButtonsInactive}
            >
              <span className="mdi mdi-phone"></span>
            </button>
          )
        }
        <button
          className="btn btn-lg btn-danger btn-circle-lg"
          onClick={rejectingMeetingClick}
          disabled={isButtonsInactive}
        >
          <span className="mdi mdi-phone-hangup"></span>
        </button>
      </div>
    </div>
  );
}

export default ConnectionScreen
