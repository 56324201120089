import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'autoPin', 'passwordBox', 'passwordInput', 'deliveryControlBox',
    'deliveryControl', 'deliveryTypesBox'
  ]

  connect() {
    this.render();
  }

  render() {
    this.switchPinMode();
    this.switchPasswordBoxVisibility();
    this.switchDeliveryControlBox();
    this.switchDeliveryTypesBox();
  }

  switchPinMode() {
    if (this.autoPinActive) {
      // reset control checkbox after return to auto PIN mode
      this.deliveryControlTarget.checked = false;

      // reset password input after return to auto PIN mode
      this.passwordInputTarget.value = '';
    }
  } 

  switchPasswordBoxVisibility() {
    this.passwordBoxTarget.style.display =
      this.#visibleBy(this.manuallyPinActive);
  }

  switchDeliveryControlBox() {
    this.deliveryControlBoxTarget.style.display =
      this.#visibleBy(this.manuallyPinActive);
  }

  switchDeliveryTypesBox() {
    this.deliveryTypesBoxTarget.style.display =
      this.#visibleBy(this.autoPinActive || this.deliveryControlActive);
  }

  get autoPinActive() {
    return this.autoPinTarget.value == 'true';
  }

  get manuallyPinActive() {
    return this.autoPinTarget.value == 'false';
  }

  get deliveryControlActive() {
    return this.deliveryControlTarget.checked;
  }

  get autoPinTarget() {
    return this.autoPinTargets.find(target => target.checked);
  }

  #visibleBy(statement) {
    return statement ? 'block' : 'none';
  }
}
