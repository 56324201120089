import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { watchdogInterrupt } from 'src/react/store/Meetings/actions'

import {
  MeetingStatuses,
  WATCHDOG_TIMINGS
} from 'src/react/constants/Meetings/index'

export function useMeetingWatchdog() {
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));
  const meetingSubscribersOnline = useSelector(state => selectors.meetingSubscribersOnline(state));

  const dispatch = useDispatch();

  const isDialogAvailable = () => {
    return meetingStatus == MeetingStatuses.Active && meetingSubscribersOnline > 1;
  }

  const isWatchdogStatus = () => {
    return meetingStatus in WATCHDOG_TIMINGS;
  }

  const handleInterrupts = () => {
    if (isDialogAvailable()) return;

    dispatch(watchdogInterrupt(meetingStatus));
  }

  useEffect(() => {
    if (!isWatchdogStatus()) return;

    const timeoutID =
      setTimeout(() => handleInterrupts(), WATCHDOG_TIMINGS[meetingStatus]);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [meetingStatus, meetingSubscribersOnline]);
}
