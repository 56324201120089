import React from 'react'

import classnames from 'classnames'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const MicrophoneButton = ({
  isMicrophoneBusy,
  isStartedAudio,
  isMuted,
  onMicrophoneClick
}) => {
  const tooltipText = isStartedAudio ? (isMuted ? 'Unmute' : 'Mute') : 'Start audio';

  return (
    <div className="microphone">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="microphone-tooltip">
            {tooltipText}
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <Button
            disabled={isMicrophoneBusy}
            variant={(!isStartedAudio || isMuted) ? 'danger' : 'dark'}
            className="d-inline-flex align-items-center audio-button text-white"
            onClick={onMicrophoneClick}
            ref={ref}
            {...triggerHandler}
          >
            <span
              className={
                classnames('mdi', (!isStartedAudio || isMuted) ? 'mdi-microphone-off' : 'mdi-microphone')
              }
            />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );
};

export default MicrophoneButton
