import moment from 'moment'

import {
  DATE_FORMAT,
  DEFAULT_OPTIONS,
  EJECTION_FRACTION
} from 'src/react/constants/Metrics/index'

import Base from './Base'

class EjectionFraction extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, EJECTION_FRACTION);
  }

  onActivateDaily(chart, e, response) {
    chart.yAxis[0].update({
      max: 100,
      labels: {
        formatter: function() {
          return this.value > 100 ? null : this.value;
        }
      }
    }, false);
  }

  onDeactivateDaily(chart) {
    chart.yAxis[0].update({
      max: 100,
      labels: {
        formatter: function() {
          return this.value;
        }
      },
      plotLines: []
    }, false);
  }

  tooltipFormatter(context, tooltip) {
    let s = this.tooltipHeader(this.tooltipLabel(context.points[0].point).toUpperCase());

    context.points.forEach((point) => {
      s += this.tooltipBody(point, this.tooltipResult(point.point));
    });

    return s;
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format(DATE_FORMAT);

    return `${moment(point.start_date).format(DATE_FORMAT)} - ${moment(point.end_date).format(DATE_FORMAT)}`;
  }

  tooltipResult(point) {
    return (this.currentDataspace.mode == 'range') ? `${point.low} - ${point.high}` : point.y;
  }

  requestOptions() {
    return {
      widget: 'ejection_fraction'
    };
  }
}

export default EjectionFraction
