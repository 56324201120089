import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'email']

  connect() {
    this.toggleSubmitButton()
    this.emailTarget.addEventListener('input', () => this.toggleSubmitButton());
  }

  disconnect() {
    this.emailTarget.removeEventListener('input', () => this.toggleSubmitButton());
  }

  toggleSubmitButton() {
    this.submitTarget.disabled = this.emailTarget.value.length === 0;
  }
}
