import { Controller } from 'stimulus'
import $ from 'jquery'
import idleTimeout from 'idle-timeout'
import Swal from 'sweetalert2/src/sweetalert2.js'

import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { formatDuration } from 'src/utils/formatDuration'

const IDLE_TIMEOUT_IN_MS = 30 * 60 * 1000;
const IDLE_CONFIRMATION_TIMEOUT_IN_MS = 1 * 60 * 1000; 

export default class extends Controller {
  connect() {
    this.idleInstance = idleTimeout(
      () => this.signOut(),
      {
        element: document,
        timeout: IDLE_TIMEOUT_IN_MS
      }
    );

    this.askConfirmationTimeout();
  }

  askConfirmationTimeout() {
    this.askConfirmationTimer = setInterval(() => {
      if (!this.confirmationThreshold) return;

      // remove ask confirmation timeout
      window.clearTimeout(this.askConfirmationTimer);

      // stop idle timeout
      this.stopIdleTimeout();

      // waiting for confirmation
      this.waitConfirmationTimeout();
    }, 1000);
  }

  stopIdleTimeout() {
    this.idleInstance.pause();
  }

  resetIdleTimeout() {
    this.idleInstance.reset();
  }

  waitConfirmationTimeout() {
    return Swal.fire({
      title: 'Session expiration warning',
      html: this.confirmationBody(IDLE_CONFIRMATION_TIMEOUT_IN_MS),
      timer: IDLE_CONFIRMATION_TIMEOUT_IN_MS,
      timerProgressBar: true,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Stay logged in',
      cancelButtonText: 'Logout now',
      didOpen: () => {
        this.waitConfirmationTimer = setInterval(() => {
          Swal.getHtmlContainer().innerHTML =
            this.confirmationBody(Swal.getTimerLeft());
        }, 100);
      },
      willClose: () => {
        window.clearInterval(this.waitConfirmationTimer);
      }
    }).then(({ dismiss }) => {
      [Swal.DismissReason.cancel, Swal.DismissReason.timer].includes(dismiss) ?
        this.exit() :
        this.continue();
    });
  }

  continue() {
    // reset idle timer and restart it
    this.resetIdleTimeout();

    // restart confirmation ask
    this.askConfirmationTimeout();
  }

  exit() {
    this.signOut();
  }

  confirmationBody(ms) {
    const durationInSeconds = formatDuration(Math.trunc(ms / 1000));

    return `
        <span>
          Because you have been inactive, your session is about to expire.
        </span>
        <div class="mt-2">
          Time remaining: <b>${durationInSeconds}</b>
        </div>`;
  }

  get remainingTime() {
    return IDLE_TIMEOUT_IN_MS - (this.now - this.idleInstance.startTime);
  }

  get confirmationThreshold() {
    return IDLE_CONFIRMATION_TIMEOUT_IN_MS >= this.remainingTime;
  }

  signOut() {
    $.ajax({
      method: 'DELETE',
      url: '/users/sign_out',
      dataType: 'json',
      success: (response) => {
        location.reload();
      },
      error: (xhr) => {
        new ErrorsInterceptor({ xhr });
      }
    })
  }

  get now() {
    return new Date().getTime();
  }
}
