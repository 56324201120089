import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import {
  changeCurrentWidgetAction
} from 'src/react/store/Metrics/actions'

import * as selectors from 'src/react/store/Metrics/selectors'

import { WIDGETS } from 'src/react/constants/Metrics/index'

import { Tab, Nav } from 'react-bootstrap'

import WidgetFactory from './WidgetFactory'

const MetricsContainer = () => {
  const from = useSelector(state => selectors.from(state));
  const to = useSelector(state => selectors.to(state));
  const settings = useSelector(state => selectors.settings(state));
  const requestStatus = useSelector(state => selectors.requestStatus(state));
  const activeWidgets = useSelector(state => selectors.activeWidgets(state));
  const currentWidget = useSelector(state => selectors.currentWidget(state));
  const isControlsInactive = useSelector(state => selectors.isControlsInactive(state));

  const dispatch = useDispatch();

  const changeCurrentWidget = (key) => dispatch((changeCurrentWidgetAction(key)));

  const isSingleWidget = activeWidgets.length == 1;

  return (
    <Tab.Container
      id="widget"
      activeKey={currentWidget}
      onSelect={changeCurrentWidget}
    >
      <Nav
        className="btn-group btn-group-toggle"
        variant="buttons"
      >
        {
          activeWidgets.map((type) => {
            const { icon, name } = WIDGETS[type];

            return (
              <Nav.Link
                key={type}
                eventKey={type}
                as="button"
                className={
                  classnames('btn', {
                    disabled: isControlsInactive,
                    single: isSingleWidget
                  })
                }
              >
                <div className={classnames('icon', icon)} />
                {name}
              </Nav.Link>
            );
          })
        }
      </Nav>
      <Tab.Content>
        {
          activeWidgets.map((type) => {
            return (
              <Tab.Pane
                key={type}
                eventKey={type}
              >
                <WidgetFactory
                  requestStatus={requestStatus}
                  currentWidget={currentWidget}
                  settings={settings}
                  type={type}
                  from={from}
                  to={to}
                />
              </Tab.Pane>
            );
          })
        }
      </Tab.Content>
    </Tab.Container>
  );
}

export default MetricsContainer