import { Controller } from 'stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    placement: { type: String, default: 'bottom' },
    maxWidth: { type: String, default: 'none' },
    interactive: { type: Boolean, default: true },
    allowHtml: { type: Boolean, default: true },
    content: String
  };

  connect() {
    this.initializeWidget();
  }

  initializeWidget() {
    tippy(this.element, {
      theme: 'light-border',
      placement: this.placementValue,
      maxWidth: this.maxWidthValue,
      content: this.contentValue,
      allowHTML: this.allowHtmlValue,
      interactive: this.interactiveValue,
      appendTo: document.body
    });
  }
}
