import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  static targets = ['fileInput', 'submitButton'];

  connect() {
    this.clearFiles();

    this.fileInputTarget.addEventListener('change', () => this.toggleSubmitButton());
  }

  toggleSubmitButton() {
    this.submitButtonTarget.disabled = (this.fileInputTarget.files.length == 0);
  }

  clearFiles() {
    this.fileInputTarget.value = '';
    this.toggleSubmitButton();
  }

  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.form) {
      $('body').
        findOrAppend('#importer_form', '<div id="importer_form" />').
        html(data.form);
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data }, () => {
      if (data.form) {
        $('#importer_form').html(data.form);
      }
    });
  }
}
