import arrayRange from 'src/utils/arrays/arrayRange'

const WEIGHT = {
  chart: {
    //type: 'spline',
    marginTop: 20,
    marginLeft: 45,
    alignTicks: false,
    plotBackgroundColor: '#F1F1F1'
  },
  xAxis: [
    {
      type: 'category',
      crosshair: true,
      alternateGridColor: '#F7F7F7'
    }
  ],
  yAxis: [
    {
      min: 0,
      tickPositioner(min, max) {
        // ceil max value to nearest 10 multiplier
        return arrayRange(min, Math.ceil(max / 10.0) * 10, 10);
      },
      title: {
        text: null,
        align: 'low',
        rotation: 0,
        offset: 22,
        y: 22,
        style: {
          fontSize: 10,
        }  
      },
    }
  ],
  plotOptions: {
    columnrange: {
      dataLabels: {
        enabled: true,
        padding: 3,
        style: {
          color: '#444',
          fontSize: '10px'
        }
      }
    },
    spline: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5
        }
      },
      marker: {
        symbol: 'circle'
      },
    },
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      events: {
        legendItemClick: function() {
          return false;
        }
      },
      point: {
        events: {}
      }
    },
    states: {
      hover: {
        enabled: false
      }
    }
  }
};

export { WEIGHT }
