import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import ZoomContext from 'src/react/contexts/Meetings/zoom-context'
import ZoomMediaStreamContext from 'src/react/contexts/Meetings/media-stream-context'

import { AudioChangeAction, VideoCapturingState } from '@zoom/videosdk'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  CameraStatuses,
  MicrophoneStatuses
} from 'src/react/constants/Meetings/index'

import CameraButton from 'src/react/components/Meetings/CameraButton'
import MicrophoneButton from 'src/react/components/Meetings/MicrophoneButton'
import SettingsButton from 'src/react/components/Meetings/SettingsButton'
import LeaveButton from 'src/react/components/Meetings/LeaveButton'

const isAudioEnable = typeof AudioWorklet === 'function';

const VideoControls = () => {
  const zmClient = useContext(ZoomContext);
  const mediaStream = useContext(ZoomMediaStreamContext);

  const cameraStatus = useSelector(state => selectors.cameraStatus(state));
  const microphoneStatus = useSelector(state => selectors.microphoneStatus(state));

  const [isStartedAudio, setIsStartedAudio] = useState(null);
  const [isStartedVideo, setIsStartedVideo] = useState(null);

  const [isCameraBusy, setIsCameraBusy] = useState(false);
  const [isMicrophoneBusy, setIsMicrophoneBusy] = useState(false);

  const [audio, setAudio] = useState('');
  const [isMirrored, setIsMirrored] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [activeCamera, setActiveCamera] = useState('');
  const [micList, setMicList] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);

  const onCameraClick = async () => {
    setIsCameraBusy(true);

    if (isStartedVideo) {
      try {
        await mediaStream?.stopVideo();

        setIsStartedVideo(false);
      } catch (e) {
        console.log('onCameraClick -> stopVideo', e);
      }
    } else {
      try {
        await mediaStream?.startVideo({
          hd: true,
          ptz: mediaStream?.isBrowserSupportPTZ(),
          virtualBackground: { imageUrl: isBlur ? 'blur' : undefined }
        });

        setIsStartedVideo(true);
      } catch (e) {
        console.log('onCameraClick -> startVideo', e);
      }
    }

    setIsCameraBusy(false);
  };

  const onMicrophoneClick = async () => {
    setIsMicrophoneBusy(true);

    if (isStartedAudio) {
      if (isMuted) {
        try {
          await mediaStream?.unmuteAudio();

          setIsMuted(false);
        } catch (e) {
          console.log('onMicrophoneClick -> unmuteAudio', e);
        }
      } else {
        try {
          await mediaStream?.muteAudio();

          setIsMuted(true);
        } catch (e) {
          console.log('onMicrophoneClick -> muteAudio', e);
        }
      }
    } else {
      try {
        await mediaStream?.startAudio();
      } catch (e) {
        console.log('onMicrophoneClick -> startAudio', e);
      }

      setIsStartedAudio(true);
    }

    setIsMicrophoneBusy(false);
  };
  
  const onMicrophoneMenuClick = async (key) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');

      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);

          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);

          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } 
      else if (type === 'leave audio') {
        if (audio === 'computer') {
          try {
            await mediaStream.stopAudio();
          } catch(e) {
            console.log('onMicrophoneMenuClick -> stopAudio', e);
          }
        }

        setIsStartedAudio(false);
      }
    }
  };

  const onSwitchCamera = async (key) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);

        setActiveCamera(mediaStream.getActiveCamera());
      }
    }
  };

  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);

    setIsMirrored(!isMirrored);
  };

  const onBlurBackground = async () => {
    const vbStatus = mediaStream?.getVirtualbackgroundStatus();
    
    if (vbStatus?.isVBPreloadReady) {
      if (!isBlur) {
        await mediaStream?.updateVirtualBackgroundImage('blur');
      } else {
        await mediaStream?.updateVirtualBackgroundImage(undefined);
      }

      setIsBlur(!isBlur);
    }
  };

  const onHostAudioMuted = useCallback(({ action, source, type }) => {
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
    }
  }, []);

  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      setCameraList(mediaStream.getCameraList());
      
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onVideoCaptureChange = useCallback(({ state }) => {
    if (state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);

  const onShareAudioChange = useCallback(({ state }) => {
    if (state === 'on') {
      setIsComputerAudioDisabled(true);
    } else if (state === 'off') {
      setIsComputerAudioDisabled(false);
    }
  }, []);

  const onHostAskToUnmute = useCallback(({ reason }) => {
    console.log(`Host ask to unmute the audio.`, reason);
  }, []);

  const onCaptionMessage = useCallback(({ text, done }) => {
    console.log(text, done);
  }, []);

  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange);
    zmClient.on('share-audio-change', onShareAudioChange);
    zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('caption-message', onCaptionMessage);

    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange);
      zmClient.off('share-audio-change', onShareAudioChange);
      zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('caption-message', onCaptionMessage);
    };
  }, [
    zmClient,
    onHostAudioMuted,
    onDeviceChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCaptionMessage
  ]);
  
  return (
    <div className="video-operations">
      {
        microphoneStatus == MicrophoneStatuses.Ready && isAudioEnable && (
          <MicrophoneButton
            isMicrophoneBusy={isMicrophoneBusy}
            isStartedAudio={isStartedAudio}
            isMuted={isMuted}
            onMicrophoneClick={onMicrophoneClick}
          />
        )
      }
  
      {
        cameraStatus == CameraStatuses.Ready && (
          <CameraButton
            isCameraBusy={isCameraBusy}
            isStartedVideo={isStartedVideo}
            onCameraClick={onCameraClick}
          />
        )
      }

      {
        (
          microphoneStatus == MicrophoneStatuses.Ready ||
          cameraStatus == CameraStatuses.Ready
        ) && (
          <SettingsButton
            isStartedAudio={isStartedAudio}
            isMuted={isMuted}
            audio={audio}
            onMicrophoneMenuClick={onMicrophoneMenuClick}
            microphoneList={micList}
            speakerList={speakerList}
            activeMicrophone={activeMicrophone}
            activeSpeaker={activeSpeaker}
            /* disabled={isComputerAudioDisabled} */
            isStartedVideo={isStartedVideo}
            onSwitchCamera={onSwitchCamera}
            onMirrorVideo={onMirrorVideo}
            onBlurBackground={onBlurBackground}
            cameraList={cameraList}
            activeCamera={activeCamera}
            isMirrored={isMirrored}
            isBlur={isBlur}
          />
        )
      }

      <LeaveButton />
    </div>
  );
};
export default VideoControls
