import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.success) {
      $('body').find('#edit-modal').find('.modal').modal('hide');
      location.reload();
    } else {
      $('#edit-modal form').replaceWith(data.form);
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data });
  }
}
