import $ from 'jquery'
import 'selectize'
import 'src/plugins/selectize/noResults'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

class Participants {
  constructor(element, namespace) {
    this.element = element;
    this.namespace = namespace;

    this.init();
  }

  init() {
    this.initializeSelectize();
    this.switchSelectParticipantsBox();
    this.switchAllParticipantsBox();
  }

  update(namespace) {
    if (this.namespace == namespace) return;

    this.namespace = namespace;

    this.resetParticipants();
    this.switchSelectParticipantsBox();
    this.switchAllParticipantsBox();
  }

  initializeSelectize() {
    const self = this;

    this.selectize = $(this.participantsInput).selectize({
      plugins: ['no_results'],
      valueField: 'id',
      searchField: 'name',
      loadThrottle: 300,
      dataAttr: 'data-hydrate',
      render: {
        item: function(item, escape) {
          return self.formatResults(item, escape);
        },
        option: function(item, escape) {
          return self.formatResults(item, escape);
        }
      },
      onChange: function() {
        if (self.individualNamespaceSelected()) {
          this.clearOptions();
          this.clearCache();
        }
      },
      onDelete: function() {
        if (self.individualNamespaceSelected()) {
          self.resetParticipants();
        }
      },
      onType: function(query) {
        this.clearOptions();
        this.refreshOptions();
      },
      load: function(query, callback) {
        if (!query && self.individualNamespaceSelected()) return callback();

        this.switchNoResults('hide');

        //if (!query.length) return callback();

        $.ajax({
          type: 'GET',
          url: self.url,
          dataType: 'json',
          data: {
            query: query,
            type: self.namespace
          },
          success: (res) => {
            self.selectizeInstance.switchNoResults(
              (res.suggestions.length == 0 ? 'show' : 'hide')
            );

            callback(res.suggestions);
          },
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        });
      }
    });
  }

  resetParticipants() {
    this.selectizeInstance.clear();
    this.selectizeInstance.clearOptions();
    this.selectizeInstance.clearCache();
  }

  switchSelectParticipantsBox() {
    this.selectParticipantsContext.classList.toggle('d-none', this.namespace == 'medical_group');
  }

  switchAllParticipantsBox() {
    this.allParticipantsContext.classList.toggle('d-none', this.namespace != 'medical_group');
  }

  individualNamespaceSelected() {
    return this.namespace == 'patient';
  }

  formatResults(item, escape) {
    switch (this.namespace) {
      case 'patient_group':
        return this.formatPatientGroups(item, escape);
      case 'patient':
        return this.formatUsers(item, escape);
      default:
        return null;
    }
  }

  formatPatientGroups(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta patient-groups">
          <div class="name">
            ${escape(item.name)}
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  formatUsers(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta users">
          <div class="content">
            <div class="name">
              ${escape(item.name)}
            </div>
            <div class="info-label">
              ${patientInfoLabel(item)}
            </div>
          </div>
          <div class="archived-badge">
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  get url() {
    return this.participantsInput.dataset.url;
  }

  get participantsInput() {
    return this.selectParticipantsContext.querySelector('select');
  }

  get selectParticipantsContext() {
    return this.element.querySelector('#select-participants-context');
  }

  get allParticipantsContext() {
    return this.element.querySelector('#all-participants-context');
  }

  get selectizeInstance() {
    return this.selectize[0].selectize;
  }
}

export default Participants
