import { createAction } from 'redux-actions'

import { toast } from 'react-toastify'

import axios from 'src/react/utils/axios'
import { abortSignal } from 'src/utils/abortSignal'
import { formatPath } from 'src/utils/formatters'

import {
  LineStatuses,
  MeetingStatuses,
  MeetingTypes,
  MeetingCommands,
  ShutdownReasons,
  WebsocketsStatuses,
  SHUTDOWN_REASONS_TOASTS
} from 'src/react/constants/Meetings/index'

const showModalAction = createAction('MEETINGS/SHOW_MODAL');
const hideModalAction = createAction('MEETINGS/HIDE_MODAL');
const clearModalAction = createAction('MEETINGS/CLEAR_MODAL');

const changeCurrentSubscriberAction = createAction('MEETINGS/CHANGE_CURRENT_SUBSCRIBER');
const startHistoryRequestAction = createAction('MEETINGS/START_HISTORY_REQUEST');
const getHistoryAction = createAction('MEETINGS/GET_HISTORY');
const getHistoryErrorAction = createAction('MEETINGS/GET_HISTORY_ERROR');
const resetHistoryAction = createAction('MEETINGS/RESET_HISTORY');

const startLatestMeetingsRequestAction = createAction('MEETINGS/START_LATEST_MEETINGS_REQUEST');
const getLatestMeetingsAction = createAction('MEETINGS/GET_LATEST_MEETINGS');
const getLatestMeetingsErrorAction = createAction('MEETINGS/GET_LATEST_MEETING_ERROR');
const updateLatestMeetingAction = createAction('MEETINGS/UPDATE_LATEST_MEETING');

const setWebsocketsStatusAction = createAction('MEETINGS/SET_WEBSOCKETS_STATUS');
const onlineOnMeetingAction = createAction('MEETINGS/ONLINE_ON_MEETING');

const startMeetingRequestAction = createAction('MEETINGS/START_MEETING_REQUEST');
const startMeetingAction = createAction('MEETINGS/START_MEETING');
const receiveMeetingAction = createAction('MEETINGS/RECEIVE_MEETING');
const acceptingMeetingAction = createAction('MEETINGS/ACCEPTING_MEETING');
const rejectingMeetingAction = createAction('MEETINGS/REJECTING_MEETING');
const acceptMeetingAction = createAction('MEETINGS/ACCEPT_MEETING');
const leavingMeetingAction = createAction('MEETINGS/LEAVING_MEETING');
const endingMeetingAction = createAction('MEETINGS/ENDING_MEETING');
const endMeetingAction = createAction('MEETINGS/END_MEETING');

const setAudioStreamReadyAction = createAction('MEETINGS/SET_AUDIO_STREAM_READY');
const setCameraBusyAction = createAction('MEETINGS/SET_CAMERA_BUSY');
const setCameraClosingAction = createAction('MEETINGS/SET_CAMERA_CLOSING');
const setMicrophoneBusyAction = createAction('MEETINGS/SET_MICROPHONE_BUSY');
const setMicrophoneClosingAction = createAction('MEETINGS/SET_MICROPHONE_CLOSING');
const setZoomStatusAction = createAction('MEETINGS/SET_ZOOM_STATUS');

const setCameraStatusAction = createAction('MEETINGS/SET_CAMERA_STATUS');
const setMicrophoneStatusAction = createAction('MEETINGS/SET_MICROPHONE_STATUS');

const connectWebsockets = () => {
  return dispatch => {
    dispatch(setWebsocketsStatusAction(WebsocketsStatuses.Active));
  }
};

const isLineBusy = (getState) => {
  const { meetings: { lineStatus } } = getState();

  return lineStatus == LineStatuses.Busy;
};

const isDisconnectMeetingTxAvailable = (getState) => {
  const { meetings: { meetingStatus } } = getState();

  return [
    MeetingStatuses.Pending,
    MeetingStatuses.Accepting,
    MeetingStatuses.Rejecting,
    MeetingStatuses.Leaving,
    MeetingStatuses.Active,
  ].includes(meetingStatus);
};

const disconnectWebsockets = () => {
  return (dispatch, getState) => {
    dispatch(setWebsocketsStatusAction(WebsocketsStatuses.Inactive));

    if (!isLineBusy(getState)) return;
    if (!isDisconnectMeetingTxAvailable(getState)) return;

    dispatch(endingMeetingAction({ shutdownReason: ShutdownReasons.WebsocketsDisconnect }));
  }
};

const watchdogInterrupt = (meetingStatus) => {
  console.log('Watchdog interrupt. Meeting status: ', meetingStatus);

  return dispatch => {
    dispatch(endingMeetingAction({ shutdownReason: ShutdownReasons.WatchdogInterrupt }));
  };
};

/*
const staleConnectionInterrupt = () => {
  return dispatch => {
    dispatch(endingMeetingAction({ shutdownReason: ShutdownReasons.StaleConnectionInterrupt }));
  };
};
*/

const loadMeetings = (page) => {
  return axios.get(window.meetings.load_history_path, {
    params: { page },
    timeout: 10_000,
    signal: abortSignal(10_000)
  });
}

const getLatestMeetings = () => (
  async dispatch => {
    dispatch(startLatestMeetingsRequestAction());

    try {
      const { data: { meetings }} = await loadMeetings(1);

      dispatch(getLatestMeetingsAction({ meetings }));
    } catch (e) {
      console.log(e);

      dispatch(getLatestMeetingsErrorAction());
    }
  }
);

const getHistory = (page) => (
  async dispatch => {
    dispatch(startHistoryRequestAction());

    try {
      const {
        data: {
          meetings,
          more_meetings: isMoreHistory
        }
      } = await loadMeetings(page);

      dispatch(getHistoryAction({ meetings, isMoreHistory }));
    } catch (e) {
      console.log(e);

      dispatch(getHistoryErrorAction());
    }
  }
);

const getMeeting = (id) => (
  async dispatch => {
    const { load_meeting_path } = window.meetings;

    try {
      const { data: { meeting } } = await axios.get(formatPath(load_meeting_path, { id }));

      dispatch(updateLatestMeetingAction(meeting));
    } catch (e) {
      console.log(e);
    }
  }
);

const changeCurrentSubscriber = (user) => (
  async dispatch => {
    dispatch(changeCurrentSubscriberAction(user));
  }
);

const loadSubscribers = (query) => {
  const { load_subscribers_path } = window.meetings;

  return axios.get(load_subscribers_path, { params: { query }}).
    then(response => response.data.subscribers).
    catch(e => console.error(e));
};

const startMeeting = ({ id: subscriber_id }) => (
  async dispatch => {
    const { create_meeting_path } = window.meetings;

    dispatch(startMeetingRequestAction());

    try {
      const { data: { meeting } } = await axios({
        method: 'POST',
        url: create_meeting_path,
        data: {
          meeting: {
            subscriber_id
          }
        },
        timeout: 10_000,
        signal: abortSignal(10_000)
      });

      dispatch(startMeetingAction(meeting));
    } catch (e) {
      console.log(e);

      dispatch(endingMeetingAction({ shutdownReason: ShutdownReasons.UnableStartMeeting }));
    }
  }
);

const acceptingMeeting = ({ id, channel }) => (
  async dispatch => {
    const { instance_identifier } = window.meetings;

    dispatch(acceptingMeetingAction());

    channel.perform(MeetingCommands.Accept, { id, instance_identifier });
  }
);

const rejectingMeeting = ({ id, channel }) => (
  async dispatch => {
    dispatch(rejectingMeetingAction());

    channel.perform(MeetingCommands.Reject, { id });
  }
);

const leavingMeeting = ({ id, channel }) => (
  async dispatch => {
    dispatch(leavingMeetingAction());

    channel.perform(MeetingCommands.Reject, { id });
  }
);

const receiveMeetingOffer = ({ meeting }) => {
  return (dispatch, getState) => {
    if (isLineBusy(getState)) return;

    dispatch(receiveMeetingAction(meeting));
  }
};

const isCurrentMeeting = (getState, id) => {
  const { meetings: { currentMeeting }} = getState();

  return currentMeeting.id == id;
};

const isMeetingAcceptTxAvailable = (getState) => {
  const { meetings: { meetingStatus } } = getState();

  return [
    MeetingStatuses.Pending,
    MeetingStatuses.Accepting
  ].includes(meetingStatus);
};

const isAcceptableInstance = (getState, instance_identifier) => {
  const { meetings: { meetingType } } = getState();

  return meetingType == MeetingTypes.Outcoming ||
         window.meetings.instance_identifier == instance_identifier;
};

const receiveMeetingAccept = ({ meeting: { id, instance_identifier, started_at }}) => {
  return (dispatch, getState) => {
    if (!isLineBusy(getState)) return;
    if (!isCurrentMeeting(getState, id)) return;
    if (!isMeetingAcceptTxAvailable(getState)) return;

    if (isAcceptableInstance(getState, instance_identifier)) {
      dispatch(acceptMeetingAction(started_at));
    } else {
      dispatch(endingMeetingAction({ shutdownReason: ShutdownReasons.AnotherDeviceAccepted }));
    }
  }
};

const isMeetingRejectTxAvailable = (getState) => {
  const { meetings: { meetingStatus } } = getState();

  return [
    MeetingStatuses.Pending,
    MeetingStatuses.Rejecting,
    MeetingStatuses.Leaving,
    MeetingStatuses.Active
  ].includes(meetingStatus);
};

const receiveMeetingReject = ({ meeting: { id, ...meetingAttributes }}) => {
  return (dispatch, getState) => {
    if (!isLineBusy(getState)) return;
    if (!isCurrentMeeting(getState, id)) return;
    if (!isMeetingRejectTxAvailable(getState)) return;

    dispatch(endingMeetingAction({ shutdownReason: meetingAttributes.status, meetingAttributes }));
  }
};

const isMeetingOnlineTxAvailable = (getState) => {
  const { meetings: { meetingStatus } } = getState();

  return meetingStatus == MeetingStatuses.Active;
};

const receiveMeetingOnline = ({ meeting: { id, online_subsribers_count }}) => {
  return (dispatch, getState) => {
    if (!isLineBusy(getState)) return;
    if (!isCurrentMeeting(getState, id)) return;
    if (!isMeetingOnlineTxAvailable(getState)) return;

    dispatch(onlineOnMeetingAction(online_subsribers_count));
  }
};

const showShutdownToast = (shutdownReason) => {
  const { message, type } =
    SHUTDOWN_REASONS_TOASTS[shutdownReason];

  toast(message, { type });
};

const endMeeting = () => {
  return (dispatch, getState) => {
    const { meetings: { shutdownReason } } = getState();

    showShutdownToast(shutdownReason);

    dispatch(endMeetingAction());
  }
};

export {
  showModalAction,
  hideModalAction,
  clearModalAction,
  changeCurrentSubscriberAction,
  startHistoryRequestAction,
  getHistoryAction,
  getHistoryErrorAction,
  resetHistoryAction,

  startLatestMeetingsRequestAction,
  getLatestMeetingsAction,
  getLatestMeetingsErrorAction,
  updateLatestMeetingAction,

  startMeetingRequestAction,
  startMeetingAction,
  receiveMeetingAction,
  acceptingMeetingAction,
  rejectingMeetingAction,
  acceptMeetingAction,
  endMeetingAction,
  leavingMeetingAction,
  setCameraStatusAction,
  setMicrophoneStatusAction,
  onlineOnMeetingAction,
  endingMeetingAction,
  setAudioStreamReadyAction,
  setCameraBusyAction,
  setCameraClosingAction,
  setMicrophoneBusyAction,
  setMicrophoneClosingAction,
  setZoomStatusAction,
  setWebsocketsStatusAction,

  connectWebsockets,
  disconnectWebsockets,
  watchdogInterrupt,
  // staleConnectionInterrupt,
  receiveMeetingOffer,
  receiveMeetingReject,
  receiveMeetingAccept,
  receiveMeetingOnline,

  startMeeting,
  acceptingMeeting,
  leavingMeeting,
  rejectingMeeting,
  endMeeting,
  getHistory,
  getLatestMeetings,
  getMeeting,
  changeCurrentSubscriber,
  loadSubscribers
}
