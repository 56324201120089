import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'

import { LineStatuses, MeetingStatuses } from 'src/react/constants/Meetings/index'

import WelcomeScreen from './WelcomeScreen'
import ConnectionScreen from './ConnectionScreen'
import LatencyScreen from './LatencyScreen'
import VideoClient from './VideoClient'

const MeetingContainer = () => {
  const lineStatus = useSelector(state => selectors.lineStatus(state));
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));

  if (
    lineStatus == LineStatuses.Free ||
    lineStatus == LineStatuses.Pending
  ) {
    return (
      <WelcomeScreen />
    );
  }

  if (
    meetingStatus == MeetingStatuses.Pending ||
    meetingStatus == MeetingStatuses.Accepting ||
    meetingStatus == MeetingStatuses.Rejecting
  ) {
    return (
      <ConnectionScreen />
    )
  }

  if (meetingStatus == MeetingStatuses.Leaving) {
    return (
      <LatencyScreen caption={'Leaving meeting ...'} />
    );
  }

  if (meetingStatus == MeetingStatuses.Ending) {
    return (
      <LatencyScreen caption={'Ending meeting ...'} />
    );
  }

  return (
    <VideoClient />
  );
}

export default MeetingContainer
