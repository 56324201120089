import $ from 'jquery';
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

$.fn.eventNotifications = function() {
  const self = $(this);

  let preloadNextNotifications = function(global_block) {
    const viewMoreBlock = $(global_block).find('.view-more-notifications');

    viewMoreBlock.find('.show-more').on('click', function(e) {
      const url = $(this).data('url');

      const request = $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        data: { next: true }
      });

      request.done((response) => {
        viewMoreBlock.replaceWith(response.content);

        preloadNextNotifications(global_block);
      });

      request.fail((xhr) => new ErrorsInterceptor({ xhr }));
    });
  }

  self.on('show.bs.dropdown', function(event) {
    const content = $(event.target).find('#eventNotificationContent');
    const link = $(event.relatedTarget);

    content.html(link.data('disable-with-content')).promise().done(() => {
      let request = $.ajax({
        type: 'GET',
        url: link.data('url'),
        dataType: 'json'
      });

      request.done((data) => {
        content.html(data.content);

        preloadNextNotifications(self)
      });

      request.fail((xhr) => new ErrorsInterceptor({ xhr }));
    });
  });
};
