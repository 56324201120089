import React, { useState } from 'react'
import { useInterval } from 'src/react/hooks/Meetings/useInterval'

import { formatDuration } from 'src/utils/formatDuration'

const MeetingDuration = () => {
  const [duration, setDuration] = useState(0);

  useInterval(() => setDuration((prev) => prev + 1), 1000);

  return formatDuration(duration);
};

export default MeetingDuration