import moment from 'moment'

const patientInfoLabel = (item) => {
  let label = '';

  label += item.gender_name ? `${item.gender_name}, ` : '';
  label += `${moment(item.dob).format('MMM-DD-YYYY').toUpperCase()} (${item.age} y.o.)`;

  return label;
}

export { patientInfoLabel }