import { useCallback, useEffect, useState } from 'react'
import { useRenderVideo } from 'src/react/hooks/Meetings/useRenderVideo'

import {
  calculateLandscapeLayout,
  calculateMiniatureLayout
} from 'src/react/utils/Meetings/layouts'

export function useLayouts({
  zmClient,
  mediaStream,
  isVideoDecodeReady,
  landscapeVideoRef,
  miniatureVideoRef,
  landscapeDimension,
  miniatureDimension
}) {
  const [landscapeParticipant, setLandscapeParticipant] = useState(null);
  const [miniatureParticipant, setMiniatureParticipant] = useState(null);

  const [landscapeLayout, setLandscapeLayout] = useState([]);
  const [miniatureLayout, setMiniatureLayout] = useState([]);

  const onParticipantsChange = useCallback(() => {
    const participants = zmClient.getAllUser();
    const miniatureClient = zmClient.getCurrentUserInfo();

    const landscapeClient = participants.findLast((participant) => {
      return participant.userId != miniatureClient.userId;
    });

    setMiniatureParticipant(miniatureClient);
    setLandscapeParticipant(landscapeClient);
  }, [zmClient]);

  useEffect(() => {
    setLandscapeLayout(calculateLandscapeLayout(landscapeDimension));
    setMiniatureLayout(calculateMiniatureLayout(miniatureDimension));
  }, [landscapeDimension, miniatureDimension]);

  useEffect(() => {
    zmClient.on('user-added', onParticipantsChange);
    zmClient.on('user-removed', onParticipantsChange);
    zmClient.on('user-updated', onParticipantsChange);

    return () => {
      zmClient.off('user-added', onParticipantsChange);
      zmClient.off('user-removed', onParticipantsChange);
      zmClient.off('user-updated', onParticipantsChange);
    };
  }, [zmClient, onParticipantsChange]);
  
  useEffect(() => onParticipantsChange(), [onParticipantsChange]);

  useRenderVideo({
    mediaStream,
    isVideoDecodeReady,
    videoRef: landscapeVideoRef,
    layout: landscapeLayout,
    participant: landscapeParticipant
  });

  useRenderVideo({
    mediaStream,
    isVideoDecodeReady,
    videoRef: miniatureVideoRef,
    layout: miniatureLayout,
    participant: miniatureParticipant
  });

  /*
  useRenderVideoDemo({
    mediaStream,
    isVideoDecodeReady,
    videoRef: landscapeVideoRef,
    layout: landscapeLayout,
    renderedVideos: landscapeRenderedVideos
  });

  useRenderVideoDemo({
    mediaStream,
    isVideoDecodeReady,
    videoRef: miniatureVideoRef,
    layout: miniatureLayout,
    renderedVideos: miniatureRenderedVideos
  });
  */

  return {
    landscapeParticipant,
    miniatureParticipant,
    landscapeLayout,
    miniatureLayout
  };
}
