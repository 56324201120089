import { useEffect, useCallback, useReducer } from 'react'
import { ConnectionState, ReconnectReason } from '@zoom/videosdk'

import {
  mediaStateReducer,
  initialState as mediaStorage
} from 'src/react/store/Meetings/reducers/mediaStateReducer'

export function useZoomConnection(zmClient) {
  const [mediaState, setMediaState] = useReducer(mediaStateReducer, mediaStorage);

  const onConnectionChange = useCallback((payload) => {
    const { state, reason } = payload;

    if (state === ConnectionState.Reconnecting) {
      console.log('Zoom status:', 'reconnecting ...');

      if (reason === ReconnectReason.Failover) {
        console.log('Zoom session disconnected, try to reconnect');
      }
    } else if (state === ConnectionState.Connected) {
      console.log('Zoom status:', 'connected');
      // console.log('Zoom session info:', zmClient.getSessionInfo());

    } else if (state === ConnectionState.Closed) {
      console.log('Zoom status:', 'closed');

      setMediaState({ type: 'reset-media' });
    }
  }, [zmClient]);

  const onMediaSDKChange = useCallback((payload) => {
    const { action, type, result } = payload;

    // console.log('onMediaSDKChange', payload);

    setMediaState({
      type: `${type}-${action}`,
      payload: result === 'success'
    });
  }, []);

  useEffect(() => {
    if (!zmClient) return;

    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);

    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return {
    mediaState
  };
}
