import React from 'react'

const NoResults = () => {
  return (
    <div className="chart-empty-state">
      <div className="mdi mdi-24px mdi-chart-bell-curve-cumulative"></div>
      No Measurements Yet
    </div>
  );
}

export default NoResults
