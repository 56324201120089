import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { WebsocketsStatuses } from 'src/react/constants/Meetings/index'

import { toast } from 'react-toastify'

export function useWebsocketsStatus() {
  const websocketsStatus = useSelector(state => selectors.websocketsStatus(state));

  useEffect(() => {
    if (websocketsStatus == WebsocketsStatuses.Inactive) toast.error('Connection was lost, please try to reload the page');
  }, [websocketsStatus]);
}
