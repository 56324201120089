import { createAction } from 'redux-actions'
import axios from 'src/react/utils/axios'
import moment from 'moment'

import { WidgetTypes } from 'src/react/constants/Metrics/index'

const showModalAction = createAction('METRICS/SHOW_MODAL');
const hideModalAction = createAction('METRICS/HIDE_MODAL');
const clearModalAction = createAction('METRICS/CLEAR_MODAL');

const changeExportOrientationAction = createAction('METRICS/CHANGE_EXPORT_ORIENTATION');
const changeExportWidgetsAction = createAction('METRICS/CHANGE_EXPORT_WIDGETS');

const changeSettingsAction = createAction('METRICS/CHANGE_SETTINGS');
const changeBloodPressureSettingsAction = createAction('METRICS/CHANGE_BLOOD_PRESSURE_SETTINGS');
const changeTemperatureSettingsAction = createAction('METRICS/CHANGE_TEMPERATURE_SETTINGS');
const changeWeightSettingsAction = createAction('METRICS/CHANGE_WEIGHT_SETTINGS');
const changeBloodGlucoseSettingsAction = createAction('METRICS/CHANGE_BLOOD_GLUCOSE_SETTINGS');

const changeIntervalAction = createAction('METRICS/CHANGE_INTERVAL');
const changeCurrentWidgetAction = createAction('METRICS/CHANGE_CURRENT_WIDGET');
const changeRequestStatusAction = createAction('METRICS/CHANGE_REQUEST_STATUS');

const loadMetrics = (params) => {
  return axios.get(window.metrics.load_metrics_path, {
    params: {
      category: 'cardiology',
      ...params
    }
  });
};

const factoryDigest = (props) => {
  const { from, to, settings, type } = props;

  let digestProps;

  switch (type) {
    case WidgetTypes.BloodPressure:
      digestProps = [
        from,
        to,
        settings.bloodPressure.view,
        settings.bloodPressure.rankKey,
        settings.bloodPressure.rankKeyDirection
      ];

      break;

    case WidgetTypes.BloodOxygen:
    case WidgetTypes.EjectionFraction:
      digestProps = [from, to];

      break;

    case WidgetTypes.Temperature:
      digestProps = [from, to, settings.temperature.unit];

      break;

    case WidgetTypes.BloodGlucose:
      digestProps = [from, to, settings.bloodGlucose.unit];

      break;

    case WidgetTypes.Weight:
      digestProps = [from, to, settings.weight.unit];

      break;
  }

  return JSON.stringify(digestProps);
};

const changeInterval = ({ from, to }) => {
  return dispatch => {
    from = moment.isMoment(from) ? from.format(moment.HTML5_FMT.DATE) : from;
    to = moment.isMoment(to) ? to.format(moment.HTML5_FMT.DATE) : to;

    dispatch(changeIntervalAction({ from, to }));
  };
};

const moveFromToday = (quantity, interval) => {
  return (dispatch, getState) => {
    const { metrics: { currentDay } } = getState();

    const from = moment(currentDay).subtract(quantity, interval);

    dispatch(changeInterval({ from, to: moment(currentDay) }));
  };
};

const moveForward = () => {
  return (dispatch, getState) => {
    const {
      metrics: {
        from: fromDate,
        to: toDate,
        settings: {
          navigationOffset
        }
      }
    } = getState();

    let from = moment(fromDate).add(navigationOffset, 'days');
    let to = moment(toDate).add(navigationOffset, 'days');

    dispatch(changeInterval({ from, to }));
  };
};

const moveBackward = () => {
  return (dispatch, getState) => {
    const {
      metrics: {
        from: fromDate,
        to: toDate,
        settings: {
          navigationOffset
        }
      }
    } = getState();

    let from = moment(fromDate).subtract(navigationOffset, 'days');
    let to = moment(toDate).subtract(navigationOffset, 'days');

    dispatch(changeInterval({ from, to }));
  };
};

const exportURL = ({ widgets: active_widgets, orientation }) => {
  return (_dispatch, getState) => {
    const {
      metrics: {
        from: start_date,
        to: end_date,
        settings: {
          weight: {
            unit: weight_unit
          },
          temperature: {
            unit: temperature_unit
          },
          bloodGlucose: {
            unit: blood_glucose_unit
          },
          bloodPressure: {
            view: blood_pressure_view,
            rankKey: blood_pressure_rank_key,
            rankKeyDirection: blood_pressure_rank_key_direction,
          }
        }
      }
    } = getState();

    return window.metrics.export_pdf_path + '?' +
      $.param({
        start_date,
        end_date,
        orientation,
        active_widgets,
        weight_unit,
        temperature_unit,
        blood_glucose_unit,
        blood_pressure_view,
        blood_pressure_rank_key,
        blood_pressure_rank_key_direction
      });
  };
};

export {
  showModalAction,
  hideModalAction,
  clearModalAction,
  changeExportOrientationAction,
  changeExportWidgetsAction,
  changeSettingsAction,
  changeBloodPressureSettingsAction,
  changeTemperatureSettingsAction,
  changeWeightSettingsAction,
  changeBloodGlucoseSettingsAction,
  changeIntervalAction,
  changeCurrentWidgetAction,
  changeRequestStatusAction,
  changeInterval,
  moveForward,
  moveBackward,
  moveFromToday,
  loadMetrics,
  factoryDigest,
  exportURL
};
