import { Controller } from 'stimulus'
import Swal from 'sweetalert2/src/sweetalert2.js'

export default class extends Controller {
  static values = {
    title: { type: String, default: '' },
    icon: { type: String, default: 'question' },
    text: { type: String, default: 'Are you sure?' },
    confirmButtonText: { type: String, default: 'Yes' },
    cancelButtonText: { type: String, default: 'Cancel' }
  };

  connect() {
    this.clicked = false;
  }

  handleConfirm(event) {
    if (this.clicked) return;

    event.preventDefault();
    event.stopPropagation();

    this.confirmation().then((result) => {
      if (this.clicked = result.isConfirmed) {
        this.element.click();
      }
    });
  }

  confirmation() {
    return Swal.fire({
      title: this.titleValue,
      html: this.textValue,
      icon: undefined,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: this.confirmButtonTextValue,
      cancelButtonText: this.cancelButtonTextValue,
      customClass: {
        container: 'swal-container',
        htmlContainer: 'swal-content',
        title: 'swal-title',
        actions: 'swal-actions',
        confirmButton: 'swal-actions-confirm',
        cancelButton: 'swal-actions-cancel'
      },
    });
  }
}
