import { Controller } from 'stimulus'
import Highcharts from 'highcharts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import metrics from 'src/react/store/Metrics'

import { RequestStatuses } from 'src/react/constants/Metrics/index'

import Page from 'src/react/containers/Metrics/Page'

export default class extends Controller {
  connect() {
    this.initializeHighcharts();

    const root = ReactDOM.createRoot(this.element);

    root.render(
      <Provider store={this.store}>
        <Page />
      </Provider>
    );
  }

  initializeHighcharts() {
    // disable click on category label
    Highcharts.Tick.prototype.drillable = function () { };

    Highcharts.wrap(Highcharts.Chart.prototype, 'drillUp', function (proceed) {
      Highcharts.fireEvent(this, 'drillupstart');

      proceed.apply(this);
    });

    Highcharts.setOptions({
      lang: {
        drillUpText: 'Back'
      }
    });
  }

  get store() {
    return configureStore({ metrics }, this.initialState);
  }

  get initialState() {
    const {
      active_widgets: activeWidgets,
      current_widget: currentWidget,
      current_day: currentDay,
      from,
      to,
    } = window.metrics;

    return {
      metrics: {
        activeWidgets,
        currentWidget,
        currentDay,
        from,
        to,
        modalShow: false,
        modalAction: null,
        requestStatus: RequestStatuses.Loading,
        settings: {
          navigationOffset: 7,
          weight: {
            unit: 'lbs'
          },
          temperature: {
            unit: 'fahrenheit'
          },
          bloodGlucose: {
            unit: 'mg_dl'
          },
          bloodPressure: {
            view: 'linear',
            rankKey: 'systolic',
            rankKeyDirection: 'desc',
          }
        }
      }
    };
  }
}
