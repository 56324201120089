import React from 'react'

import { HistoryStatuses } from 'src/react/constants/Meetings/index'

type StatusProps = {
  status: string;
}

const HistoryStatus = ({ status }: StatusProps) => {
  if (status == HistoryStatuses.Rejected) {
    return (
      <div className="text-danger">
        <div className="mdi mdi-call-missed"></div>{' '}
        Rejected
      </div>
    );
  }

  if (status == HistoryStatuses.Timeouted) {
    return (
      <div className="text-info">
        <div className="mdi mdi-call-missed"></div>{' '}
        No answer
      </div>
    );
  }

  if (status == HistoryStatuses.Ended) {
    return (
      <div className="text-success">
        <div className="mdi mdi-call-made"></div>{' '}
        Ended
      </div>
    );
  }

  return null;
}

export default HistoryStatus