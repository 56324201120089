import { useEffect } from 'react'

import { usePrevious } from './usePrevious'

export function useRenderVideo({
  mediaStream,
  isVideoDecodeReady,
  videoRef,
  participant,
  layout
}) {
  const previousParticipant = usePrevious(participant);
  const previousLayout = usePrevious(layout);

  useEffect(() => {
    if (!isVideoDecodeReady || !participant || !participant.bVideoOn) return;

    // console.log('start', isVideoDecodeReady, participant, layout);

    const configureStream = async () => {
      const { width, height, x, y, quality } = layout;

      await mediaStream?.renderVideo(
        videoRef.current,
        participant.userId,
        width,
        height,
        x,
        y,
        quality
      );
    };

    configureStream();

  }, [isVideoDecodeReady, participant]);

  useEffect(() => {
    if (!isVideoDecodeReady || !participant || participant.bVideoOn) return;

    // console.log('stop', isVideoDecodeReady, participant);

    const configureStream = async () => {
      await mediaStream?.stopRenderVideo(videoRef.current, participant.userId);
    };

    configureStream();
  }, [isVideoDecodeReady, participant]);

  useEffect(() => {
    if (!isVideoDecodeReady || !participant || !participant.bVideoOn) return;

    const configureStream = async () => {
      const { width, height, x, y, quality } = layout;

      // console.log('adjust', previousLayout, layout);

      if (
        previousLayout.x == x &&
        previousLayout.y == y &&
        previousLayout.width == width &&
        previousLayout.height == height &&
        previousLayout.quality == quality
      ) return;

      if (previousLayout.quality != quality) {
        // console.log('quality change');

        await mediaStream?.renderVideo(
          videoRef.current,
          participant.userId,
          width,
          height,
          x,
          y,
          quality
        );
      }

      // console.log('adjustRenderedVideoPosition');

      await mediaStream?.adjustRenderedVideoPosition(
        videoRef.current,
        participant.userId,
        width,
        height,
        x,
        y
      );
    };

    configureStream();
  }, [
    isVideoDecodeReady,
    participant,
    layout,
    previousLayout
  ]);

  useEffect(() => {
    if (!isVideoDecodeReady || participant || !previousParticipant) return;

    // console.log('stop render client', isVideoDecodeReady, participant);

    const configureStream = async () => {
      await mediaStream?.stopRenderVideo(videoRef.current, previousParticipant.userId);
    };

    configureStream();
  }, [
    isVideoDecodeReady,
    participant,
    previousParticipant
  ]);
}
