import {
  DEFAULT_OPTIONS,
  DAILY_PLOT_BANDS,
  DATE_FORMAT
} from './charts/defaults'

import { BLOOD_GLUCOSE } from './charts/cardiology/blood_glucose'
import { BLOOD_OXYGEN } from './charts/cardiology/blood_oxygen'
import { BLOOD_PRESSURE } from './charts/cardiology/blood_pressure'
import { TEMPERATURE } from './charts/cardiology/temperature'
import { WEIGHT } from './charts/cardiology/weight'
import { EJECTION_FRACTION } from './charts/cardiology/ejection_fraction'

// rebuild to enum
const RequestStatuses = {
  Loading: 'loading',
  Ready: 'ready',
  Error: 'error'
};

// rebuild to enum
const WidgetTypes = {
  BloodPressure: 'blood_pressure',
  BloodOxygen: 'blood_oxygen',
  BloodGlucose: 'blood_glucose',
  Temperature: 'temperature',
  Weight: 'weight',
  EjectionFraction: 'ejection_fraction'
};

const NAVIGATION_OFFSETS = {
  1: '1 day',
  3: '3 days',
  7: '7 days',
  14: '14 days',
  30: '1 month'
};

const UNITS = {
  lbs: 'Lbs',
  kg: 'Kg',
  celsius: '°C',
  fahrenheit: '°F',
  mg_dl: 'mg/dL',
  mmol_l: 'mmol/L'
};

const WIDGETS = {
  [WidgetTypes.BloodPressure]: {
    name: 'Blood Pressure',
    icon: 'icomoon-test-blood-pressure'
  },
  [WidgetTypes.BloodOxygen]: {
    name: 'Blood Oxygen',
    icon: 'icomoon-test-blood-oxygen'
  },
  [WidgetTypes.Temperature]: {
    name: 'Temperature',
    icon: 'icomoon-test-temperature'
  },
  [WidgetTypes.BloodGlucose]: {
    name: 'Blood Glucose',
    icon: 'icomoon-test-blood-glucose'
  },
  [WidgetTypes.Weight]: {
    name: 'Weight',
    icon: 'icomoon-test-weight'
  },
  [WidgetTypes.EjectionFraction]: {
    name: 'LVEF',
    icon: 'icomoon-test-ejection-fraction'
  }
};

export {
  RequestStatuses,
  WidgetTypes,
  DEFAULT_OPTIONS,
  DAILY_PLOT_BANDS,
  DATE_FORMAT,
  BLOOD_GLUCOSE,
  BLOOD_OXYGEN,
  BLOOD_PRESSURE,
  TEMPERATURE,
  WEIGHT,
  EJECTION_FRACTION,
  NAVIGATION_OFFSETS,
  UNITS,
  WIDGETS
}