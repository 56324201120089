import { Controller } from 'stimulus'

const BACKSPACE_KEY = 8;
const ENTER_KEY = 13;
const TAB_KEY = 9;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;

export default class extends Controller {
  static targets = ['input', 'otpAttemptInput']

  connect() {
    this.inputTargets.forEach((element, index) => {
     this.registerEvents(element, index);
    });
  }

  disconnect() {
    this.inputTargets.forEach((element, index) => {
      this.unregisterEvents(element, index);
    });
  }

  changeCallback() {
    this.otpAttemptInputTarget.value = this.retrieveOTP();

    this.dispatch('change', {
      detail: {
        value: this.retrieveOTP(),
        isReady: this.isOTPComplete()
      }
    });
  }

  completeCallback() {
    this.dispatch('complete', {
      detail: {
        value: this.retrieveOTP()
      }
    });
  }

  registerEvents(element, index) {
    element.addEventListener('input', (e) => this.onInput(e, index));
    element.addEventListener('paste', (e) => this.onPaste(e, index));
    element.addEventListener('keydown', (e) => this.onKeyDown(e, index));
  }

  unregisterEvents(element, index) {
    element.removeEventListener('input', (e) => this.onInput(e, index));
    element.removeEventListener('paste', (e) => this.onPaste(e, index));
    element.removeEventListener('keydown', (e) => this.onKeyDown(e, index));
  }

  onKeyDown(ev, index) {
    const key = ev.keyCode || ev.which;

    if (key == LEFT_KEY && index > 0) {
      ev.preventDefault(); // prevent cursor to move before digit in input
      this.inputTargets[index - 1].focus();
    }

    if (key == RIGHT_KEY && index + 1 < this.inputTargets.length) {
      ev.preventDefault();
      this.inputTargets[index + 1].focus();
    }

    if (key == BACKSPACE_KEY && index > 0) {
      if (this.inputTargets[index].value == "") {
        // Empty and focus previous input and current input is empty
        this.inputTargets[index - 1].value = "";
        this.inputTargets[index - 1].focus();
      } else {
        this.inputTargets[index].value = "";
      }
    }

    /*
    if (key == ENTER_KEY) {
      // force submit if enter is pressed
      ev.preventDefault();

      if (this.isOTPComplete()) this.completeCallback();
    }

    if (key == TAB_KEY && index == this.inputTargets.length - 1) {
      // force submit if tab pressed on last input
      ev.preventDefault();
      
      if (this.isOTPComplete()) this.completeCallback();
    }
    */

    if (
      //key == TAB_KEY ||
      //key == ENTER_KEY ||
      key == BACKSPACE_KEY ||
      key == LEFT_KEY ||
      key == RIGHT_KEY
    ) {
      this.changeCallback();
    }
  }

  onPaste(ev, index) {
    ev.preventDefault();

    let curIndex = index;
    let clipboardData = ev.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");

    for (let i = 0; i < pastedData.length; i++) {
      if (i < this.inputTargets.length) {
        if (!this.isDigit(pastedData[i])) break;

        this.inputTargets[curIndex].value = pastedData[i];
        curIndex++;
      }
    }

    if (curIndex == this.inputTargets.length) {
      this.inputTargets[curIndex - 1].focus();
      this.completeCallback();
    } else {
      this.inputTargets[curIndex].focus();
    }

    this.changeCallback();
  }

  onInput(ev, index) {
    let value = ev.data || ev.target.value;
    let curIndex = index;

    for (let i = 0; i < value.length; i++) {
      if (i < this.inputTargets.length) {
        if (!this.isDigit(value[i])) {
          this.inputTargets[curIndex].value = "";
          break;
        }

        this.inputTargets[curIndex++].value = value[i];

        if (curIndex == this.inputTargets.length) {
          if (this.isOTPComplete()) this.completeCallback();
        } else {
          this.inputTargets[curIndex].focus();
        }
      }
    }

    this.changeCallback();
  }

  retrieveOTP() {
    let otp = "";

    for (let i = 0; i < this.inputTargets.length; i++) {
      otp += this.inputTargets[i].value;
    }

    return otp;
  }

  isDigit(d) {
    return d >= "0" && d <= "9";
  }

  isOTPComplete() {
    let isComplete = true;
    let i = 0;

    while (i < this.inputTargets.length && isComplete) {
      if (this.inputTargets[i].value == "") {
        isComplete = false;
      }
      i++;
    }

    return isComplete;
  }
}
