import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { hideModalAction, clearModalAction } from 'src/react/store/Meetings/actions'

import HistoryModal from './Modals/HistoryModal'

const ModalFactory = () => {
  const modalShow = useSelector(state => selectors.modalShow(state));
  const modalAction = useSelector(state => selectors.modalAction(state));

  const dispatch = useDispatch();

  const modalProps = {
    show: modalShow,
    onHide: () => dispatch(hideModalAction()),
    onExited: () => dispatch(clearModalAction())
  };

  if (modalAction == 'history') {
    return <HistoryModal {...modalProps} />;
  }

  return null;
}

export default ModalFactory
