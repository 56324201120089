import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'
import 'src/plugins/selectize/noResults'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

export default class extends Controller {
  connect() {
    this.initializeSelectize();
  }

  initializeSelectize() {
    const self = this;

    this.selectize =
      $(this.element).selectize({
        plugins: ['no_results'],
        valueField: 'id',
        searchField: 'name',
        loadThrottle: 300,
        dataAttr: 'data-hydrate',
        sortField: [{
          direction: 'asc',
          field: '$score'
        }],
        render: {
          item: function(item, escape) {
            return self.formatResults(item, escape);
          },
          option: function(item, escape) {
            return self.formatResults(item, escape);
          }
        },
        onType: function(query) {
          this.clearOptions();
          this.refreshOptions();
        },
        load: function(query, callback) {
          this.switchNoResults('hide');

          //if (!query.length) return callback();

          $.ajax({
            type: 'GET',
            url: self.url,
            dataType: 'json',
            data: {
              query: query,
              type: self.type
            },
            success: (res) => {
              self.selectizeInstance.switchNoResults(
                (res.suggestions.length == 0 ? 'show' : 'hide')
              );

              callback(res.suggestions);
            },
            error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
          });
        }
      });
  }

  resetParticipants() {
    this.selectizeInstance.clear();
    this.selectizeInstance.clearOptions();
    this.selectizeInstance.clearCache();
  }

  formatResults(item, escape) {
    switch (this.type) {
      case 'patient':
        return this.formatUsers(item, escape);
      default:
        return null;
    }
  }

  formatUsers(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta users">
          <div class="content">
            <div class="name">
              ${escape(item.name)}
            </div>
            <div class="info-label">
              ${patientInfoLabel(item)}
            </div>
          </div>
          <div class="archived-badge">
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  get url() {
    return this.element.dataset.url;
  }

  get type() {
    return this.element.dataset.type;
  }

  get selectizeInstance() {
    return this.selectize[0].selectize;
  }
}
