import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'

import { disconnectWebsockets } from 'src/react/store/Meetings/actions'

export function useMeetingChannel(actionCable) {
  const currentMeetingId = useSelector(state => selectors.currentMeetingId(state));
  const inMeeting = useSelector(state => selectors.inMeeting(state));

  const [meetingChannel, setMeetingChannel] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentMeetingId || !inMeeting) return;

    const channel = actionCable.subscriptions.create({
      channel: 'Meetings::MeetingChannel',
      id: currentMeetingId
    }, {
      disconnected: () => dispatch(disconnectWebsockets())
    });

    setMeetingChannel(channel);

    return () => {
      channel.unsubscribe();
    };
  }, [currentMeetingId, inMeeting]);

  return {
    channel: meetingChannel
  };
}
