import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { allowNegative: { type: Boolean, default: false } };

  connect() {
    this.element.addEventListener('input', (e) => this.handleInput(e.target));
    this.element.addEventListener('focusout', (e) => this.handleFocusOut(e.target));
  }

  disconnect() {
    this.element.removeEventListener('input', (e) => this.handleInput(e.target));
    this.element.removeEventListener('focusout', (e) => this.handleFocusOut(e.target));
  }

  handleInput(target) {
    if (typeof(target.value) === 'string') {
      const regex = this.allowNegativeValue ? /-?\d+|-?/i : /\d+/i;

      target.value = target.value.match(regex);
    }
  }

  handleFocusOut(target) {
    if (typeof(target.value) === 'string') {
      target.value = target.value.match(/^-?\d+$/i);
    }
  }
}