import React, { useContext, useRef } from 'react'

import { useDimensions } from 'src/react/hooks/Meetings/useDimensions'
import { useLayouts } from 'src/react/hooks/Meetings/useLayouts'
import { useLocalVolume } from 'src/react/hooks/Meetings/useLocalVolume'

import ZoomContext from 'src/react/contexts/Meetings/zoom-context'
import ZoomMediaStreamContext from 'src/react/contexts/Meetings/media-stream-context'
import ZoomMediaStateContext from 'src/react/contexts/Meetings/media-state-context'

import Avatar from 'src/react/components/Meetings/Avatar'
import VideoControls from 'src/react/components/Meetings/VideoControls'

const VideoContainer = () => {
  const landscapeVideoRef = useRef(null);
  const miniatureVideoRef = useRef(null);

  const zmClient = useContext(ZoomContext);
  const mediaStream = useContext(ZoomMediaStreamContext);

  const {
    video: {
      decode: isVideoDecodeReady
    }
  } = useContext(ZoomMediaStateContext);

  // dynamically receive size of canvases (width x height) on layout resize
  const {
    landscapeDimension,
    miniatureDimension
  } = useDimensions({ mediaStream, landscapeVideoRef, miniatureVideoRef });

  const {
    landscapeParticipant,
    miniatureParticipant,
    landscapeLayout,
    miniatureLayout
  } = useLayouts({
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    landscapeVideoRef,
    miniatureVideoRef,
    landscapeDimension,
    miniatureDimension
  });

  const { userVolumeList, setLocalVolume } = useLocalVolume();

  return (
    <div className="viewport">

      <div className="video-container">
        <canvas
          className="video-canvas"
          id="video-canvas"
          width="800"
          height="600"
          ref={landscapeVideoRef}
        />

        <div className="clients">
          {
            landscapeParticipant && (
              <Avatar
                type={"landscape"}
                participant={landscapeParticipant}
                volume={userVolumeList.find((u) => u.userId === landscapeParticipant.userId)?.volume}
                setLocalVolume={setLocalVolume}
                style={{
                  width: `${landscapeLayout.width}px`,
                  height: `${landscapeLayout.height}px`,
                  top: `${landscapeDimension.height - landscapeLayout.y - landscapeLayout.height}px`,
                  left: `${landscapeLayout.x}px`
                }}
              />
            )
          }
        </div>
      </div>

      <div className="self-container">
        <canvas
          className="video-canvas"
          id="self-video-canvas"
          width="320"
          height="180"
          ref={miniatureVideoRef}
        />

        {
          miniatureParticipant && (
            <Avatar
              type={"miniature"}
              participant={miniatureParticipant}
              volume={userVolumeList.find((u) => u.userId === miniatureParticipant.userId)?.volume}
              setLocalVolume={setLocalVolume}
              style={{
                width: `${miniatureLayout.width}px`,
                height: `${miniatureLayout.height}px`
              }}
            />
          )
        }
      </div>

      <VideoControls />
    </div>
  );
};

export default VideoContainer
