import moment from 'moment'

import {
  DATE_FORMAT,
  DEFAULT_OPTIONS,
  BLOOD_PRESSURE
} from 'src/react/constants/Metrics/index'

import Base from './Base'

class BloodPressure extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, BLOOD_PRESSURE);
  }

  onActivateDaily(chart, e, response) {}
  onDeactivateDaily(chart) {}

  tooltipFormatter(context, tooltip) {
    let s = this.tooltipHeader(this.tooltipLabel(context.points[0].point).toUpperCase());

    context.points.forEach((point) => {
      s += this.tooltipBody(point, this.tooltipResult(point.point));
    });

    return s;
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format(DATE_FORMAT);

    return `${moment(point.start_date).format(DATE_FORMAT)} - ${moment(point.end_date).format(DATE_FORMAT)}`;
  }

  tooltipResult(point) {
    return ('low' in point && 'high' in point) ? `${point.low} - ${point.high}` : point.y;
  }

  requestOptions() {
    const {
      settings: {
        bloodPressure: {
          view,
          rankKey: rank_key,
          rankKeyDirection: rank_key_direction
        }
      }
    } = this.settings;

    return {
      view,
      rank_key,
      rank_key_direction,
      widget: 'blood_pressure'
    };
  }
}

export default BloodPressure
