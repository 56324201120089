import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { disconnectWebsockets } from 'src/react/store/Meetings/actions'

import {
  MeetingStatuses,
  STALE_CONNECTION_TIMEOUT
} from 'src/react/constants/Meetings/index'

const now = () => new Date().getTime();
const secondsSince = time => (now() - time) / 1000;

export function useStaleConnection(actionCable) {
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));

  const dispatch = useDispatch();

  const isStaleConnection = () => {
    return secondsSince(actionCable.connection.monitor.refreshedAt) > STALE_CONNECTION_TIMEOUT;
  };

  const handleInterrupts = () => {
    if (!isStaleConnection()) return;

    dispatch(disconnectWebsockets());
  };

  useEffect(() => {
   if (meetingStatus != MeetingStatuses.Active) return;

    const timeoutID = setInterval(() => handleInterrupts(), 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [meetingStatus]);
}