import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  connectWebsockets,
  disconnectWebsockets,
  receiveMeetingOffer,
  receiveMeetingReject,
  receiveMeetingAccept,
  receiveMeetingOnline,
} from 'src/react/store/Meetings/actions'

import { MeetingEvents } from 'src/react/constants/Meetings/index'

export function useCommandChannel(actionCable) {
  const [commandChannel, setCommandChannel] = useState(null);

  const dispatch = useDispatch();

  const eventsHandler = ({ event, ... payload }) => {
    // console.log(event, payload);

    switch (event) {
      case MeetingEvents.Offer:
        dispatch(receiveMeetingOffer(payload));
        break;

      case MeetingEvents.Reject:
      case MeetingEvents.Timeout:
        dispatch(receiveMeetingReject(payload));
        break;

      case MeetingEvents.Accept:
        dispatch(receiveMeetingAccept(payload));
        break;

      case MeetingEvents.Online:
        dispatch(receiveMeetingOnline(payload));
        break;
    }
  };

  useEffect(() => {
    const channel = actionCable.subscriptions.create({
      channel: 'Meetings::CommandChannel'
    }, {
      connected: () => dispatch(connectWebsockets()),
      disconnected: () => dispatch(disconnectWebsockets()),
      received: (data) => eventsHandler(data)
    });

    setCommandChannel(channel);

    return () => {
      channel.unsubscribe();
    };
  }, []);

  return {
    channel: commandChannel
  };
}
