import React from 'react'

import { MeetingStatuses } from 'src/react/constants/Meetings/index'

type StatusProps = {
  status: string;
}

const MeetingStatus = ({ status }: StatusProps) => {
  if (status == MeetingStatuses.Pending) {
    return (
      <div className="text-warning">
        <div className="mdi mdi-call-received"></div>{' '}
        Calling ...
      </div>
    );
  }

  if (status == MeetingStatuses.Accepting) {
    return (
      <div className="text-info">
        <div className="mdi mdi-access-point"></div>{' '}
        Accepting ...
      </div>
    );
  }

  if (status == MeetingStatuses.Rejecting) {
    return (
      <div className="text-danger">
        <div className="mdi mdi-call-missed"></div>{' '}
        Rejecting ...
      </div>
    );
  }

  if (status == MeetingStatuses.Leaving) {
    return (
      <div className="text-danger">
        <div className="mdi mdi-call-missed"></div>{' '}
        Leaving ...
      </div>
    );
  }

  if (status == MeetingStatuses.Ending) {
    return (
      <div className="text-danger">
        <div className="mdi mdi-call-missed"></div>{' '}
        Ending ...
      </div>
    );
  }

  if (status == MeetingStatuses.Active) {
    return (
      <div className="text-info">
        <div className="mdi mdi-access-point"></div>{' '}
        On air
      </div>
    );
  }

  return null;
}

export default MeetingStatus