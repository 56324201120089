import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['staffType', 'renderingPhysicianOperative'];

  static values = {
    locked: Boolean
  }

  connect() {
    this.locks();
    this.refresh();
  }

  locks() {
    this.activeRenderingPhysicianField(!this.lockedValue);
    this.activeStaffTypeField(!this.lockedValue);
  }

  refresh() {
    if (this.lockedValue) return;

    const isProvider = this.staffTypeTarget.value == 'provider';

    if (!isProvider) {
      this.renderingPhysicianOperativeTarget.checked = false;
    }

    this.activeRenderingPhysicianField(isProvider);
  }

  activeRenderingPhysicianField(active) {
    this.renderingPhysicianOperativeTarget.disabled = !active;
    this.renderingPhysicianOperativeContainer.classList.toggle('inactive', !active);
  }

  activeStaffTypeField(active) {
    this.staffTypeTarget.disabled = !active;
  }

  get renderingPhysicianOperativeContainer() {
    return this.renderingPhysicianOperativeTarget.parentNode;
  }
}
