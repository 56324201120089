import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import meetings from 'src/react/store/Meetings'

import ActionCableConnection from 'src/react/containers/Meetings/ActionCableConnection'
import Page from 'src/react/containers/Meetings/Page'

export default class extends Controller {
  connect() {
    const root = ReactDOM.createRoot(this.element);

    root.render(
      <React.StrictMode>
        <Provider store={this.store}>
          <ActionCableConnection>
            <Page />
          </ActionCableConnection>
        </Provider>
      </React.StrictMode>
    );
  }

  get store() {
    return configureStore({ meetings }, this.initialState);
  }

  get initialState() {
    const {
      current_user: currentUser,
      current_subscriber: currentSubscriber
    } = window.meetings;

    return {
      meetings: {
        currentUser,
        currentSubscriber,
        history: [],
        isMoreHistory: true,
        isLoadingHistory: false,
        isLoadingHistoryError: false,
        latestMeetings: [],
        isLoadingLatestMeetings: true,
        isLoadingLatestMeetingsError: false,
        cameraBusy: false,
        cameraStatus: null,
        cameraClosing: false,
        microphoneBusy: false,
        microphoneStatus: null,
        microphoneClosing: false,
        audioStreamReady: false,
        websocketsStatus: null,
        meetingSubscribersOnline: 0,
        meetingStatus: null,
        meetingType: null,
        inMeeting: false,
        lineStatus: 'free',
        zoomStatus: 'free',
        shutdownReason: null,
        modalShow: false,
        modalAction: null,
        currentMeeting: {}
      }
    };
  }
}
