import { Controller } from 'stimulus'
import MonitoringReasonsFormatter from 'src/utils/monitoringReasonsFormatter'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    let self = this;

    const url = this.element.dataset.url;
    const type = this.element.dataset.type;
    const preloadingOptions = JSON.parse(this.element.dataset.preloadingOptions);
    const isGroupingDisabled = this.element.dataset.disableGroupByNode == true;
    const formatter = new MonitoringReasonsFormatter(this.element);

    $(this.element).selectize({
      valueField: 'code',
      labelField: 'code',
      searchField: ['code', 'description'],
      options: preloadingOptions,
      create: false,
      plugins: ['remove_button'],
      disabledField: isGroupingDisabled ? false : 'node',
      render: {
        option: formatter.optionViewCallback(),
        item: formatter.itemViewCallback()
      },
      onInitialize: this.tagTooltipCallback,
      onChange: function() {
        self.handleSelection(this);
        self.tagTooltipCallback();
        formatter.cleanHiddenSelectOptions(this.$input[0].options);
      },
      load: function(query, callback) {
        query = formatter.dotInsensitiveQuery(query);
        this.clearOptions();

        if (!query.length) return callback();

        $.ajax({
          url: url,
          dataType: 'json',
          data: { search: query, type: type },
          success: (response) => callback(formatter.formatResponseData(response.suggestions)),
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        })
      },
    });
  }

  handleSelection(target) {
    const selection = target.getValue();

    if (this.reasonsAndNoneSelected(selection)) {
      if (selection[0] != 'None') {
        this.setNoneSelected(target);
      } else {
        this.setReasonsSelected(target);
      }
    }
  }

  reasonsAndNoneSelected(selection) {
    return selection.length > 1 && selection.includes('None')
  }

  setNoneSelected(target) {
    target.setValue(['None']);
    target.close();
  }

  setReasonsSelected(target) {
    const selection = target.getValue();
    const indexItemToRemove = selection.indexOf('None');

    if (indexItemToRemove >= 0) {
      selection.splice(indexItemToRemove, 1)
      target.setValue(selection);
    }
  }

  tagTooltipCallback() {
    $('[data-toggle="tooltip"], .tooltip').tooltip('hide');
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
  }
}
