import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Metrics/selectors'

import { exportURL } from 'src/react/store/Metrics/actions'
import { useCheckboxes } from 'src/react/hooks/Metrics/useCheckboxes'

import { WIDGETS } from 'src/react/constants/Metrics/index'

import {
  Modal,
  ToggleButtonGroup,
  ButtonGroup,
  ToggleButton,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

const ExportModal = (props) => {
  const activeWidgets = useSelector(state => selectors.activeWidgets(state));

  const dispatch = useDispatch();

  const [orientation, setOrientation] = useState('landscape');
  const [widgets, setWidgets] = useCheckboxes(activeWidgets);

  const lastWidget = widgets.length == 1 ? widgets[0] : null;
  const documentPageURL = dispatch(exportURL({ widgets, orientation }));

  return (
    <Modal
      className="patient-metrics-export-modal"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4">
          Export to PDF
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="export-widget">
          <div className="export-widget-line select-format">
            <div className="caption">
              Format
            </div>
            <ToggleButtonGroup
              type="radio"
              name="orientation"
              value={orientation}
              onChange={setOrientation}
            >
              <ToggleButton value="landscape" variant="white">Landscape</ToggleButton>
              <ToggleButton value="portrait" variant="white">Portrait</ToggleButton>
            </ToggleButtonGroup>
          </div>

          {
            activeWidgets.length > 1 && (
              <div className="export-widget-line select-charts">
                <div className="caption">
                  Show Charts
                </div>
                <ButtonGroup
                  toggle
                  bsPrefix="btn-group-select-charts"
                  className="btn-group-toggle"
                >
                  {
                    activeWidgets.map((widgetType) => {
                      const isChecked = widgets.includes(widgetType);

                      return (
                        <OverlayTrigger
                          key={widgetType}
                          overlay={
                            <Tooltip
                              id={`tooltip-${widgetType}`}
                            >
                              {WIDGETS[widgetType].name}
                            </Tooltip>
                          }
                        >
                          <ToggleButton
                            type="checkbox"
                            variant="white"
                            value={widgetType}
                            checked={isChecked}
                            onChange={setWidgets}
                            className={widgetType}
                            disabled={lastWidget == widgetType}
                          />
                        </OverlayTrigger>
                      )
                    })
                  }
                </ButtonGroup>
              </div>
            )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cancel
        </a>
        <a
          href={documentPageURL}
          onClick={props.onHide}
          className="btn btn-primary"
          target="_blank"
        >
          Export to PDF
        </a>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportModal
