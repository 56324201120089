import React from 'react'

import CurrentMeeting from 'src/react/components/Meetings/CurrentMeeting'
import LatestMeetings from 'src/react/containers/Meetings/LatestMeetings'

const Activities = () => {
  return (
    <div className="activities">
      <CurrentMeeting />
      <LatestMeetings />
    </div>
  );
}

export default Activities
