import React from 'react'

import BloodPressure from './Scores/BloodPressure'
import BloodOxygen from './Scores/BloodOxygen'
import BloodGlucose from './Scores/BloodGlucose'
import Temperature from './Scores/Temperature'
import Weight from './Scores/Weight'
import EjectionFraction from './Scores/EjectionFraction'

import { WidgetTypes } from 'src/react/constants/Metrics/index'

const factoryComponents = {
  [WidgetTypes.BloodPressure]: BloodPressure,
  [WidgetTypes.BloodOxygen]: BloodOxygen,
  [WidgetTypes.BloodGlucose]: BloodGlucose,
  [WidgetTypes.Temperature]: Temperature,
  [WidgetTypes.Weight]: Weight,
  [WidgetTypes.EjectionFraction]: EjectionFraction
};

const ScoresFactory = ({ type, ... rest }) => {
  const Scores = factoryComponents[type];
  
  return <Scores {...rest} />;
}

export default ScoresFactory
