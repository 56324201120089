import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.submitActive(false);
  }

  change({ detail: { isReady }}) {
    this.submitActive(isReady);
  }

  submitActive(isReady) {
    this.submitTarget.disabled = !isReady;
  }
}
