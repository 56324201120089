import { handleActions } from 'redux-actions'
import produce from 'immer'

import {
  showModalAction,
  hideModalAction,
  clearModalAction,
  changeSettingsAction,
  changeBloodPressureSettingsAction,
  changeTemperatureSettingsAction,
  changeWeightSettingsAction,
  changeBloodGlucoseSettingsAction,
  changeIntervalAction,
  changeCurrentWidgetAction,
  changeRequestStatusAction
} from './actions'

const initialState = {};

export default handleActions({
  [showModalAction]: (state, { payload: modalAction }) => {
    return produce(state, draftState => {
      Object.assign(draftState, { modalAction, modalShow: true });
    });
  },
  [hideModalAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.modalShow = false;
    });
  },
  [clearModalAction]: (state, { payload }) => {
    return produce(state, draftState => {
      draftState.modalAction = null;
    });
  },
  [changeSettingsAction]: (state, { payload: settings }) => {
    return produce(state, draftState => {
      Object.assign(draftState.settings, settings);
    });
  },
  [changeBloodPressureSettingsAction]: (state, { payload: settings }) => {
    return produce(state, draftState => {
      Object.assign(draftState.settings.bloodPressure, settings);
    });
  },
  [changeTemperatureSettingsAction]: (state, { payload: settings }) => {
    return produce(state, draftState => {
      Object.assign(draftState.settings.temperature, settings);
    });
  },
  [changeWeightSettingsAction]: (state, { payload: settings }) => {
    return produce(state, draftState => {
      Object.assign(draftState.settings.weight, settings);
    });
  },
  [changeBloodGlucoseSettingsAction]: (state, { payload: settings }) => {
    return produce(state, draftState => {
      Object.assign(draftState.settings.bloodGlucose, settings);
    });
  },
  [changeIntervalAction]: (state, { payload: { from, to } }) => {
    return produce(state, draftState => {
      draftState.from = from;
      draftState.to = to;
    });
  },
  [changeCurrentWidgetAction]: (state, { payload: currentWidget }) => {
    return produce(state, draftState => {
      draftState.currentWidget = currentWidget;
    });
  },
  [changeRequestStatusAction]: (state, { payload: status }) => {
    return produce(state, draftState => {
      draftState.requestStatus = status;
    });
  },
}, initialState);