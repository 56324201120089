class ErrorsInterceptor {
  constructor({ xhr, data = null }, callback = null) {
    this.xhr = xhr;
    this.data = data;
    this.responseData = this.processResponse();

    if (this.intercepts()) {
      this.handleInterception();
    } else if (typeof callback === "function") {
      callback(this);
    }
  }

  intercepts() {
    return this.badRequest() ||
           this.unauthorizedRequest() ||
           this.unknownRequestFormat();
  }

  handleInterception() {
    window.location = '/';
  }

  badRequest() {
    return this.status == 400;
  }

  unknownRequestFormat() {
    return this.status == 415;
  }

  unauthorizedRequest() {
    return this.status == 401;
  }

  processResponse() {
    if (this.data && typeof this.data === "object") return this.data;
    if (this.xhr.responseJSON && typeof this.xhr.responseJSON === "object") return this.xhr.responseJSON;

    let response = null;

    try {
      response = JSON.parse(this.xhr.responseText);
    } catch (error) { }

    return response;
  }

  hasResponseError(message) {
    return this.responseData &&
           this.responseData.error &&
           this.responseData.error == message;
  }

  get status() {
    return this.xhr.status;
  }
}

export default ErrorsInterceptor
