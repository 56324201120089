import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'
import 'src/plugins/selectize/noResults'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

export default class extends Controller {
  connect() {
    this.initializeSelectize();
    this.initializeSwitchers();
  }

  initializeSwitchers() {
    this.switchParticipantsBox();

    $(this.element).on('change', '.namespace', (e) => {
      this.resetParticipants();
      this.switchParticipantsBox();
    });
  }

  formatPatientGroups(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta patient-groups">
          <div class="name">
            ${escape(item.name)}
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  formatUsers(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta users">
          <div class="name">
            ${escape(item.name)}
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
          <div class="info-label">
            ${patientInfoLabel(item)}
          </div>
        </div>
      </div>
    `;
  }

  formatResults(item, escape) {
    switch (this.namespace) {
      case 'patient_group':
        return this.formatPatientGroups(item, escape);
      case 'patient':
        return this.formatUsers(item, escape);
      default:
        return null;
    }
  }

  initializeSelectize() {
    const self = this;

    this.selectize = this.participantsBox.selectize({
      create: false,
      plugins: ['no_results', 'remove_button'],
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      loadThrottle: 300,
      //dataAttr: 'data-hydrate',
      render: {
        item: function(item, escape) {
          return `<div class="option">${escape(item.name)}</div>`;
        },
        option: function(item, escape) {
          return self.formatResults(item, escape);
        }
      },
      onType: function(query) {
        this.clearOptions();
        this.refreshOptions();
      },
      load: function(query, callback) {
        this.switchNoResults('hide');

        //if (!query.length) return callback();

        $.ajax({
          type: 'GET',
          url: self.url,
          dataType: 'json',
          data: {
            query: query,
            type: self.namespace
          },
          success: (res) => {
            self.selectizeInstance.switchNoResults(
              (res.suggestions.length == 0 ? 'show' : 'hide')
            );

            callback(res.suggestions);
          },
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        });
      }
    });
  }

  resetParticipants() {
    this.selectizeInstance.clear();
    this.selectizeInstance.clearOptions();
    this.selectizeInstance.clearCache();
  }

  switchParticipantsBox() {
    const action = ['patient', 'patient_group'].
      find(n => this.namespace == n) ? 'show' : 'hide';

    this.participantsContext[action]();
  }

  get url() {
    return this.participantsBox.data('url');
  }

  get namespace() {
    return $(this.element).find('.namespace option:selected').val();
  }

  get participantsContext() {
    return $(this.element).find('.participants-context');
  }

  get participantsBox() {
    return $(this.element).find('.selectize-rule-participants');
  }

  get selectizeInstance() {
    return this.selectize[0].selectize;
  }
}
