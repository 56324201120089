import ConfirmController from 'src/stimulus/controllers/utils/confirm_controller'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends ConfirmController {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    $(this.element).replaceWith(data.content);
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data });
  }
}
