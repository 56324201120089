import axios from 'axios'
import Rails from '@rails/ujs'

import ErrorsInterceptor from 'src/utils/errorsInterceptor'

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  config.headers.common['X-CSRF-Token'] = Rails.csrfToken();

  return config;
}, (err) => {
  return Promise.reject(err);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    new ErrorsInterceptor({ xhr: error.response.request, data: error.response.data });
  }

  return Promise.reject(error);
});

export default axiosInstance
