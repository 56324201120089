import React from 'react'

import classnames from 'classnames'

import { Participant } from '@zoom/videosdk'

interface AvatarProps {
  participant: Participant;
  style?: { [key: string]: string };
  type: string;
}

const Avatar = (props: AvatarProps) => {
  const {
    participant,
    style,
    type
  } = props;

  const {
    displayName,
    audio,
    muted,
    bVideoOn
  } = participant;

  const avatarClass = type == 'miniature' ? 'avatar-miniature' : 'avatar';
  const avatarBackground = bVideoOn ? 'transparent' : (type == 'miniature' ? 'rgb(55, 52, 52)' : 'rgb(26, 26, 26)');

  return (
    <div
      className={classnames(avatarClass, 'shadow-sm')}
      style={{ ...style, background: avatarBackground }}
    >
      {(bVideoOn || (audio === 'computer' && muted)) && (
        <div className="corner-name">
          {audio === 'computer' && muted && <span className="mdi mdi-microphone-off text-danger" />}
          {bVideoOn && <span>{displayName}</span>}
        </div>
      )}
      {!bVideoOn && <p className="center-name">{displayName}</p>}
    </div>
  );
};

export default Avatar
