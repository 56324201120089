import React from 'react'

import { WIDGETS } from 'src/react/constants/Metrics/index'

const RequestError = ({ type }) => {
  return (
    <div className="failure">
      <div className="mdi mdi-alert-circle"></div>
      Failed to load {WIDGETS[type].name} measurements.
    </div>
  );
}

export default RequestError