import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { DATE_FORMAT } from 'src/react/constants/Timesheets/index'

import {
  showFormModalAction,
  changeTimesheetRangeAction,
  changeActionable,
  loadActionables
} from 'src/react/store/Timesheets/actions'

import { Button, Dropdown } from 'react-bootstrap'

import Actionable from './Actionable'
import TotalAmount from 'src/react/components/Timesheets/TotalAmount'

const Controls = ({ from, to, currentWeek }) => {
  const dispatch = useDispatch();

  const fromPretty = moment(from).format(DATE_FORMAT);
  const toPretty = moment(to).format(DATE_FORMAT);
  const isCurrentWeek = from == currentWeek.from && to == currentWeek.to;

  const isLoading = useSelector(state => selectors.isLoading(state));
  const currentUser = useSelector(state => selectors.currentUser(state));
  const isMedicalGroupAdmin = currentUser.role == 'medical_group_admin';

  const previousWeek = () => [
    moment(from).subtract(7, 'days'), moment(to).subtract(7, 'days')
  ];

  const nextWeek = () => [
    moment(from).add(7, 'days'), moment(to).add(7, 'days')
  ];

  const weeksRange = () => {
    return [14, 7, 0, -7, -14].map(days => [
      moment(from).subtract(days, 'days'),
      moment(to).subtract(days, 'days')
    ]);
  };

  const changeRange = ([from, to]) =>
    dispatch(changeTimesheetRangeAction({
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    })
  );

  const setCurrentWeek = () =>
    dispatch(changeTimesheetRangeAction({
      from: currentWeek.from,
      to: currentWeek.to
    })
  );

  const changeActionableUser = (user) => dispatch(changeActionable(user));

  return (
    <div className="timesheet-actions-bar">
      <div className="d-flex align-items-center">
        <div className="timesheet-action-nav">
          <div
            className={
              classnames('btn', 'btn-icon', { readonly: isLoading })
            }
            onClick={
              () => changeRange(previousWeek())
            }
          >
            <span className="mdi mdi-chevron-left"></span>
          </div>

          <Dropdown>
            <Dropdown.Toggle
              as="div"
              className={
                classnames('btn', 'btn-icon', { readonly: isLoading })
              }
            >
              <span className="mdi mdi-calendar-range"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                weeksRange().map(([_from, _to], index) => {
                  const fromPretty = _from.format('D MMM');
                  const toPretty = _to.format('D MMM');

                  const fromWithFormat = _from.format('YYYY-MM-DD');
                  const toWithFormat = _to.format('YYYY-MM-DD');

                  const isActiveWeek = fromWithFormat == from && toWithFormat == to;
                  const isCurrentWeek = fromWithFormat == currentWeek.from && toWithFormat == currentWeek.to;
                  const week = (isCurrentWeek ? 'Current week' : 'Week') + ' ' +  _from.format('W');

                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={
                        () => changeRange([_from, _to])
                      }
                      className={
                        classnames({ current: isActiveWeek })
                      }
                    >
                      {week}, <span className="text-uppercase"> {fromPretty} - {toPretty} </span>
                    </Dropdown.Item>
                  );
                })
              }
              {
                !isCurrentWeek && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={
                        () => setCurrentWeek()
                      }
                    >
                      Today
                    </Dropdown.Item>
                  </>
                )
              }

            </Dropdown.Menu>
          </Dropdown>

          <div
            className={
              classnames('btn', 'btn-icon', { readonly: isLoading })
            }
            onClick={
              () => changeRange(nextWeek())
            }
          >
            <span className="mdi mdi-chevron-right"></span>
          </div>
        </div>

        <div className="timesheet-period">
          <span className="text-uppercase"> {fromPretty} - {toPretty} </span>

          {
            !isLoading &&
              <TotalAmount />
          }
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <div
          className={
            classnames('actionable', { readonly: isLoading })
          }
        >
          {
            isMedicalGroupAdmin && (
              <Actionable
                user={currentUser}
                changeActionable={changeActionableUser}
                loadOptions={loadActionables}
              />
            )
          }
        </div>

        <Button
          variant="primary"
          onClick={
            () => dispatch(showFormModalAction({ entry: 'time_record' }))
          }
        >
          Add time
        </Button>
      </div>
    </div>
  );
}

export default Controls
