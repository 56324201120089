import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import * as selectors from 'src/react/store/Timesheets/selectors'

const DeleteMessage = ({ entry, entity: { day, patient_id } }) => {
  const patient = useSelector(state => selectors.getPatientById(state, patient_id));
  const dayPretty = moment(day).format('D MMM YYYY');

  if (entry == 'activity') {
    return (
      <>
        You are going to delete time records
        of <strong>{patient.name}</strong> for <strong>{dayPretty}</strong>?
      </>
    );
  }

  if (entry == 'time_record') {
    return (
      <>
        You are going to delete time record
        of <strong>{patient.name}</strong> for <strong>{dayPretty}</strong>?
      </>
    );
  }

  return null;
};

export default DeleteMessage
