import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  connect() {
    const url = this.element.dataset.url;

    $(this.element).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      maxItems: 1,
      minChars: 2,
      closeAfterSelect: true,
      create: false,
      render: {
        option: function(item, escape) {
          const deactivationTag = item.archived ? '<span class="badge badge-secondary">Deactivated</span>&nbsp;' : '';
          return '<div class="option">' + deactivationTag + '<span>' + escape(item.name) + '</span></div>';
        }
      },
      load: function(query, callback) {
        this.clearOptions();

        if (!query.length) return callback();

        $.ajax({
          type: 'GET',
          url: url,
          dataType: 'json',
          data: {
            type: 'rule',
            query: query
          },
          success: (response) => callback(response.suggestions),
          error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
        })
      },
      onInitialize: function() {
        this.$wrapper.removeClass('single');
      }
    });
  }
}
