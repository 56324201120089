import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import * as selectors from 'src/react/store/Timesheets/selectors'

import Controls from './Controls'
import Days from './Days'
import Board from './Board'
import ModalFactory from './ModalFactory'

import { getTimesheetTree } from 'src/react/store/Timesheets/actions'

const Page = (props) => {
  const dispatch = useDispatch();

  const from = useSelector(state => selectors.from(state));
  const to = useSelector(state => selectors.to(state));
  const actionableUser = useSelector(state => selectors.actionableUser(state));
  const currentWeek = useSelector(state => selectors.currentWeek(state));
  const actionableId = useSelector(state => selectors.actionableId(state));
  const verificationLock = useSelector(state => selectors.verificationLock(state));

  useEffect(() => {
    dispatch(getTimesheetTree(from, to, { actionableId }));
  }, [
    from,
    to,
    actionableId,
    verificationLock.minDate,
    verificationLock.maxDate
  ]);

  return (
    <div className="container-fluid">
      <div className="timesheet">
        <div className="page-head">
          <div className="page-head-title">Timesheet - {actionableUser.name}</div>
        </div>

        <div className="main-content">
          <Controls
            from={from}
            to={to}
            currentWeek={currentWeek}
          />

          <div className="timesheet-container">
            <Days from={from} to={to} />
            <Board />
          </div>
        </div>
      </div>

      <ModalFactory />
      <ToastContainer />
    </div>
  );
}

export default Page
