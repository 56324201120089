import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  setMicrophoneStatusAction,
  setMicrophoneBusyAction,
  setMicrophoneClosingAction,
} from 'src/react/store/Meetings/actions'

import {
  MeetingStatuses,
  MicrophoneStatuses
} from 'src/react/constants/Meetings/index'

export function useMeetingMicrophone(mediaStream) {
  const meetingStatus = useSelector(state => selectors.meetingStatus(state));
  const audioStreamReady = useSelector(state => selectors.audioStreamReady(state));
  const microphoneBusy = useSelector(state => selectors.microphoneBusy(state));
  const microphoneStatus = useSelector(state => selectors.microphoneStatus(state));
  const microphoneClosing = useSelector(state => selectors.microphoneClosing(state))

  const dispatch = useDispatch();

  const startAudio = async () => {
    // console.log('starting audio ...');

    dispatch(setMicrophoneBusyAction(true));
    dispatch(setMicrophoneStatusAction(MicrophoneStatuses.Pending));

    try {
      await mediaStream.startAudio();

      dispatch(setMicrophoneStatusAction(MicrophoneStatuses.Ready));
    } catch (e) {
      console.log('start audio exception: ', e);

      dispatch(setMicrophoneBusyAction(false));

      if (e.type == 'USER_FORBIDDEN_MICROPHONE' || e.reason == 'USER_FORBIDDEN_MICROPHONE') {
        toast.error(`
          Access is denied for microphone in your browser.
          Please allow microphone permissions and restart meeting.
        `, { autoClose: false });

        dispatch(setMicrophoneStatusAction(MicrophoneStatuses.Unpermitted));
      }
    }
  };

  const stopAudio = async () => {
    if (microphoneClosing) return;

    // console.log('stopping audio ...');

    dispatch(setMicrophoneClosingAction(true));

    try {
      await mediaStream.stopAudio();
    } catch (e) {
      console.log('stop audio exception: ', e);
    }

    dispatch(setMicrophoneClosingAction(false));
    dispatch(setMicrophoneBusyAction(false));
  };

  useEffect(() => {
    audioStreamReady && startAudio();
  }, [audioStreamReady]);

  useEffect(() => {
    // console.log('microphoneStatus:', microphoneStatus, 'currentMeetingStatus:', currentMeetingStatus, 'microphoneBusy', microphoneBusy);

    if (
      meetingStatus == MeetingStatuses.Ending &&
      microphoneStatus == MicrophoneStatuses.Ready &&
      microphoneBusy
    ) stopAudio();
  }, [meetingStatus, microphoneStatus, microphoneBusy]);
}
