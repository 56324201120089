import BloodPressure from './cardiology/BloodPressure'
import BloodOxygen from './cardiology/BloodOxygen'
import Temperature from './cardiology/Temperature'
import Weight from './cardiology/Weight'
import EjectionFraction from './cardiology/EjectionFraction'
import BloodGlucose from './cardiology/BloodGlucose'

class Factory {
  constructor(type, { node, ...rest }) {
    if (this.factory = Factory.registry[type])
      return new this.factory(node, rest);

    console.log('Factory is not registered')
  }

  static get registry() {
    return {
      'blood_pressure': BloodPressure,
      'blood_oxygen': BloodOxygen,
      'temperature': Temperature,
      'weight': Weight,
      'ejection_fraction': EjectionFraction,
      'blood_glucose': BloodGlucose
    }
  }
}

export default Factory
