import { Controller } from 'stimulus'
import Participants from 'src/components/rules/participants'
import Recipients from 'src/components/rules/recipients'

export default class extends Controller {
  static targets = [
    'namespaceInput', 'recipientsTypeInput', 'recipientsInput',
    'participantsBox', 'recipientsBox', 'assigneeTypeBox',
    'assigneeBox', 'assigneeTypeInput', 'assigneeInput'
  ]

  initialize() {
    this.participants = new Participants(
      this.participantsBoxTarget,
      this.namespace
    );

    this.recipients = new Recipients(this.recipientsInputTarget);

    // cache options lists on initialize
    this.assigneeTypeInputTargetOptions = [...this.assigneeTypeInputTarget.options];
    this.recipientsTypeInputTargetOptions = [...this.recipientsTypeInputTarget.options];

    // configure list of namespace options
    this.assigneeTypeInputTarget.innerHTML =
      this.optionsByNamespace(this.assigneeTypeInputTargetOptions);

    this.recipientsTypeInputTarget.innerHTML =
      this.optionsByNamespace(this.recipientsTypeInputTargetOptions);
  }

  connect() {
    this.render();

    this.namespaceInputTarget.addEventListener('change', (e) => this.updateNamespace());
    this.assigneeTypeInputTarget.addEventListener('change', (e) => this.updateAssigneeType());
    this.recipientsTypeInputTarget.addEventListener('change', (e) => this.updateRecipientsType());
  }

  disconnect() {
    this.namespaceInputTarget.removeEventListener('change', (e) => this.updateNamespace());
    this.assigneeTypeInputTarget.removeEventListener('change', (e) => this.updateAssigneeType());
    this.recipientsTypeInputTarget.removeEventListener('change', (e) => this.updateRecipientsType());
  }

  updateNamespace() {
    this.resetAssigneeType();
    this.resetRecipientsType();
    this.resetAssignee();
    this.resetRecipients();

    this.render();
  }

  updateAssigneeType() {
    this.resetAssignee();

    this.render();
  }

  updateRecipientsType() {
    this.resetRecipients();

    this.render();
  }

  render() {
    // assignee type visibility
    this.assigneeTypeBoxTarget.classList.toggle('d-none', false);

    // assignee visibility
    this.assigneeBoxTarget.classList.toggle('d-none', this.selectiveAssineeOption);

    // recipients visibility
    this.recipientsBoxTarget.classList.toggle('d-none', this.selectiveRecipientsOption);

    // switch namespace apply to input context
    this.participants.update(this.namespace);
  }

  resetAssigneeType() {
    this.assigneeTypeInputTarget.innerHTML =
      this.optionsByNamespace(this.assigneeTypeInputTargetOptions);

    this.assigneeTypeInputTarget.value = {
      'medical_group': 'medical_group:primary_member',
      'patient_group': 'patient_group:primary_member',
      'patient': 'patient:primary_member'
    }[this.namespace];
  }

  resetRecipientsType() {
    this.recipientsTypeInputTarget.innerHTML =
      this.optionsByNamespace(this.recipientsTypeInputTargetOptions);

    this.recipientsTypeInputTarget.value = {
      'medical_group': 'medical_group:selective',
      'patient_group': 'patient_group:selective',
      'patient': 'patient:selective'
    }[this.namespace];
  }

  resetAssignee() {
    this.assigneeInputTarget.value = '';
  }

  resetRecipients() {
    this.recipients.clear();
  }

  optionsByNamespace(options) {
    let html = '';

    options.forEach((el) => {
      if (el.dataset.namespace == this.namespace) html += el.outerHTML;
    });

    return html;
  }

  get selectiveAssineeOption() {
    return !['medical_group:selective', 'patient_group:selective', 'patient:selective'].includes(this.assigneeTypeInputTarget.value);
  }

  get selectiveRecipientsOption() {
    return !['medical_group:selective', 'patient_group:selective', 'patient:selective'].includes(this.recipientsTypeInputTarget.value);
  }

  get namespace() {
    return this.namespaceInputTarget.querySelector('input:checked').value;
  }
}
