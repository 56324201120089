import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Meetings/selectors'
import { startMeeting } from 'src/react/store/Meetings/actions'

import {
  LineStatuses,
  WebsocketsStatuses
} from 'src/react/constants/Meetings/index'

export function useMeetingStart() {
  const lineStatus = useSelector(state => selectors.lineStatus(state));
  const websocketsStatus = useSelector(state => selectors.websocketsStatus(state));
  const currentSubscriber = useSelector(state => selectors.currentSubscriber(state));

  const dispatch = useDispatch();

  const isReady =
    websocketsStatus == WebsocketsStatuses.Active &&
    lineStatus == LineStatuses.Free &&
    currentSubscriber != null;

  const isPending = lineStatus == LineStatuses.Pending;

  const startMeetingClick = () => dispatch(startMeeting(currentSubscriber));

  return {
    isReady,
    isPending,
    startMeetingClick
  };
}
