import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {

  connect() {
    const self = this;

    $(this.element).selectize({
      valueField: 'id',
      labelField: 'title',
      searchField: ['title'],
      create: false,
      minChars: 2,
      plugins: ['remove_button'],
      disabledField: 'disabled',
      render: {
        option: function(item, escape) {
          return '<div class="option"><span>' + escape(item.title) + '</span></div>';
        }
      },
      load: function(query, callback) {
        this.clearOptions();
        self.loadClinics(query, callback);
      },
      score: () => () => 1
    });
  }

  loadClinics(query, callback) {
    if (!query.length) return callback();

    if (query.length > 1) {
      this.fetchClinics({
        search: query,
        success: (response) => callback(response.suggestions),
        error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
      })
    }
  }

  fetchClinics(options) {
    $.ajax({
      url: this.url(),
      dataType: 'json',
      data: {
        type: 'clinic',
        query: options.search
      },
      error: options.error,
      success: options.success
    })
  }

  url() {
    return this.element.dataset.url;
  }
}