import React from 'react'

import { formatPath } from 'src/utils/formatters'
import { patientInfoLabel } from 'src/utils/patientInfoFormatter'

import Avatar from './Avatar'

const ActivityPatient = ({ patient }) => {
  const { id, name, email } = patient;

  const profileURL = formatPath(window.timesheets.patient_path, { id });

  return (
    <a
      className="user-list-widget"
      href={profileURL}
    >

      <div className="content">
        <div className="name">{name}</div>
        <div className="info-label">{patientInfoLabel(patient)}</div>
      </div>

      <div className="d-flex align-items-center ml-1">
        <div className="mr-0"></div>
      </div>
    </a>
  );
}

export default ActivityPatient
