import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Metrics/selectors'
import { showModalAction } from 'src/react/store/Metrics/actions'

const Actions = () => {
  const isControlsInactive = useSelector(state => selectors.isControlsInactive(state));

  const dispatch = useDispatch();

  const exportModal = () => dispatch(showModalAction('export'));
  const settingsModal = () => dispatch(showModalAction('settings'));

  return (
    <>
      <button
        className={
          classnames('btn', 'btn-secondary', { disabled: isControlsInactive })
        }
        onClick={exportModal}
      >
        Export to pdf
      </button>
      <button
        className={
          classnames('btn', 'btn-secondary', { disabled: isControlsInactive })
        }
        onClick={settingsModal}
      >
        <div className="mdi mdi-18px mr-1 mdi-tune-variant" />
        Settings
      </button>
    </>
  );
}

export default Actions