import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('trix-initialize', (event) => {
      this.element.querySelector('.trix-button-group--file-tools').remove();
    })

    this.element.addEventListener('trix-file-accept', (event) => {
      event.preventDefault();
    });

    this.element.addEventListener('trix-attachment-add', (event) => {
      event.attachment.remove();
    });
  }
}
