import { Controller } from 'stimulus'
import 'devbridge-autocomplete'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  connect() {
    const url = this.data.get('url')
    const type = this.data.get('type')

    $(this.element).autocomplete({
      serviceUrl: url,
      dataType: 'json',
      params: { type },
      minChars: 1,
      preserveInput: true,
      deferRequestBy: 200,
      appendTo: '.be-top-header',
      forceFixPosition: true,
      showNoSuggestionNotice: false,
      formatResult(suggestion, currentValue) {
        return `${suggestion.data}`;
      },
      onSearchError(query, xhr) {
        new ErrorsInterceptor({ xhr });
      }
    });

    // TODO: Temporary fix to eliminate simple form submit
    $('.search').keypress(
      function (event) {
        if (event.key == 'Enter') {
          event.preventDefault();
        }
      });

    $('.autocomplete-suggestions').addClass('custom-autocomplete-suggestions');
  }
}
