import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import * as selectors from 'src/react/store/Meetings/selectors'

import {
  getHistory,
  resetHistoryAction
} from 'src/react/store/Meetings/actions'

import { Modal } from 'react-bootstrap'
import HistoryItem from 'src/react/components/Meetings/History/HistoryItem'

const HistoryModal = (props) => {
  const isLoadingHistory = useSelector(state => selectors.isLoadingHistory(state));
  const isLoadingHistoryError = useSelector(state => selectors.isLoadingHistoryError(state));
  const isMoreHistory = useSelector(state => selectors.isMoreHistory(state));
  const history = useSelector(state => selectors.history(state));
  const currentUser = useSelector(state => selectors.currentUser(state));

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetHistoryAction());
  }, []);

  const loadMore = () => {
    dispatch(getHistory(currentPage));

    setCurrentPage((previousPage) => previousPage + 1);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoadingHistory,
    hasNextPage: isMoreHistory,
    onLoadMore: loadMore,
    disabled: isLoadingHistoryError,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <Modal
      className="meetings"
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4">
          History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0 activities modal-activities">
        <div className="history">
          <div
            ref={rootRef}
            className="list-group list-group-flush scrollable-list"
          >
            {
              history.map(meeting => (
                <HistoryItem
                  key={meeting.id}
                  meeting={meeting}
                  currentUser={currentUser}
                />
              ))
            }

            {
              (isLoadingHistory || isMoreHistory) ? (
                <div
                  ref={sentryRef}
                  className="d-flex justify-content-center mt-1"
                >
                  {
                    (isLoadingHistory && isMoreHistory) && (
                      <div className="text-secondary">
                        <span className="mdi mdi-loading mdi-spin" />
                        <span className="ml-1">Loading ...</span>
                      </div>
                    )
                  }
                </div>
              ) : (
                history.length == 0 && !isLoadingHistoryError && (
                  <div className="text-secondary text-center">
                    No meetings yet
                  </div>
                )
              )
            }

            {
              isLoadingHistoryError && (
                <div className="text-center text-danger">
                  <span className="mdi mdi-alert-circle-outline" />
                  <span className="ml-1">Meetings loading error</span>
                </div>
              )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default HistoryModal
