import { Controller } from 'stimulus'
import $ from 'jquery'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    $('#actions-modal .modal').modal('hide');
    $(`#roster-${data.key}`).replaceWith(data.content);
    $('.flash-alerts').html(data.flash);
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    new ErrorsInterceptor({ xhr, data });
  }
}
