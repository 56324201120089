import React, { useRef, useEffect, useCallback } from 'react'

import moment from 'moment'
import 'daterangepicker'

const RangePicker = (props) => {
  const factoryRef = useRef(null);

  const childRef = useCallback((node) => {
    factoryRef.current =
      $(node).
        daterangepicker({
          ...props.settings,
          startDate: moment(props.from),
          endDate: moment(props.to)
        }).
        on('apply.daterangepicker', (e, { startDate: from, endDate: to }) => {
          props.onApply({ from, to });
        });
    }, []);

  useEffect(() => {
    factoryRef.current.data('daterangepicker').setStartDate(moment(props.from));
    factoryRef.current.data('daterangepicker').setEndDate(moment(props.to));
  }, [props.from, props.to]);

  // unmount
  useEffect(() => {
    return () => {
      factoryRef.current.data('daterangepicker').remove();
    };
  }, []);

  return React.cloneElement(props.children, { ref: childRef });
}

export default RangePicker