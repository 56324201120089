import { createSelector } from 'reselect'
import { RequestStatuses } from 'src/react/constants/Metrics/index'

const getState = state => state.metrics;

const from = state => getState(state).from;
const to = state => getState(state).to;
const currentDay = state => getState(state).currentDay;
const currentWidget = state => getState(state).currentWidget;
const activeWidgets = state => getState(state).activeWidgets;
const modalShow = state => getState(state).modalShow;
const modalAction = state => getState(state).modalAction;
const requestStatus = state => getState(state).requestStatus;
const settings = state => getState(state).settings;

const anyResults = state => activeWidgets(state).length > 0;

const isControlsInactive = createSelector(
  [anyResults, requestStatus],
  (isResults, status) => {
    return !isResults || status == RequestStatuses.Loading;
  }
);

export {
  getState,
  from,
  to,
  currentDay,
  currentWidget,
  activeWidgets,
  modalShow,
  modalAction,
  settings,
  requestStatus,
  anyResults,
  isControlsInactive
}