import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.topOffset = 75;
  }

  connect() {
    window.scrollTo({
      top: this.alertRect.top - this.topOffset + window.scrollY,
      behavior: 'smooth'
    });
  }

  get alertRect() {
    return this.element.getBoundingClientRect();
  }
}
