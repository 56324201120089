import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'follower']

  connect() {
    this.switchAccessibility();

    this.sourceTarget.addEventListener('change', (e) => this.switchAccessibility());
  }

  switchAccessibility() {
    this.followerTarget.classList.toggle('disabled', !this.sourceTarget.checked);
  }
}
